import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";

function HeaderDropdown() {
  const navigate = useNavigate();
  const [roundId, setRoundId] = useState(null);
  const [roundData, setRoundData] = useState(null);
  const userData = useSelector((state) => state.user.data);

  useEffect(() => {
    async function getRound() {
      const params = new URLSearchParams(window.location.search);
      const roundId = params.get("round");
      setRoundId(roundId);

      const docRef = doc(firestore, "round", roundId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setRoundData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    }
    getRound();
  }, []);

  const handleEndRound = async () => {
    Swal.fire({
      title: "End this round?",
      html: `
        <h5 class="fw-600">Are you sure you want to end this round?</h5>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const ref = doc(firestore, "round", roundId);
        await updateDoc(ref, {
          teeTime: "finish",
        });

        Swal.fire({
          title: "Round End!",
          html: `
            <h5 class="fw-600">Your round has been end.</h5>
          `,
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#ee3d7f",
        }).then(async (result) => {
          navigate("/round-activity");
        });
      }
    });
  };

  // console.log(roundData);
  // console.log(userData);

  return (
    <>
      <Dropdown className="drodownFriend" drop="down-centered">
        <Dropdown.Toggle
          as="i"
          className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"
          id="dropdown-basic"
          style={{ cursor: "pointer", zIndex: 1000 }}
        ></Dropdown.Toggle>
        <Dropdown.Menu as={CustomDropdown}>
          <Dropdown.Item style={{ display: "flex" }}>
            <h5
              className="fw-600"
              style={{
                height: "25px",
              }}
              onClick={() => {
                navigate(`/leader-board?round=${roundId}`);
              }}
            >
              Leader Board
            </h5>
          </Dropdown.Item>
          <Dropdown.Item style={{ display: "flex" }} disabled>
            <h5
              className="fw-600"
              style={{
                height: "25px",
                color: "#cdcdcd",
              }}
            >
              Game mode
            </h5>
          </Dropdown.Item>
          <Dropdown.Item style={{ display: "flex" }} disabled>
            <h5
              className="fw-600"
              style={{
                height: "25px",
                color: "#cdcdcd",
              }}
            >
              Competitor
            </h5>
          </Dropdown.Item>
          <hr />
          <Dropdown.Item style={{ display: "flex" }} disabled>
            <h5
              className="fw-600"
              style={{
                height: "25px",
                color: "#cdcdcd",
              }}
            >
              Player & Caddie
            </h5>
          </Dropdown.Item>
          <Dropdown.Item style={{ display: "flex" }} disabled>
            <h5
              className="fw-600"
              style={{
                height: "25px",
                color: "#cdcdcd",
              }}
            >
              Course
            </h5>
          </Dropdown.Item>
          <hr />
          <Dropdown.Item style={{ display: "flex" }} disabled>
            <h5
              className="fw-600"
              style={{
                height: "25px",
                color: "#cdcdcd",
              }}
            >
              Edit Round
            </h5>
          </Dropdown.Item>
          <Dropdown.Item style={{ display: "flex" }} disabled>
            <h5
              className="fw-600"
              style={{
                height: "25px",
                color: "#cdcdcd",
              }}
            >
              Invitation Link
            </h5>
          </Dropdown.Item>
          <Dropdown.Item
            disabled={roundData?.creator !== userData?.uid}
            style={{ display: "flex" }}
            onClick={() => handleEndRound()}
          >
            <h5
              className="fw-600"
              style={{
                height: "25px",
                color: roundData?.creator == userData?.uid ? "black" : "#cdcdcd",
              }}
            >
              End Round
            </h5>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default HeaderDropdown;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
