import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

function HoleDetailModal({
  showHoleDetailModal,
  setShowHoleDetailModal,
  selectedHoleDetail,
  courseHoleData,
  holeScoreData,
}) {
  const [holeData, setHoleData] = useState({});

  useEffect(() => {
    if (selectedHoleDetail && courseHoleData) {
      const holeNumber = selectedHoleDetail.holeNumber;
      let existingScore = courseHoleData.holeDetails.find(
        (hole) =>
          hole.holeNumber === holeNumber ||
          hole.holeNumber === parseInt(holeNumber)
      );
      setHoleData(existingScore);
    }
  }, [selectedHoleDetail, courseHoleData]);

  const handleCloseScoreModal = () => {
    setShowHoleDetailModal(false);
  };

  const rightDiv = ["Par", "Index", "Y", "R", "BL", "W", "B"];
  const rightDivColor = {
    Par: "#E9F6E9",
    Index: "#E9F6E9",
    Y: "#FFFED5",
    R: "#FFD5D5",
    BL: "#CAD0FE",
    W: "#ffffff",
    B: "#000000",
  };

  return (
    <>
      <Modal
        show={showHoleDetailModal}
        onHide={handleCloseScoreModal}
        centered
        size="md"
      >
        <Modal.Body className="p-0">
          <div className="row">
            <div className="col-xl-12 mb-3">
              <div
                className="card shadow-xss w-100 d-block d-flex border-0 p-3"
                style={{ backgroundColor: "#4D4D4D" }}
              >
                <div className="d-flex w-100 flex-column flex-md-row ">
                  <div className="d-flex align-items-center">
                    <h4 className="fw-600 mt-0 m-0 text-white">
                      {courseHoleData?.courseHoleName}
                    </h4>
                  </div>

                  <div className="d-flex align-items-center ms-md-auto mt-2 mt-md-0 w-md-auto">
                    <h4 className="fw-600 m-0 ms-3 mt-0 text-white">No.</h4>
                    <h2 className="fw-700 m-0 ms-3 mt-0 text-white">
                      {holeData?.holeNumber}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "relative",
              height: "70vh",
              minHeight: "200px",
            }}
          >
            <img
              src={holeData?.holeImage}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              className="rounded-3"
            />

            <div
              style={{
                position: "absolute",
                top: "5%",
                right: "5%",
                backgroundColor: "#4D4D4D",
                padding: "6px",
                borderRadius: "8px",
                boxShadow: "0 0 5px rgba(0,0,0,0.1)",
                maxWidth: "200px",
              }}
            >
              {rightDiv.map((item) => (
                <div
                  style={{
                    backgroundColor: rightDivColor[item],
                    padding: "5px",
                    borderRadius: "8px",
                    boxShadow: "0 0 5px rgba(0,0,0,0.1)",
                    maxWidth: "200px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  className="mb-2"
                >
                  <h4
                    className={`fw-600 mb-0  ${
                      item === "B" ? "text-white" : ""
                    }`}
                  >
                    {item}
                  </h4>
                  <h5
                    className={`fw-600 mb-0  ${
                      item === "B" ? "text-white" : ""
                    }`}
                  >
                    {item == "Par"
                      ? holeData?.par
                      : item == "Index"
                      ? holeData?.index
                      : item == "Y"
                      ? holeData?.yellowTee
                      : item == "R"
                      ? holeData?.redTee
                      : item == "BL"
                      ? holeData?.blueTee
                      : item == "W"
                      ? holeData?.whiteTee
                      : item == "B"
                      ? holeData?.blackTee
                      : ""}
                  </h5>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <button
            className="btn bg-current post-btn"
            onClick={handleBack}
            style={{ width: "80px" }}
            disabled={currentIndex === 0}
          >
            <h4 className="fw-600 m-0 text-white p-1">Back</h4>
          </button>

          <button
            className="btn bg-current post-btn"
            onClick={handleNext}
            disabled={currentIndex === selectCourseHole.holeDetails.length - 1}
            style={{ width: "80px" }}
          >
            <h4 className="fw-600 m-0 text-white p-1">Next</h4>
          </button> */}

          <button
            className="btn bg-greylight"
            onClick={handleCloseScoreModal}
            style={{ width: "80px" }}
          >
            <h4 className="fw-600 m-0 p-1">OK</h4>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default HoleDetailModal;
