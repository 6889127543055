import React from 'react'
import moment from 'moment'

export const ViewPlayerPerf = () => {
    return (
        <>
            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3 mb-2 position-relative" style={{ backgroundColor: '#FDE7EF', fontWeight: 700, fontSize: 14 }}>
                <div className='position-absolute fw-normal fs-6' style={{ top: 0, right: '5px' }}>{moment.unix(1725334575).format('DD MMM YY')}</div>
                <strong className="px-3 rounded-3 text-white" style={{ fontSize: 32, backgroundColor: '#EE3C7F' }}>
                    0
                </strong>
                <div className='ms-2 d-block mt-1'>
                    <span>{`Best Score (to par)`}</span><br />
                    <small className='fw-normal'>Pinehurst Golf & Country Club</small>
                </div>
            </div>

            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3 mb-2" style={{ backgroundColor: '#F5F3FF', fontWeight: 700, fontSize: 14 }}>
                <strong className="px-3 rounded-3 text-white" style={{ fontSize: 32, backgroundColor: '#5D3DB5' }}>
                    0
                </strong>
                <span className="mb-3 ms-2">Putts per hole</span>
            </div>

            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3" style={{ backgroundColor: '#E9F6E9', fontWeight: 700, fontSize: 14 }}>
                <strong className="px-3 rounded-3 text-white" style={{ fontSize: 32, backgroundColor: '#81D77A' }}>
                    0
                </strong>
                <span className="mb-3 ms-2">{`Greens in Regulation(%)`}</span>
            </div>
        </>
    )
}

export const ViewCaddiePerf = () => {
    return (
        <>
            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3 mb-2" style={{ backgroundColor: '#FDE7EF', fontWeight: 700, fontSize: 14 }}>
                <strong className="px-3 rounded-3 text-white" style={{ fontSize: 32, backgroundColor: '#EE3C7F' }}>
                    0
                </strong>
                <span className="mb-3 ms-2">Service Mind</span>
            </div>

            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3 mb-2" style={{ backgroundColor: '#F5F3FF', fontWeight: 700, fontSize: 14 }}>
                <strong className="px-3 rounded-3 text-white" style={{ fontSize: 32, backgroundColor: '#5D3DB5' }}>
                    0
                </strong>
                <span className="mb-3 ms-2">Advice and Guidance</span>
            </div>

            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3" style={{ backgroundColor: '#E9F6E9', fontWeight: 700, fontSize: 14 }}>
                <strong className="px-3 rounded-3 text-white" style={{ fontSize: 32, backgroundColor: '#81D77A' }}>
                    0
                </strong>
                <span className="mb-3 ms-2">Total Rounds</span>
            </div>
        </>
    )
}