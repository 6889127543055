import React, { useState, useEffect } from 'react'

import Header from '../../../components/Header';
import Leftnav from '../../../components/Leftnav';
import Rightchat from '../../../components/Rightchat';
import Appfooter from '../../../components/Appfooter';
import Popupchat from '../../../components/Popupchat';

import AddClub from './AddClub';
import RenderClub from './RenderClub';
import { useSelector } from 'react-redux';

import { firestore } from '../../../components/firebase/FirebaseConfig';
import { getDocs, addDoc, updateDoc, query, where, collection } from 'firebase/firestore';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { defaultClubs } from './module/clubDefaultData';

import { Link } from 'react-router-dom';


function MyClubs() {
    const [myClub, setMyclub] = useState([]);
    const userData = useSelector((state) => state.user.data);
    const [loadingClub, setLoadingClub] = useState(true);
    const [reLoad, setReload] = useState(0);

    useEffect(() => {
        async function getMyClub() {
            const clubsCollection = collection(firestore, 'clubs');
            const q = query(clubsCollection, where("userId", "==", userData.uid));
            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const data = querySnapshot.docs[0].data();
                    setMyclub(data.clubs);
                } else {
                    setMyclub(defaultClubs)
                }
                setLoadingClub(false)
            } catch (e) {
                setLoadingClub(false)
                console.error("Error adding or updating document: ", e);
            }

        }
        if (userData) {
            getMyClub()
        }
    }, [userData])

    const handleSubmitedAddClub = (clubData) => {
        const mergedClub = [...myClub, clubData];
        const sortedClubs = mergedClub.sort((a, b) => b.distance - a.distance);
        setMyclub(sortedClubs)
        updateClub(sortedClubs)
        setReload((prev) => prev + 1)
    }
    const handleSubmitedEditClub = (clubData, index) => {
        const updatedClubs = [...myClub];
        updatedClubs[index] = clubData;
        const sortedClubs = updatedClubs.sort((a, b) => b.distance - a.distance);
        setMyclub(sortedClubs);
        updateClub(sortedClubs);
        setReload((prev) => prev + 1)
    }
    const handleRemoveClub = (index) => {
        const updatedClubs = myClub.filter((_, i) => i !== index);
        const sortedClubs = updatedClubs.sort((a, b) => b.distance - a.distance);
        setMyclub(sortedClubs);
        updateClub(sortedClubs);
        setReload((prev) => prev + 1);
    }

    console.log(myClub);


    const resetClub = () => {
        Swal.fire({
            text: "Do you want to reset your bag to default ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "Yes",
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    didOpen: () => {
                        Swal.showLoading();
                    },
                })
                await updateClub(defaultClubs)
                setMyclub(defaultClubs);
                setReload((prev) => prev + 1);
                Swal.close()
            }
        });
    }

    async function updateClub(myClub) {

        const clubsCollection = collection(firestore, 'clubs');
        const q = query(clubsCollection, where("userId", "==", userData.uid));

        try {
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                querySnapshot.forEach(async (doc) => {
                    const docRef = doc.ref;
                    await updateDoc(docRef, { clubs: myClub });
                });
            } else {
                await addDoc(clubsCollection, { userId: userData.uid, clubs: myClub });
            }
        } catch (e) {
            console.error("Error adding or updating document: ", e);
        }
    }

    return (
        <>
            <Header />
            <Leftnav />
            <Rightchat />
            <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <Link to="/my-perf-profile" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">My Clubs</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {!loadingClub ? (
                                                <>
                                                    <div className='w-100 d-sm-flex align-items-center justify-content-between px-3 py-4 rounded-3' style={{ backgroundColor: '#E9F6E9' }}>
                                                        <section className='d-flex align-items-center'>
                                                            <strong className='px-2 rounded-3 text-white' style={{ backgroundColor: '#81D77A', fontSize: 32 }}>{myClub.filter(club => club.inBag).length}</strong>
                                                            <strong className='fs-4 ms-4'>Clubs in your bag</strong>
                                                        </section>
                                                        <section className='text-end'>
                                                            <button className='btn p-0 mx-2 bg-light rounded-circle text-center' style={{ width: 36, height: 36 }} onClick={() => resetClub()}>
                                                                <i className='bi bi-arrow-repeat fs-1'></i>
                                                            </button>
                                                            <AddClub defaultClubs={defaultClubs} submitedClub={handleSubmitedAddClub} />
                                                        </section>
                                                    </div>
                                                    <ul className="list-inline" key={reLoad}>
                                                        {myClub.map((club, index) => (
                                                            <RenderClub key={index} myClub={club} index={index} defaultClubs={defaultClubs} submitedClub={handleSubmitedEditClub} removeClub={handleRemoveClub} />
                                                        ))}
                                                    </ul>
                                                </>
                                            ) : (
                                                <div className='d-flex justify-content-center align-items-center' style={{ height: '75vh' }}>
                                                    <Spinner variant="grey" animation="border" role="status" size="xl">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />
        </>
    )
}

export default MyClubs

