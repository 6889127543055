import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react'
import { ReactSVG } from 'react-svg';
import { Toast, ToastContainer, OverlayTrigger, Popover, ListGroup } from 'react-bootstrap';
import { collection, onSnapshot, where, query, orderBy, limit, doc, getDocs, updateDoc, startAfter } from 'firebase/firestore';
import { firestore } from '../../components/firebase/FirebaseConfig';
import { useSelector } from 'react-redux';
import { notiTypeDef } from '../../components/notification/manageNotification';
import { useNavigate, Link } from 'react-router-dom';
import './notistyle.css'

const noti = "assets/images/dooNineIconV2/noti.svg";
const noti_mobile = "assets/images/dooNineIconV2/mobile/mobile-Noti.svg";

const Notification = ({ isMobile }) => {
    const [showOverlay, setShowOverlay] = useState(false);
    const popoverRef = useRef(null);
    const userData = useSelector((state) => state.user.data);
    const [notiContentList, setNotiContentList] = useState([])
    const navigate = useNavigate();
    const [readCount, setReadCount] = useState([]);
    const timeOutRef = useRef();
    const lastVisibleRef = useRef(null);
    const isHasmore = useRef(true);
    const initSize = 21;
    const nextSize = 10;

    const handleClickOutside = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            setShowOverlay(false);
            clearTimeout(timeOutRef.current)
        }
    };
    useEffect(() => {
        if (showOverlay) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('scroll', handleClickOutside, true);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('scroll', handleClickOutside, true);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('scroll', handleClickOutside, true);
        };
    }, [showOverlay]);

    const handleProfileClick = (uid) => {

        if (!uid) return;

        if (uid === userData.uid) {
            navigate('/userPage')
        } else {
            navigate("/user", { state: { uid: uid } });
        }
    }

    useEffect(() => {
        if (userData) {
            const notiRef = collection(firestore, 'notifications');
            const q = query(notiRef, where('toUserId', '==', userData.uid), orderBy("timestamp", "desc"), limit(initSize));
            const unsubscribe = onSnapshot(q, async (snapshot) => {

                if (snapshot.size < initSize) isHasmore.current = false;

                const docs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                lastVisibleRef.current = snapshot.docs[snapshot.docs.length - 1];

                const userIds = Array.from(new Set(docs.map(doc => doc.fromUserId)));

                if (userIds.length > 0) {
                    const usersQuery = query(
                        collection(firestore, 'users'),
                        where('uid', 'in', userIds)
                    );
                    const userSnapshot = await getDocs(usersQuery);

                    const usersMap = userSnapshot.docs.reduce((acc, userDoc) => {
                        acc[userDoc.data().uid] = userDoc.data();
                        return acc;
                    }, {});

                    const enrichedDocs = docs.map(doc => ({
                        ...doc,
                        user: usersMap[doc.fromUserId] || {}
                    }));

                    setNotiContentList(enrichedDocs);
                    updateReadCount([...notiContentList, ...enrichedDocs], true)

                } else {
                    setNotiContentList(docs);
                }
            });

            return () => unsubscribe();
        }
    }, [userData]);

    const handleViewmore = async () => {
        if (!isHasmore.current) return;

        const notiRef = collection(firestore, 'notifications');

        try {
            const q = query(notiRef, where('toUserId', '==', userData.uid), orderBy("timestamp", "desc"), startAfter(lastVisibleRef.current), limit(nextSize));
            const moreSnapshot = await getDocs(q);

            if (moreSnapshot.size < nextSize) isHasmore.current = false;

            lastVisibleRef.current = moreSnapshot.docs[moreSnapshot.docs.length - 1];

            const docs = moreSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            const userIds = Array.from(new Set(docs.map(doc => doc.fromUserId)));
            if (userIds.length > 0) {
                const usersQuery = query(
                    collection(firestore, 'users'),
                    where('uid', 'in', userIds)
                );
                const userSnapshot = await getDocs(usersQuery);
                const usersMap = userSnapshot.docs.reduce((acc, userDoc) => {
                    acc[userDoc.data().uid] = userDoc.data();
                    return acc;
                }, {});

                const enrichedDocs = docs.map(doc => ({
                    ...doc,
                    user: usersMap[doc.fromUserId] || {}
                }));

                setNotiContentList((prev) => [...prev, ...enrichedDocs]);
                updateReadCount([...notiContentList, ...enrichedDocs], false)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleShowOverlay = () => {
        setShowOverlay(true);

        if (!readCount || !readCount.length) {
            return;
        }

        if (timeOutRef.current) {
            clearTimeout(timeOutRef.current);
        }

        timeOutRef.current = setTimeout(async () => {
            await readNoti(readCount);
        }, 1000);
    };

    const updateReadCount = useCallback(async (list, isInit) => {

        const foundUnread = list.filter((item) => item.isRead === false);
        if (foundUnread && !isInit) {
            await readNoti(foundUnread);
        } else {
            setReadCount(foundUnread);
        }
    }, [notiContentList])

    const readNoti = async (foundUnread) => {
        try {
            const updatePromises = foundUnread.map(notification => {
                const notiRef = doc(firestore, "notifications", notification.notificationId);
                return updateDoc(notiRef, { isRead: true });
            });

            await Promise.all(updatePromises);
        } catch (error) {
            console.error("Error updating notifications:", error);
        }
    }


    const handleNotificationClick = (noti) => {
        switch (noti.type) {
            case 'add':
            case 'accept':
                // handleProfileClick(noti.user.uid);
                window.location.href = `/user?uid=${noti.user.uid}`
                break;

            default:
                break;
        }
    };

    const notiContent = useMemo(() => {
        return (
            <Popover id='popover-notification'>
                <div ref={popoverRef} className='theme-dark-bg popover-container' >
                    <Popover.Header as="h3" className='fs-2 fw-bold bg-white theme-dark-bg popover-head'>Notification</Popover.Header>
                    <Popover.Body as='div' className='theme-dark-bg right-scroll-bar popover-body'>
                        <ListGroup variant='flush' className='noti-list-container'>
                            {notiContentList.length ? (
                                <>
                                    {notiContentList.map((noti) =>
                                        <ListGroup.Item key={noti.notificationId}
                                            className='noti-item pointer'
                                            onClick={() => handleNotificationClick(noti)}
                                        >
                                            {notiTypeDef(noti)}
                                        </ListGroup.Item>
                                    )}

                                </>
                            ) : (
                                <ListGroup.Item className='noti-item'><h5 className='text-center'>No Notification</h5></ListGroup.Item>
                            )}
                        </ListGroup>
                        {isHasmore.current &&
                            <div className='noti-item p-2'>
                                <h5 className='text-center m-0 text-info pointer text-decoration-underline' onClick={() => handleViewmore()}>View More</h5>
                            </div>
                        }
                    </Popover.Body>
                </div>
            </Popover>
        )
    }, [notiContentList]);


    if (isMobile) {
        return (
            <>
                <OverlayTrigger
                    show={showOverlay}
                    placement="bottom"
                    overlay={notiContent}
                >
                    <button className="mob-menu me-2 bg-transparent border-0 position-relative" onClick={() => handleShowOverlay()}>
                        <ReactSVG src={noti_mobile} className='w50' />
                        {readCount && readCount.length ?
                            <h6 className='position-absolute m-0 readcount-text'>
                                {readCount.length > 20 ? '20+' : readCount.length}
                            </h6>
                            :
                            null
                        }

                    </button>
                </OverlayTrigger>
            </>
        )
    } else {
        return (
            <>
                <OverlayTrigger
                    show={showOverlay}
                    placement="bottom"
                    overlay={notiContent}
                >
                    <button className="p-2 text-center ms-3 menu-icon bg-transparent border-0 position-relative" onClick={() => handleShowOverlay()}>
                        <ReactSVG src={noti} className='dooNine-icon-header-right' />
                        {readCount && readCount.length ?
                            <h6 className='position-absolute m-0 readcount-text'>
                                {readCount.length > 20 ? '20+' : readCount.length}
                            </h6>
                            :
                            null
                        }
                    </button>
                </OverlayTrigger>
            </>
        )
    }
}

export default Notification