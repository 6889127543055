import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Popupchat from "../../components/Popupchat";
import Appfooter from "../../components/Appfooter";
import { useSelector } from "react-redux";

import GroupChannel from "@sendbird/uikit-react/GroupChannel";
import GroupChannelList from "@sendbird/uikit-react/GroupChannelList";

import { useNavigate } from "react-router-dom";
import axios from "axios";

import "./styles2.css";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { or } from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import { ReactSVG } from "react-svg";
import { Dropdown } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import ReactDOM from 'react-dom';
import SBChannelList from "@sendbird/uikit-react/ChannelList";
import SBConversation from "@sendbird/uikit-react/Channel";
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
const backChat = "assets/images/message/Chat-Back.svg";
const friend = "assets/images/message/Chat-Friends.svg";
const addFriend = "assets/images/message/Char-Add-Friend.svg";
const menu = "assets/images/message/Chat-Menu.svg";

function ChatRoom() {
  const { state } = useLocation();
  const channelUrl = state.channelUrl;
  const previousPage = state.previousPage;

  const userData = useSelector((state) => state.user.data);

  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [isFriend, setIsFriend] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dataProfile, setDataProfile] = useState();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelUrl}/members`,
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );

        let member = response.data.members.filter(
          (user) => user.user_id !== userData?.dooNineId
        );
        setMembers(member);

        const q = query(
          collection(firestore, "users"),
          where("dooNineId", "==", member[0].user_id)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          let isFriend = false;
          setDataProfile(doc.data())
          const q3 = query(
            collection(firestore, "friends"),
            where("friendId", "==", doc.data().uid),
            where("userId", "==", userData?.uid)
          );

          const q2 = query(
            collection(firestore, "friends"),
            where("friendId", "==", userData?.uid),
            where("userId", "==", doc.data().uid)
          );

          const querySnapshot2 = await getDocs(q2);
          querySnapshot2.forEach(async (doc2) => {
            if (doc2.data().status == "active") {
              isFriend = true;
            }
          });

          const querySnapshot3 = await getDocs(q3);
          querySnapshot3.forEach(async (doc3) => {
            if (doc3.data().status == "active") {
              isFriend = true;
            }
          });

          if (isFriend) {
            console.log("isFriend");
            setIsFriend(true);
          } else {
            console.log("notFriend");
            setIsFriend(false);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    if (userData) {
      fetchData();
    }
  }, [channelUrl, userData]);

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate('/userPage')
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      const container = document.querySelector('.sendbird-conversation__scroll-container');
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [channelUrl])
  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />
      <SendbirdProvider
        appId={'B72515CE-E0DA-417F-AF02-4BF3D07B3D50'}
        userId={userData?.dooNineId}
        accessToken={userData?.sendBirdAccessToken}
      >
        <div className="chat-room-container">
          <div className="main-content right-chat-active chat-room-content">
            <GroupChannel
              channelUrl={channelUrl}
              renderChannelHeader={() => (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      borderStartEndRadius: "16px",
                      borderStartStartRadius: "16px",
                      marginTop: "5px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ReactSVG
                        src={backChat}
                        style={{ width: "30px" }}
                        onClick={() => navigate("/chat")}
                      />
                      <p
                        style={{
                          fontWeight: "bold",
                          marginBottom: 0,
                          marginLeft: "10px",
                          fontSize: "18px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "-0.2px",
                          fontFamily: "var(--sendbird-font-family-default)",
                        }}
                      >
                        {members[0]?.nickname}
                      </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ marginBottom: 0, marginRight: "10px" }}>
                        {isFriend ? (
                          <ReactSVG src={friend} style={{ width: "35px" }} />
                        ) : (
                          <ReactSVG src={addFriend} style={{ width: "30px" }} />
                        )}
                      </p>
                      <div>
                        <ReactSVG
                          src={menu}
                          style={{ width: "35px", cursor: "pointer" }}
                          onClick={toggleDropdown}
                        />

                        {dropdownOpen && (
                          <Dropdown
                            className="drodownFriend"
                            drop="down-centered"
                            show={dropdownOpen}
                            onToggle={toggleDropdown}
                          >

                            <Dropdown.Toggle
                              as="i"
                              id="dropdown-basic"
                              style={{ cursor: "pointer", zIndex: 1000 }}
                            ></Dropdown.Toggle>

                            <Dropdown.Menu
                              className="dropdownChat"
                              as={CustomDropdown}
                              popperConfig={{
                                modifiers: [
                                  {
                                    name: "flip",
                                    options: {
                                      altBoundary: true,
                                      rootBoundary: "viewport",
                                      padding: 8,
                                    },
                                  },
                                  {
                                    name: "preventOverflow",
                                    options: {
                                      altAxis: true,
                                      altBoundary: true,
                                      tether: false,
                                    },
                                  },
                                  {
                                    name: "offset",
                                    options: {
                                      offset: [0, 10],
                                    },
                                  },
                                ],
                              }}
                              style={{ zIndex: 1050 }}
                            >
                              <Dropdown.Item style={{ display: "flex" }} onClick={() => handleProfileClick(dataProfile.uid)}>
                                Profile
                              </Dropdown.Item>
                              <Dropdown.Item style={{ display: "flex" }} disabled>
                                Pin Chat
                              </Dropdown.Item>
                              <Dropdown.Item style={{ display: "flex" }} disabled>
                                Leave Chat
                              </Dropdown.Item>
                              <hr className="verticalHeader" />
                              <Dropdown.Item style={{ display: "flex" }} disabled>
                                Block
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr className="verticalHeader" />
                </>
              )}
            />
          </div>
        </div>

        {/* <div className="chat-room-container">
        <div className="main-content right-chat-active chat-room-content">
          <div className="middle-sidebar-bottom">
            <div className="col-xl-8 col-xxl-9 col-lg-8 App-chat-desktop">
              <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                <GroupChannel
                  renderChannelHeader={() => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                        background: "white",
                        borderStartEndRadius: "16px",
                        borderStartStartRadius: "16px",
                      }}
                    >
                      <div className="flex-item">
                        <button
                          className="btn"
                          onClick={() => navigate("/" + previousPage)}
                          style={{
                            marginRight: "10px",
                            backgroundColor: "#EE3D7F",
                            color: "white",
                          }}
                        >
                          Back
                        </button>
                      </div>
                      <div className="flex-item d-flex align-items-center">
                        <p
                          style={{
                            fontWeight: "bold",
                            marginBottom: 0,
                            marginLeft: "10px",
                          }}
                        >
                          {members[0]?.nickname}
                        </p>
                        <p style={{ marginBottom: 0, marginLeft: "10px" }}>
                          ({isFriend ? "Friend" : "Not friend"})
                        </p>
                      </div>

                      <div className="flex-item">i</div>
                    </div>
                  )}
                  channelUrl={channelUrl}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      </SendbirdProvider >
      <Popupchat />
      <Appfooter />
    </>
  );
}

export default ChatRoom;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}