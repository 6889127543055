import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import {
  getDoc,
  query,
  where,
  getDocs,
  collection,
  doc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function SelectCourseModal({
  showSelectCourseModal,
  setShowSelectCourseModal,
}) {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.data);
  const [selectedCourseHole, setSelectedCourseHole] = useState("");
  const [holeData, setHoleData] = useState([]);
  const [roundId, setRoundId] = useState("");

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams(window.location.search);
      const roundId = params.get("round");
      setRoundId(roundId);

      const docRef = doc(firestore, "round", roundId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let numberOfHole;
        if (docSnap.data().holes == "9 holes") {
          numberOfHole = "9holes";
        } else {
          numberOfHole = "18holes";
        }

        const q = query(
          collection(firestore, "courseHoles"),
          where("courseId", "==", docSnap.data().course),
          where("numberOfHole", "==", numberOfHole)
        );
        const querySnapshot = await getDocs(q);
        let array = [];
        querySnapshot.forEach((doc) => {
          array.push({ docId: doc.id, ...doc.data() });
        });
        setHoleData(array);
      }
    }
    if (userData) {
      fetchData();
    }
  }, [userData]);

  const handleCloseModal = () => {
    setShowSelectCourseModal(false);
  };

  const handleSubmit = async () => {
    if (selectedCourseHole) {
      if (selectedCourseHole !== "-") {
        const ref = doc(firestore, "round", roundId);
        await updateDoc(ref, {
          holesCourse: selectedCourseHole,
        });

        setShowSelectCourseModal(false);
        navigate(0); // This will refresh the page
      } else {
        console.log("No course hole selected 2.");
        Swal.fire({
          title: "Invalid Course",
          html: `
              <h5 class="fw-600">The course you selected is invalid.</h5>
              <h5 class="fw-600">Please check and try again.</h5>
            `,
          icon: "error",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });
      }
    } else {
      console.log("No course hole selected 1.");
      Swal.fire({
        title: "No Course Hole Selected",
        html: `
            <h5 class="fw-600">Please check and try again.</h5>
          `,
        icon: "error",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <Modal show={showSelectCourseModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2
              style={{ display: "inline-block", marginLeft: "15px" }}
              className="fw-700 mb-0 mt-0 font-sm text-grey-700"
            >
              Please select course
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 mb-3">
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss mb-2">
                Cours Hole
              </label>
              <select
                name="courseHole"
                id="courseHole"
                className="form-select"
                value={selectedCourseHole} // Bind state value
                onChange={(e) => setSelectedCourseHole(e.target.value)} // Update state on change
              >
                <option value={"-"}>-</option>

                {holeData.map((hole, index) => (
                  <option key={index} value={hole.docId}>
                    {hole.courseHoleName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn bg-greylight"
            onClick={() => handleCloseModal()}
          >
            <h4 className="fw-600 m-0 text-grey-700 p-1">Close</h4>
          </button>
          <button
            className="btn bg-current post-btn"
            onClick={() => handleSubmit()}
          >
            <h4 className="fw-600 m-0 text-white p-1">OK</h4>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SelectCourseModal;
