import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";
import { blockFriend } from "../../../components/friend/BlockFriend";
import { UnFriend } from "../../../components/friend/UnFriend";
const ChannelHeader = ({
  members,
  isFriend,
  friend,
  addFriend,
  menu,
  toggleDropdown,
  dropdownOpen,
  handleProfileClick,
  dataProfile,
  handleLeaveChannel,
  channel,
}) => {
  const userData = useSelector((state) => state.user.data);
  const [channelData, setChannelData] = useState(null);

  const handleBlock = (channel) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Blocking the user will result in the chat being deleted, and they will automatically be unfriended.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Block friend",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // console.log(channel);
        // console.log(members);
        // console.log(friend);
        // console.log(isFriend);

        // console.log("dataProfile : ", dataProfile);
        // console.log("userData : ", userData);

        // console.log("dataProfile UID : ", dataProfile.uid);
        // console.log("userData UID : ", userData.uid);

        // Step 1 - Delete Chat
        try {
          await axios.delete(
            `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channel}`,

            {
              headers: {
                "Content-Type": "application/json; charset=utf8",
                "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
              },
            }
          );

          // Step 2- Block User
          if (await blockFriend(userData.uid, dataProfile.uid)) {
            if (isFriend) {
              if (await UnFriend(userData.uid, dataProfile.uid)) {
                Swal.fire(
                  "Unfriended!",
                  "User has been unfriended.",
                  "success"
                );
              }
            }
            Swal.fire("Blocked!", "User has been blocked.", "success");
          }

          // Step 3 - Unfriend User
        } catch (error) {
          console.error("Error leaving channel:", error);
        }
      }
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channel}`,
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );
        setChannelData(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    if (channel) {
      fetchData();
    }
  }, [channel]);

  console.log(channelData);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          borderStartEndRadius: "10px",
          borderStartStartRadius: "10px",
          marginTop: "5px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              fontWeight: "bold",
              marginBottom: 0,
              marginLeft: "10px",
              fontSize: "18px",
              fontWeight: "600",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "-0.2px",
              fontFamily: "var(--sendbird-font-family-default)",
            }}
          >
            {channelData?.joined_member_count > 2
              ? channelData?.name + "(" + channelData?.joined_member_count + ")"
              : members[0]?.nickname}
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginBottom: 0, marginRight: "10px" }}>
            {isFriend ? (
              <ReactSVG src={friend} style={{ width: "35px" }} />
            ) : (
              <ReactSVG src={addFriend} style={{ width: "30px" }} />
            )}
          </div>
          <div>
            <ReactSVG
              src={menu}
              style={{ width: "35px", cursor: "pointer" }}
              onClick={toggleDropdown}
            />

            {dropdownOpen && (
              <Dropdown
                className="drodownFriend"
                drop="down-centered"
                show={dropdownOpen}
                onToggle={toggleDropdown}
              >
                <Dropdown.Toggle
                  as="i"
                  id="dropdown-basic"
                  style={{ cursor: "pointer", zIndex: 1000 }}
                ></Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropdownChatDesktop"
                  as={CustomDropdown}
                  popperConfig={{
                    modifiers: [
                      {
                        name: "flip",
                        options: {
                          altBoundary: true,
                          rootBoundary: "viewport",
                          padding: 8,
                        },
                      },
                      {
                        name: "preventOverflow",
                        options: {
                          altAxis: true,
                          altBoundary: true,
                          tether: false,
                        },
                      },
                      {
                        name: "offset",
                        options: {
                          offset: [0, 10],
                        },
                      },
                    ],
                  }}
                  style={{ zIndex: 1050 }}
                >
                  <Dropdown.Item
                    style={{ display: "flex" }}
                    onClick={() => handleProfileClick(dataProfile.uid)}
                  >
                    Profile
                  </Dropdown.Item>
                  {/* <Dropdown.Item style={{ display: "flex" }} disabled>
                    Pin Chat
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    style={{ display: "flex" }}
                    onClick={() => {
                      handleLeaveChannel(channel, userData?.dooNineId);
                    }}
                  >
                    Leave Chat
                  </Dropdown.Item>
                  <hr className="verticalHeader" />
                  <Dropdown.Item
                    style={{ display: "flex" }}
                    onClick={() => {
                      handleBlock(channel);
                    }}
                  >
                    Block
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
      <hr className="verticalHeader" />
    </>
  );
};

export default ChannelHeader;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
