import React, { useState, useEffect } from "react";
import Header from '../../../components/Header';
import Leftnav from '../../../components/Leftnav';
import Rightchat from '../../../components/Rightchat';
import Appfooter from '../../../components/Appfooter';
import Popupchat from '../../../components/Popupchat';

import { Card } from "react-bootstrap";
import { PhotoProvider, PhotoView } from "react-photo-view";
import FriendCount from "../module/FriendCount";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { Dropdown } from "react-bootstrap";
import '../myperfprofile/myperfstyle.css'
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { getDocs, query, where, collection } from 'firebase/firestore';

import { Spinner } from 'react-bootstrap';

import { DistributionChart, ScoreByParChart } from "../module/Chart";
import { defaultClubs } from "./module/clubDefaultData";

import DefaultTeeBox from "../profile/player/statcomponent/DefaultTeeBox";
import Handicap from "../profile/player/statcomponent/Handicap";

const PlayerMyPerf = () => {

    const userData = useSelector((state) => state.user.data);

    const [selectedCompletedRound, setSelectedCompletedRound] = useState("All Time");
    const [myClub, setMyclub] = useState([]);
    const [loadingClub, setLoadingClub] = useState(true);

    const handleSelectCompltedRound = (selected) => {

        setSelectedCompletedRound(selected)
    }

    useEffect(() => {
        async function getMyClub() {
            const clubsCollection = collection(firestore, 'clubs');
            const q = query(clubsCollection, where("userId", "==", userData.uid));
            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const data = querySnapshot.docs[0].data();
                    setMyclub(data.clubs);
                } else {
                    setMyclub(defaultClubs)
                }
                setLoadingClub(false)
            } catch (e) {
                setLoadingClub(false)
                console.error("Error adding or updating document: ", e);
            }

        }
        if (userData) {
            getMyClub()
        }
    }, [userData])

    return (
        <>
            <Header />
            <Leftnav />
            <Rightchat />
            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12 mb-3 p-0 px-sm-3">
                                <Card className="w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                                    <Card.Body className="p-0 rounded-xxl overflow-hidden m-3 profile-cover-card" style={{ height: 250 }}>
                                        {userData?.coverImage ? (
                                            <PhotoProvider bannerVisible={false}>
                                                <PhotoView src={userData?.coverImage}>
                                                    <img src={userData?.coverImage} alt="avater" style={{ objectFit: 'cover', width: '100%', height: '100%', cursor: 'pointer' }} />
                                                </PhotoView>
                                            </PhotoProvider>
                                        ) : (
                                            <div className='w-100 h-100 bg-light'></div>
                                        )}
                                    </Card.Body>
                                    <Card.Body className="p-0 position-relative profile-image-card">
                                        <PhotoProvider bannerVisible={false}>
                                            <figure className="avatar position-absolute w100 h100 z-index-1" style={{ top: '-40px', left: '30px' }}>
                                                <PhotoView src={userData?.profileImage}>
                                                    <img src={userData?.profileImage} alt="avater"
                                                        className="float-right p-1 rounded-circle w-100 h-100 player-color" style={{ objectFit: 'cover', cursor: 'pointer' }} />
                                                </PhotoView>
                                            </figure>
                                        </PhotoProvider>
                                        <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">{userData?.firstName || userData?.firstname} {userData?.lastName || userData?.lastname}
                                            <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block text-truncate"><FriendCount user={userData} /></span>
                                        </h4>
                                        <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2 mb-3 mb-sm-0">
                                            <Link to={'/userPage'}>
                                                <button className="btn text-white p-2 px-3 player-color">My Profile</button>
                                            </Link>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-xl-4 col-xxl-3 col-lg-4 pe-sm-0 px-sm-3 px-0 mb-2">
                                <Card className="border border-0 shadow-xss rounded-xxl">
                                    {/* my clubs */}
                                    <Card.Body>
                                        <div className="d-flex justify-content-between">
                                            <h4 className="fw-bold">My Clubs</h4><Link to="/myclubs">See all</Link>
                                        </div>
                                        {!loadingClub ? (
                                            <>
                                                <div className="d-flex justify-content-center align-items-center p-3 rounded-3 mb-2" style={{ backgroundColor: '#E9F6E9' }}>
                                                    <strong className="text-center py-1 px-3 fs-1 fw-bolder me-2 rounded-3 text-white" style={{ backgroundColor: '#81D77A' }}>{myClub.filter(club => club.inBag).length}</strong>
                                                    <strong className="fs-4">Clubs in your bag</strong>
                                                </div>
                                                <div className="py-2 px-4 rounded-3" style={{ backgroundColor: '#EBF6FF' }}>
                                                    {myClub
                                                        .filter(club => club.inBag)
                                                        .sort((a, b) => b.distance - a.distance)
                                                        .slice(0, 14)
                                                        .map((club, index) => (
                                                            <div key={index} className="d-flex justify-content-between align-items-center">
                                                                <strong className="text-truncate me-4">{club.name}</strong>
                                                                <div className="text-nowrap">{club.distance} {club.distance ? "yd" : null}</div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </>
                                        ) : (
                                            <div className="d-flex justify-content-center">
                                                <Spinner variant="grey" animation="border" role="status" size="sm">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div>
                                        )}
                                    </Card.Body>
                                </Card>
                            </div>

                            {/* my stat */}
                            <div className="col-xl-8 col-xxl-9 col-lg-8 px-sm-3 px-0" >
                                <Card className="border border-0 shadow-xss rounded-xxl">
                                    <Card.Body className="main-player-stat">
                                        <h4 className="fw-bold">My Statistics</h4>
                                        {/* completed round */}
                                        <section className="d-flex justify-content-between align-items-center py-2 px-3 rounded-3 mb-2" style={{ backgroundColor: '#E9F6E9' }}>
                                            {/* <div className="d-none d-sm-flex justify-content-center align-items-center rounded-circle" style={{ width: 60, height: 60, backgroundColor: '#81D77A' }}> */}
                                            {/* <i className="bi bi-activity text-white"
                                                    style={{ fontSize: 40 }}>
                                                </i> */}
                                            <ReactSVG src="assets/images/dooNineIconV3/my-performance/profile-total-rounds.svg" wrapper="svg" className="d-none d-sm-block" width={70} height={70} />
                                            {/* </div> */}
                                            <div className="me-auto ms-4">
                                                <div className="d-sm-block d-flex align-items-center gap-2">
                                                    <ReactSVG src="assets/images/dooNineIconV3/my-performance/profile-total-rounds.svg" wrapper="svg" className="d-sm-none" width={50} height={50} />
                                                    <strong className="" style={{ color: '#81D77A' }}>0</strong> <br />
                                                </div>
                                                <small>Total number of rounds you  have completed</small>
                                            </div>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" className="btn rounded-pill bg-white py-2 px-4 fs-5 fw-bold text-black w125">
                                                    {selectedCompletedRound}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu style={{ borderRadius: 15 }} className="border-0">
                                                    <Dropdown.Item onClick={() => handleSelectCompltedRound('All Time')} className="fw-600">All Time</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSelectCompltedRound('Last 20')} className="fw-600">Last 20</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleSelectCompltedRound('Last 5')} className="fw-600">Last 5</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </section>
                                        {/* handicap & putt */}
                                        <section className="gap-2 d-flex flex-md-nowrap flex-wrap hand-putt-section mb-2">
                                            <Handicap data={userData} />
                                            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3" style={{ backgroundColor: '#F5F3FF' }}>
                                                <strong className="px-3 rounded-3" style={{ backgroundColor: '#5D3DB5' }}>
                                                    0
                                                </strong>
                                                <span className="mb-3 ms-2">Putts per hole</span>
                                            </div>
                                        </section>
                                        {/* Greens & Best */}
                                        <section className="gap-2 d-flex flex-md-nowrap flex-wrap reg-best-section mb-2">
                                            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3" style={{ backgroundColor: '#E9F6E9' }}>
                                                <strong className="px-3 rounded-3" style={{ backgroundColor: '#81D77A' }}>
                                                    0
                                                </strong>
                                                <span className="mb-3 ms-2">{`Greens in Regulation(%)`}</span>
                                            </div>
                                            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3" style={{ backgroundColor: '#FDE7EF' }}>
                                                <strong className="px-3 rounded-3" style={{ backgroundColor: '#EE3C7F' }}>
                                                    0
                                                </strong>
                                                <span className="mb-3 ms-2">{`Best Score (to par)`}</span>
                                            </div>
                                        </section>
                                        {/* Teebox */}
                                        <section className="d-flex gap-2 flex-md-nowrap flex-wrap reg-best-section mb-2">
                                            <DefaultTeeBox data={userData} />
                                        </section>
                                        {/* Score Distribution */}
                                        <section className="mb-2 text-center pt-2 rounded-3" style={{ height: 'auto', backgroundColor: '#F5F5F5' }}>
                                            <DistributionChart userId={userData.uid} />
                                        </section>
                                        <section className="mb-2 text-center pt-2 rounded-3" style={{ height: 'auto', backgroundColor: '#F5F5F5' }}>
                                            <ScoreByParChart userId={userData.uid} />
                                        </section>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />

        </>
    );
}

export default PlayerMyPerf;