import { useState, useEffect, useRef } from 'react';

const useModalWithHistory = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef(false);
    const openModal = () => {
        setIsModalOpen(true);
        modalRef.current = true;
        window.history.pushState({ modal: true }, '');
    };

    const closeModal = () => {
        setIsModalOpen(false);
        if (window.history.state?.modal) {
            window.history.back();
        }
    };

    useEffect(() => {

        const handlePopState = (event) => {
            if (event.state?.modal || modalRef.current) {
                setIsModalOpen(false);
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    return {
        isModalOpen,
        openModal,
        closeModal,
    };
};

export default useModalWithHistory;
