import React, { useRef, useState } from "react";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";

function CourseDetailHeader({
  courseData,
  section,
  setSection,
  favoriteCourse,
  trigger,
  setTrigger,
}) {
  const userData = useSelector((state) => state.user.data);

  const handleFavoriteCourse = async (courseId, isCourseFavorited) => {
    const timestampInSecond = Math.round(new Date().getTime() / 1000);

    const favoriteCourseRef = collection(firestore, "favoriteCourses");
    const querySnapshot = await getDocs(
      query(
        favoriteCourseRef,
        where("userId", "==", userData.uid),
        where("courseId", "==", courseId)
      )
    );

    if (querySnapshot.empty) {
      // If no document exists, add a new one
      const docRef = await addDoc(favoriteCourseRef, {
        userId: userData.uid,
        status: "active",
        timestamp: timestampInSecond,
        courseId: courseId,
      });
    } else {
      // If document exists, update it
      querySnapshot.forEach(async (doc) => {
        await updateDoc(doc.ref, {
          status: isCourseFavorited ? "inactive" : "active",
          timestamp: timestampInSecond,
        });
      });
    }
    setTrigger(!trigger);
  };

  return (
    <>
      <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
        <div
          className="card-body h250 p-0 rounded-xxl overflow-hidden m-3"
          style={{ width: "auto", height: "250px" }}
        >
          <img
            src={courseData?.coverImage}
            alt="avatar"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>

        <div className="card-body p-0 position-relative">
          <figure
            className="avatar position-absolute w100 z-index-1"
            style={{ top: "-40px", left: "30px" }}
          >
            <img
              src={courseData?.profileImage}
              alt="avatar"
              className="float-right p-1 rounded-circle w-100"
              style={{
                backgroundColor: "#81D77A",
              }}
            />
          </figure>
          <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-2 pl-15 text-start">
            {courseData?.courseName}
            <span className="fw-500 font-xssss text-grey-500 mt-1 mb-2 d-block">
              {`${courseData?.location?.districtEng}, ${courseData?.location?.provinceEng}`}
              <span
                onClick={() =>
                  handleFavoriteCourse(courseData.courseId, favoriteCourse)
                }
              >
                {favoriteCourse ? (
                  <i
                    className="btn-round-sm font-lg bi bi-bookmark-fill ms-2 pointer"
                    style={{
                      color: "#E59E49",
                    }}
                  ></i>
                ) : (
                  <i
                    className="btn-round-sm font-md bi bi-bookmark ms-2 pointer"
                    style={{
                      color: "#B0B5BD",
                    }}
                  ></i>
                )}
              </span>
            </span>
          </h4>
          <div className="d-flex align-items-center justify-content-start position-absolute-md right-15 top-0 me-3 ms-3 mb-3">

            {/* por 020924 */}

            {/* <button
              className="btn bg-success p-3 z-index-1 rounded-3 text-white font-xsssss fw-700 ls-3 d-block w-100"
              disabled
            >
              Start Round
            </button> */}

            {/* <button
              className="btn player-color p-3 z-index-1 rounded-3 text-white font-xssss fw-700 ls-3 d-block w-100"
              disabled
            >
              Start Round
            </button> */}
            <button className="btn text-white p-2 px-3 golfCourse-color" disabled>Start Round</button>

            {/* <button
              id="dropdownMenu4"
              className="btn bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              disabled
            >
              <i className="ti-more font-md tetx-dark"></i>
            </button> */}


            {/* por 020924 */}


            <div
              className="dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg"
              aria-labelledby="dropdownMenu4"
            >
              <div className="card-body p-0 d-flex">
                <i className="feather-bookmark text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                  Save Link{" "}
                  <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                    Add this to your saved items
                  </span>
                </h4>
              </div>
              <div className="card-body p-0 d-flex mt-2">
                <i className="feather-alert-circle text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                  Hide Post{" "}
                  <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                    Save to your saved items
                  </span>
                </h4>
              </div>
              <div className="card-body p-0 d-flex mt-2">
                <i className="feather-alert-octagon text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">
                  Hide all from Group{" "}
                  <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                    Save to your saved items
                  </span>
                </h4>
              </div>
              <div className="card-body p-0 d-flex mt-2">
                <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-0">
                  Unfollow Group{" "}
                  <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                    Save to your saved items
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
          <ul
            className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4"
            id="pills-tab"
            role="tablist"
          >
            <li className="active list-inline-item me-5">
              <a
                className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${section === "about" ? "active" : ""
                  }`}
                onClick={() => setSection("about")}
                style={{
                  cursor: "pointer",
                }}
              >
                About
              </a>
            </li>
            <li className="list-inline-item me-5">
              <a
                className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${section === "course" ? "active" : ""
                  }`}
                onClick={() => setSection("course")}
                style={{
                  cursor: "pointer",
                }}
              >
                Course
              </a>
            </li>
            <li className="list-inline-item me-5">
              <a
                className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${section === "caddie" ? "active" : ""
                  }`}
                onClick={() => setSection("caddie")}
                style={{
                  cursor: "pointer",
                }}
              >
                Caddie
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default CourseDetailHeader;
