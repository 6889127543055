import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

function HoleNumberBox({
  row,
  showHoleDetailModal,
  setShowHoleDetailModal,
  handleClickHoleNumberBox,
}) {
  const cellStyle = {
    padding: "5px 5px 5px 5px",
    textAlign: "center",
    borderBottom: "1px solid #ddd",
    fontSize: "14px",
  };

  const colorStyles = {
    grey: { backgroundColor: "#A6A6A6", borderRadius: "6px" },
    white: { backgroundColor: "#F5F5F5", borderRadius: "6px" },
  };

  const handleClickBox = () => {
    setShowHoleDetailModal(true);
    handleClickHoleNumberBox(row);
  };

  return (
    <>
      <div
        style={{
          ...cellStyle,
          ...colorStyles.white,
          position: "relative", // ใช้สำหรับการวางตำแหน่งแบบ absolute
          fontSize: "2rem",
          lineHeight: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleClickBox()}
      >
        {/* ตัวเลขหลัก สีชมพู เยื้องด้านหน้า */}
        <span
          style={{
            color: "#f5427d", // สีชมพูสำหรับตัวเลขหลัก
            position: "relative",
            zIndex: 1, // ให้อยู่ด้านหน้า
          }}
        >
          <h2
            className="fw-600"
            style={{
              color: "#f5427d",
            }}
          >
            {row.holeNumber}
          </h2>
        </span>

        {/* ตัวเลข 4 และ 1 ด้านหลัง */}
        <div
          style={{
            position: "absolute", // ซ้อนตัวเลขด้านหลัง
            top: "0.5rem", // จัดตำแหน่งด้านบน
            right: "1rem", // เลื่อนให้เลขด้านหลังเยื้องไป
            zIndex: 0, // ให้เป็นตัวที่อยู่ด้านหลัง
            display: "flex",
            flexDirection: "column", // จัดเรียงเลข 4 และ 1 ในแนวตั้ง
            alignItems: "center",
          }}
        >
          {/* ตัวเลข 4 ด้านบน */}
          <span
            style={{
              fontSize: "1rem",
              color: "gray",
              marginBottom: "0.2rem", // ระยะห่างระหว่างเลข 4 และเลข 1
            }}
          >
            {row.par}
          </span>
          {/* ตัวเลข 1 ด้านล่าง */}
          <span
            style={{
              fontSize: "1rem",
              color: "gray",
            }}
          >
            {row.handicapIndex}
          </span>
        </div>
      </div>
    </>
  );
}

export default HoleNumberBox;
