import moment from "moment";
const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';


export const notiTypeDef = (noti) => {

    switch (noti.type) {
        case 'add':
            return (
                <>
                    <div className="top-noti">
                        {!noti.isRead && <i className="bi bi-dot red-dot"></i>}
                        <div className="text-end text-muted">{moment(noti.timestamp * 1000).fromNow()}</div>
                    </div>
                    <div className="d-flex">
                        <img src={noti.user.profileImage || defaultProfile} className="me-2" alt="img" width={40} height={40} style={{ objectFit: 'cover', borderRadius: '100%' }}
                            onError={(e) => {
                                e.target.onerror = null;  // Prevents endless loop in case fallback image also fails
                                e.target.src = defaultProfile;  // Replace with default image on error
                            }}
                        />
                        <h4><div className="fw-600 fs-4">
                            {noti.user.firstName || noti.user.lastName ?
                                `${noti?.user?.firstName} ${noti?.user?.lastName}` :
                                "Invalid user"}
                        </div> sent you a friend request.</h4>
                    </div>
                </>
            );

        case 'accept':
            return (
                <>
                    <div className="top-noti">
                        {!noti.isRead && <i className="bi bi-dot red-dot"></i>}
                        <div className="text-end text-muted">{moment(noti.timestamp * 1000).fromNow()}</div>
                    </div>
                    <div className="d-flex">
                        <img src={noti.user.profileImage || defaultProfile} className="me-2" alt="img" width={40} height={40} style={{ objectFit: 'cover', borderRadius: '100%' }} />
                        <h4><div className="fw-600 fs-4">
                            {noti.user.firstName || noti.user.lastName ?
                                `${noti?.user?.firstName} ${noti?.user?.lastName}` :
                                "Invalid user"}
                        </div> accept your friend request.</h4>
                    </div>
                </>
            );

        case 'comment':
            return (
                <>
                    <div className="top-noti">
                        {!noti.isRead && <i className="bi bi-dot red-dot"></i>}
                        <div className="text-end text-muted">{moment(noti.timestamp * 1000).fromNow()}</div>
                    </div>
                    <div className="d-flex">
                        <img src={noti.user.profileImage || defaultProfile} className="me-2" alt="img" width={40} height={40} style={{ objectFit: 'cover', borderRadius: '100%' }} />
                        <h4><div className="fw-600 fs-4">
                            {noti.user.firstName || noti.user.lastName ?
                                `${noti?.user?.firstName} ${noti?.user?.lastName}` :
                                "Invalid user"}
                        </div> Commented your post.</h4>
                    </div>
                </>
            )
        default:
            return (
                <div>
                    <strong>Unknown Notification Type</strong>
                </div>
            );
    }
};
