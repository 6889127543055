import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { Dropdown } from "react-bootstrap";
import axios from "axios";

const GroupChannelListPreview = ({
  channel,
  dropdownOpenChannelPre,
  setDropdownOpenChannelPre,
  handleDropdownToggle,
  dropdownRef,
  handleProfileClick,
  handleLeaveChannel,
  selectedChannelUrl,
}) => {
  const userData = useSelector((state) => state.user.data);
  const dropdownId = channel.url;
  const isOpen = dropdownOpenChannelPre === dropdownId;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dataChannelPreview, setDataChannelPreview] = useState();
  const [member, setMember] = useState([]);

  // console.log("--------------------");
  // console.log(channel);
  // console.log(channel._name);
  // console.log(channel.memberCount);

  useEffect(() => {
    const fetchMember = async () => {
      const memberData = channel.members.filter(
        (user) => user.userId !== userData?.dooNineId
      );
      setMember(memberData);
      if (memberData.length > 0) {
        const userQuery = query(
          collection(firestore, "users"),
          where("dooNineId", "==", memberData[0].userId)
        );
        const userQuerySnapshot = await getDocs(userQuery);

        if (!userQuerySnapshot.empty) {
          const userDoc = userQuerySnapshot.docs[0];

          const application_id = "B72515CE-E0DA-417F-AF02-4BF3D07B3D50";
          const url = `https://api-${application_id}.sendbird.com/v3/users/${
            userDoc.data().dooNineId
          }`;
          const apiKey = "1a3a0f2103a01d56c01cde731a802118945aeeb4";

          try {
            const res = await axios.get(url, {
              headers: {
                "Content-Type": "application/json; charset=utf8",
                "Api-Token": apiKey,
              },
            });
            setDataChannelPreview({
              last_seen_at: res.data.last_seen_at,
              ...userDoc.data(),
            });
          } catch (error) {
            console.error("Error creating user:", error);
          }
        }
      }
    };
    fetchMember();
  }, [channel, userData]);

  // console.log("-------------------------");
  // console.log(channel.lastMessage);
  // console.log(channel.lastMessage.message);
  // console.log(channel.lastMessage.messageType);
  //   console.log(selectedChannelUrl);
  //   console.log(dataChannelPreview);

  const toggleDropdownChannelList = (e) => {
    e.stopPropagation();
    handleDropdownToggle(dropdownId);
  };

  const formattedTime = formatDate(channel.lastMessage.createdAt);

  return (
    <>
      <div
        className="sendbird-channel-preview"
        role="link"
        tabIndex="0"
        style={{
          backgroundColor:
            selectedChannelUrl === channel.url ? "#FCD8E5" : "#fff",
        }}
      >
        <div className="sendbird-channel-preview__avatar ">
          <div
            className="sendbird-chat-header--avatar--group-channel sendbird-avatar"
            role="button"
            tabIndex="0"
            style={{
              height: "65px",
              width: "65px",
              zIndex: 0,
              borderStyle: "solid",
              borderColor:
                dataChannelPreview?.role === "player" ? "#4A74E5" : "#EE3D7F",
              borderWidth: "4px",
              position: "relative",
              overflow: "hidden",
              borderRadius: "50%",
            }}
          >
            <div
              className="sendbird-avatar-img sendbird-image-renderer"
              style={{
                width: "100%",
                minWidth: "calc(56px)",
                maxWidth: "400px",
                height: "calc(56px)",
              }}
            >
              <div
                className="sendbird-image-renderer__image"
                style={{
                  width: "100%",
                  minWidth: "calc(56px)",
                  maxWidth: "400px",
                  height: "calc(56px)",
                  position: "absolute",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: " center center",
                  backgroundSize: "cover",
                  backgroundImage: `url(${member[0]?.plainProfileUrl})`,
                }}
              >
                <img
                  className="sendbird-image-renderer__hidden-image-loader"
                  src={member[0]?.plainProfileUrl}
                  alt="Group Channel"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "5px",
              left: "60px",
              width: "16px",
              height: "16px",
              backgroundColor:
                dataChannelPreview?.last_seen_at == "0" ? "#3ce44d" : "#B0B5BD",
              borderRadius: "50%",
              border: "3px solid white",
            }}
          ></div>
        </div>

        <div className="sendbird-channel-preview__content">
          <div className="sendbird-channel-preview__content__upper">
            <div className="sendbird-channel-preview__content__upper__header">
              <span
                className="sendbird-channel-preview__content__upper__header__channel-name sendbird-label sendbird-label--subtitle-2 sendbird-label--color-onbackground-1"
                data-testid="sendbird-channel-preview__content__upper__header__channel-name"
              >
                {/* Group Chat (> 2 member) Or Normal Chat (2 member) */}
                {channel.memberCount > 2
                  ? "(" + channel.memberCount + ") " + channel._name
                  : member[0]?.nickname}
              </span>
            </div>
            <span className="sendbird-channel-preview__content__upper__last-message-at sendbird-label sendbird-label--caption-3 sendbird-label--color-onbackground-2">
              {formattedTime}
            </span>
          </div>

          <div className="sendbird-channel-preview__content__lower">
            <span className="sendbird-channel-preview__content__lower__last-message sendbird-label sendbird-label--body-2 sendbird-label--color-onbackground-3">
              {channel.lastMessage.message}
              {channel.lastMessage.messageType == "file" ? "Image" : ""}
            </span>
            <div className="sendbird-channel-preview__content__lower__unread-message-count">
              {channel.unreadMessageCount > 0 ? (
                <div className="sendbird-badge">
                  <div className="sendbird-badge__text">
                    <span className="sendbird-label sendbird-label--caption-2 sendbird-label--color-oncontent-1">
                      {channel.unreadMessageCount}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="sendbird-channel-preview__action">
            <div tabIndex="0" role="button" style={{ display: "inline-block" }}>
              <div
                className="sendbird-context-menu"
                style={{ display: "inline", position: "relative" }}
              >
                <button
                  className="sendbird-iconbutton"
                  type="button"
                  style={{
                    height: "32px",
                    width: "32px",
                    visibility: "visible",
                    opacity: "1",
                  }}
                  onClick={toggleDropdownChannelList}
                >
                  <span className="sendbird-iconbutton__inner">
                    <div
                      className="sendbird-icon sendbird-icon-more sendbird-icon-color--primary"
                      role="button"
                      tabIndex="0"
                      style={{
                        width: "24px",
                        minWidth: "24px",
                        height: "24px",
                        minHeight: "24px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 64 64"
                      >
                        <path
                          fill="#000"
                          fillRule="evenodd"
                          d="M32 45.333a5.333 5.333 0 1 1 0 10.666 5.333 5.333 0 0 1 0-10.666M32 28a5.333 5.333 0 1 1 0 10.668A5.333 5.333 0 0 1 32 28m0-17.333c2.946 0 5.333 2.387 5.333 5.333S34.946 21.333 32 21.333 26.667 18.946 26.667 16s2.387-5.333 5.333-5.333"
                          className="icon-more_svg__fill"
                        ></path>
                      </svg>
                    </div>
                  </span>
                </button>

                {isOpen && (
                  <Dropdown
                    className="drodownFriend"
                    drop="down-centered"
                    show={isOpen}
                    ref={dropdownRef}
                    style={{ borderRadius: "15px" }}
                  >
                    <Dropdown.Toggle
                      as="i"
                      id="dropdown-basic"
                      style={{ cursor: "pointer" }}
                    ></Dropdown.Toggle>
                    <Dropdown.Menu
                      className="dropdownChatDesktop"
                      popperConfig={{
                        modifiers: [
                          {
                            name: "flip",
                            options: {
                              altBoundary: true,
                              rootBoundary: "viewport",
                              padding: 8,
                            },
                          },
                          {
                            name: "preventOverflow",
                            options: {
                              altAxis: true,
                              altBoundary: true,
                              tether: false,
                            },
                          },
                          {
                            name: "offset",
                            options: {
                              offset: [0, 10],
                            },
                          },
                        ],
                      }}
                    >
                      <Dropdown.Item
                        style={{ display: "flex" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleProfileClick(dataChannelPreview.uid);
                        }}
                      >
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ display: "flex" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLeaveChannel(channel.url, member[0]?.userId);
                        }}
                      >
                        Leave Channel
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupChannelListPreview;

const formatDate = (createdAt) => {
  const date = new Date(createdAt);
  const now = new Date();

  // คำนวณความแตกต่างของเวลาในวัน
  const timeDifference = now - date;
  const oneDay = 24 * 60 * 60 * 1000; // หนึ่งวันในมิลลิวินาที

  if (timeDifference < oneDay && date.getDate() === now.getDate()) {
    // ถ้าเป็นวันนี้ แสดงเวลา
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes} ${period}`;
  } else if (
    timeDifference < 2 * oneDay &&
    now.getDate() - date.getDate() === 1
  ) {
    // ถ้าเป็นเมื่อวาน แสดง "Yesterday"
    return "Yesterday";
  } else {
    // แสดงวันที่ในรูปแบบเดือน/วัน
    const options = { month: "short", day: "numeric" }; // เดือนแบบสั้น เช่น "Aug"
    return date.toLocaleDateString("en-US", options);
  }
};
