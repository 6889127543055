import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useSelector } from "react-redux";
import {
  getDoc,
  query,
  where,
  getDocs,
  collection,
  doc,
  addDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import HeaderScoreBox from "./component/HeaderScoreBox";
import HoleNumberBox from "./component/HoleNumberBox";
import ScoreBox from "./component/ScoreBox";
import AddScoreModal from "./component/AddScoreModal";
import HoleDetailModal from "./component/HoleDetailModal";
import SelectCourseModal from "./component/SelectCourseModal";

import HeaderDropdown from "./component/HeaderDropdown";
import { ReactSVG } from "react-svg";

function Score() {
  const navigate = useNavigate();

  const [roundId, setRoundId] = useState(null);
  const [roundData, setRoundData] = useState(null);
  const [holeData, setHoleData] = useState([]);
  const [isSelectedCourse, setIsSelectedCourse] = useState(false);
  const [scoreData, setScoreData] = useState(null);
  const [holeScoreData, setHoleScoreData] = useState([]);
  const [totalScoreData, setTotalScoreData] = useState([]);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [showSelectCourseModal, setShowSelectCourseModal] = useState(false);
  const [courseHoleData, setCourseHoleData] = useState(null);

  const [selectedHole, setSelectedHole] = useState(null);
  const [selectedHoleDetail, setSelectedHoleDetail] = useState(null);
  const [userList, setUserList] = useState(null);
  const [showHoleDetailModal, setShowHoleDetailModal] = useState(false);

  const userData = useSelector((state) => state.user.data);

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams(window.location.search);

      const roundId = params.get("round");
      setRoundId(roundId);

      if (roundId == null) {
        Swal.fire({
          title: "Invalid Round",
          html: `
            <h5 class="fw-600">The round you selected is invalid.</h5>
            <h5 class="fw-600">Please check and try again.</h5>
          `,
          icon: "error",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        }).then(async (result) => {
          navigate("/round-activity");
        });
      } else {
        const docRef = doc(firestore, "round", roundId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          //Check is scorecard exist

          let userList = docSnap.data().userList;

          if (docSnap.data().holesCourse) {
            console.log("เลือก course hole แล้ว");

            for (let index = 0; index < userList.length; index++) {
              const element = userList[index];

              const q = query(
                collection(firestore, "scoreCards"),
                where(
                  "userId",
                  "==",
                  element.player?.uid == undefined ? "" : element.player.uid
                ),
                where(
                  "caddieId",
                  "==",
                  element.caddie?.uid == undefined ? "" : element.caddie.uid
                ),
                where("roundId", "==", roundId)
              );

              if (
                element.player?.uid == undefined &&
                element.caddie?.uid == undefined
              ) {
              } else {
                const querySnapshot = await getDocs(q);
                if (querySnapshot.size === 0) {
                  console.log("No such document!");

                  if (docSnap.data().holes == "9 holes") {
                    const docRef = await addDoc(
                      collection(firestore, "scoreCards"),
                      {
                        caddieId:
                          element.caddie?.uid == undefined
                            ? ""
                            : element.caddie.uid,
                        roundId: roundId,
                        userId:
                          element.player?.uid == undefined
                            ? ""
                            : element.player.uid,
                        status: "playing",
                        hole1: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "1",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole2: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "2",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole3: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "3",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole4: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "4",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole5: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "5",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole6: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "6",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole7: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "7",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole8: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "8",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole9: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "9",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                      }
                    );
                  } else {
                    const docRef = await addDoc(
                      collection(firestore, "scoreCards"),
                      {
                        caddieId:
                          element.caddie?.uid == undefined
                            ? ""
                            : element.caddie.uid,
                        roundId: roundId,
                        userId:
                          element.player?.uid == undefined
                            ? ""
                            : element.player.uid,
                        status: "playing",
                        hole1: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "1",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole2: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "2",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole3: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "3",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole4: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "4",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole5: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "5",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole6: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "6",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole7: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "7",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole8: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "8",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole9: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "9",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole10: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "10",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole11: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "11",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole12: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "12",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole13: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "13",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole14: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "14",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole15: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "15",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole16: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "16",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole17: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "17",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                        hole18: {
                          bunkerHit: false,
                          drivingRange: "-",
                          fairwayHit: false,
                          holeNumber: "18",
                          penalties: "0",
                          putts: "-",
                          score: "-",
                        },
                      }
                    );
                  }
                }
              }
            }
          } else {
            console.log("ยังไม่เลือก course hole");
          }

          setUserList(docSnap.data().userList);

          for (let index = 0; index < userList.length; index++) {
            const element = userList[index];

            if (element.caddie !== null) {
              const q = query(
                collection(firestore, "users"),
                where("uid", "==", element.caddie.uid)
              );
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                userList[index].caddie.data = doc.data();
              });
            }

            if (element.player !== null) {
              const q = query(
                collection(firestore, "users"),
                where("uid", "==", element.player.uid)
              );
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                userList[index].player.data = doc.data();
              });
            }
          }
          setRoundData({ ...docSnap.data(), userList: userList });

          if (docSnap.data().holesCourse) {
            setIsSelectedCourse(true);

            const docRef2 = doc(
              firestore,
              "courseHoles",
              docSnap.data().holesCourse
            );
            const docSnap2 = await getDoc(docRef2);

            if (docSnap2.exists()) {
              setCourseHoleData(docSnap2.data());
              const holeDetails = docSnap2.data().holeDetails.map((hole) => ({
                holeNumber: hole.holeNumber,
                par: hole.par,
                handicapIndex: hole.index,
              }));

              setHoleData(holeDetails);
            }
          } else {
            setIsSelectedCourse(false);
            setHoleData([
              { holeNumber: 1, par: 4, handicapIndex: 1 },
              { holeNumber: 2, par: 4, handicapIndex: 2 },
              { holeNumber: 3, par: 4, handicapIndex: 3 },
              { holeNumber: 4, par: 4, handicapIndex: 4 },
              { holeNumber: 5, par: 4, handicapIndex: 5 },
              { holeNumber: 6, par: 4, handicapIndex: 6 },
              { holeNumber: 7, par: 4, handicapIndex: 7 },
              { holeNumber: 8, par: 4, handicapIndex: 8 },
              { holeNumber: 9, par: 4, handicapIndex: 9 },
              { holeNumber: 10, par: 4, handicapIndex: 10 },
              { holeNumber: 11, par: 4, handicapIndex: 11 },
              { holeNumber: 12, par: 4, handicapIndex: 12 },
              { holeNumber: 13, par: 4, handicapIndex: 13 },
              { holeNumber: 14, par: 4, handicapIndex: 14 },
              { holeNumber: 15, par: 4, handicapIndex: 15 },
              { holeNumber: 16, par: 4, handicapIndex: 16 },
              { holeNumber: 17, par: 4, handicapIndex: 17 },
              { holeNumber: 18, par: 4, handicapIndex: 18 },
            ]);
          }
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
          Swal.fire({
            title: "Invalid Round",
            html: `
              <h5 class="fw-600">The round you selected is invalid.</h5>
              <h5 class="fw-600">Please check and try again.</h5>
            `,
            icon: "error",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(async (result) => {
            navigate("/round-activity");
          });
        }
      }
    }
    if (userData) {
      fetchData();
    }
  }, [userData]);

  useEffect(() => {
    async function fetchData() {
      const userList = roundData.userList;

      // "NqcyjewRyJfdfzqHxmlMRln3iB23"
      let scoreData = [];
      for (let index = 0; index < userList.length; index++) {
        const element = userList[index];

        const q = query(
          collection(firestore, "scoreCards"),
          where(
            "userId",
            "==",
            element.player?.uid == undefined ? "" : element.player.uid
          ),
          where(
            "caddieId",
            "==",
            element.caddie?.uid == undefined ? "" : element.caddie.uid
          ),
          where("roundId", "==", roundId)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          scoreData.push({ docId: doc.id, ...doc.data() });
        });
      }
      setScoreData(scoreData);
    }
    if (roundData) {
      fetchData();
    }
  }, [roundData]);

  useEffect(() => {
    const unsubscribers = [];

    async function fetchData() {
      const userList = roundData.userList;

      for (let index = 0; index < userList.length; index++) {
        const element = userList[index];

        const q = query(
          collection(firestore, "scoreCards"),
          where(
            "userId",
            "==",
            element.player?.uid === undefined ? "" : element.player.uid
          ),
          where(
            "caddieId",
            "==",
            element.caddie?.uid === undefined ? "" : element.caddie.uid
          ),
          where("roundId", "==", roundId)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            setScoreData((prevScoreData) => {
              return prevScoreData?.map((doc1) => {
                if (doc1.docId === doc.id) {
                  return { docId: doc.id, ...doc.data() }; // แทนที่ข้อมูลเก่าด้วยข้อมูลใหม่
                }
                return doc1; // ถ้าไม่ตรง docId ก็คืนค่าเดิม
              });
            });
          });
        });

        unsubscribers.push(unsubscribe);
      }
    }

    if (roundData) {
      fetchData();
    }

    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, [roundData]);

  // Calculate Score of game
  useEffect(() => {
    if (scoreData || holeData) {
      let array = [];
      holeData.map((hole) => {
        const holeScore = scoreData?.map((userScore) => {
          const holeNumberKey = `hole${hole.holeNumber}`;
          const scoreInfo = userScore[holeNumberKey] || {};
          return {
            userId: userScore.userId,
            caddieId: userScore.caddieId,
            roundId: userScore.roundId,
            docId: userScore.docId,
            ...scoreInfo,
          };
        });

        array.push({
          holeNumber: hole.holeNumber,
          par: hole.par,
          handicapIndex: hole.handicapIndex,
          holeScore: holeScore,
        });
      });

      const scoreSummary = [];

      if (
        roundData?.gameMode == "Stroke Play" &&
        roundData?.scoring == "Gross" &&
        roundData?.handicap == "scratch"
      ) {
        array.forEach((hole) => {
          hole?.holeScore?.forEach((score) => {
            const userId = score.userId;
            const caddieId = score.caddieId;
            let existingEntry = scoreSummary.find(
              (entry) =>
                entry.playerId === userId && entry.caddieId === caddieId
            );

            const user = scoreData.find(
              (item) => item.userId === "userId" || item.caddieId === caddieId
            );

            const currentScore =
              score.score === "-" ? 0 : parseInt(score.score, 10);
            const currentPutts =
              score.putts === "-" ? 0 : parseInt(score.putts);
            const currentPenalties =
              score.penalties === "-" ? 0 : parseInt(score.penalties);

            if (existingEntry) {
              existingEntry.totalScore += currentScore;
              existingEntry.totalPutts += currentPutts;
              existingEntry.totalPenalties += currentPenalties;
            } else {
              // ถ้ายังไม่มีข้อมูลของคู่นี้ ให้เพิ่มข้อมูลใหม่
              scoreSummary.push({
                playerId: userId,
                caddieId: caddieId,
                totalScore: currentScore,
                totalPutts: currentPutts,
                totalPenalties: currentPenalties,
                status: user?.status,
              });
            }
          });
        });
      } else if (
        roundData?.gameMode == "Stroke Play" &&
        roundData?.scoring == "To Par" &&
        roundData?.handicap == "scratch"
      ) {
        array.forEach((hole) => {
          hole?.holeScore?.forEach((score) => {
            const userId = score.userId;
            const caddieId = score.caddieId;

            const user = scoreData.find(
              (item) => item.userId === "userId" || item.caddieId === caddieId
            );

            let existingEntry = scoreSummary.find(
              (entry) =>
                entry.playerId === userId && entry.caddieId === caddieId
            );

            const hole = holeScoreData.find(
              (hole) =>
                hole.holeNumber === score?.holeNumber ||
                hole.holeNumber === parseInt(score?.holeNumber)
            );

            const currentScore =
              score.score === "-" ? 0 : parseInt(score.score, 10) - hole.par;
            const currentPutts =
              score.putts === "-" ? 0 : parseInt(score.putts);
            const currentPenalties =
              score.penalties === "-" ? 0 : parseInt(score.penalties);

            if (existingEntry) {
              existingEntry.totalScore += currentScore;
              existingEntry.totalPutts += currentPutts;
              existingEntry.totalPenalties += currentPenalties;
            } else {
              // ถ้ายังไม่มีข้อมูลของคู่นี้ ให้เพิ่มข้อมูลใหม่
              scoreSummary.push({
                playerId: userId,
                caddieId: caddieId,
                totalScore: currentScore,
                totalPutts: currentPutts,
                totalPenalties: currentPenalties,
                status: user?.status,
              });
            }
          });
        });
      }

      setTotalScoreData(scoreSummary);
      setHoleScoreData(array);
    }
  }, [scoreData, holeData]);

  // console.log(userData);
  // console.log("roundData : ", roundData);
  // console.log("scoreData : ", scoreData);
  // console.log("holeData : ", holeData);
  // console.log("holeScoreData : ", holeScoreData);
  // console.log("totalScoreData : ", totalScoreData);
  // console.log("courseHoleData : ", courseHoleData);

  const tableContainerStyle = {
    maxWidth: "100%",
    // margin: "20px auto",
    borderRadius: "5px",
    overflow: "hidden",
    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    display: "grid",
    gridTemplateColumns: `repeat(${userList?.length + 1}, 1fr)`,
    gap: "5px",
  };

  const headerStyle = {
    display: "contents",
    fontWeight: "bold",
    color: "#333",
  };

  const handleClickScoreBox = async (data) => {
    setShowScoreModal(true);
    setSelectedHole(data);
  };

  const handleClickHoleNumberBox = async (data) => {
    setSelectedHoleDetail(data);
  };

  const handleSelectCourse = async () => {
    setShowSelectCourseModal(true);
  };

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex justify-content-between align-items-center rounded-3">
                  <div className="d-flex align-items-center">
                    <Link to="/round-activity" className="d-inline-block mt-2">
                      <i className="ti-arrow-left font-sm text-white"></i>
                    </Link>
                    <div>
                      <h2 className="text-white fw-600 ms-4 mb-0 mt-0">
                        {roundData?.roundName}
                      </h2>
                      <h6 className="text-white fw-600 ms-4 mb-0 mt-0">
                        {roundData?.holes == "9 holes" && "9 Holes "}
                        {roundData?.holes == "18 holes" && "18 Holes "}(
                        {courseHoleData?.courseHoleName}), {roundData?.gameMode}{" "}
                        ({roundData?.scoring})
                      </h6>
                    </div>
                  </div>

                  <HeaderDropdown />
                </div>

                <div className="card-body p-lg-3 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      {isSelectedCourse === false ? (
                        <div
                          className="mb-3"
                          style={{
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: "#F5F5F5",
                            borderRadius: "6px",
                            padding: "10px",
                          }}
                        >
                          <h5 className="fw-600 mb-2">
                            Please select your desired course before starting to
                            play.
                          </h5>

                          <button
                            className="btn bg-current text-white mx-1 fs-5"
                            onClick={() => handleSelectCourse()}
                          >
                            Select course
                          </button>
                        </div>
                      ) : null}
                    </div>
                    {/* Select Course Button */}

                    {isSelectedCourse === true ? (
                      <div className="col-xl-12">
                        <div
                          style={{
                            ...tableContainerStyle,
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {/* Header Table */}
                          <div style={headerStyle}>
                            <div>
                              <Link to={`/leader-board?round=${roundId}`}>
                                <div
                                  style={{
                                    background: "#F5F5F5",
                                    height: "60%",
                                    borderRadius: "5px",
                                    marginBottom: "10px",
                                    textAlign: "center",
                                    display: "flex", // ใช้ Flexbox
                                    flexDirection: "column", // จัดให้อยู่ในแนว column
                                    alignItems: "center", // จัดให้อยู่ตรงกลางแนวนอน
                                    justifyContent: "center", // จัดให้อยู่ตรงกลางแนวตั้ง
                                    color: "black",
                                  }}
                                >
                                  <ReactSVG
                                    src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_leader_board.svg"
                                    style={{ width: "40px" }}
                                  />
                                  Leader Board
                                </div>
                              </Link>

                              <div
                                style={{
                                  background: "#F5F5F5",
                                  height: "30%",
                                  borderRadius: "5px",
                                  textAlign: "center",
                                }}
                              >
                                Total Par 72
                              </div>
                            </div>

                            {roundData?.userList.map((user) => {
                              return user.player?.uid == userData.uid ? (
                                <HeaderScoreBox
                                  totalScoreData={totalScoreData}
                                  user={user}
                                  scoreData={scoreData}
                                ></HeaderScoreBox>
                              ) : null;
                            })}

                            {roundData?.userList.map((user) => {
                              return user.player?.uid !== userData.uid ? (
                                <HeaderScoreBox
                                  totalScoreData={totalScoreData}
                                  user={user}
                                  scoreData={scoreData}
                                ></HeaderScoreBox>
                              ) : null;
                            })}
                          </div>
                          {/* Header Table */}

                          {holeScoreData.map((row, index) => (
                            <div
                              style={{
                                display: "contents",
                              }}
                            >
                              <HoleNumberBox
                                row={row}
                                showHoleDetailModal={showHoleDetailModal}
                                setShowHoleDetailModal={setShowHoleDetailModal}
                                handleClickHoleNumberBox={
                                  handleClickHoleNumberBox
                                }
                              />

                              {row.holeScore?.map((user) =>
                                user?.userId == userData.uid ? (
                                  <ScoreBox
                                    user={user}
                                    handleClickScoreBox={handleClickScoreBox}
                                    roundData={roundData}
                                    holeScoreData={holeScoreData}
                                    totalScoreData={totalScoreData}
                                  ></ScoreBox>
                                ) : (
                                  ""
                                )
                              )}

                              {row.holeScore?.map((user) =>
                                user.userId !== userData.uid ? (
                                  <ScoreBox
                                    user={user}
                                    handleClickScoreBox={handleClickScoreBox}
                                    roundData={roundData}
                                    holeScoreData={holeScoreData}
                                    totalScoreData={totalScoreData}
                                  ></ScoreBox>
                                ) : (
                                  ""
                                )
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddScoreModal
        showScoreModal={showScoreModal}
        setShowScoreModal={setShowScoreModal}
        selectedHole={selectedHole}
        courseHoleData={courseHoleData}
        holeScoreData={holeScoreData}
      />

      <SelectCourseModal
        showSelectCourseModal={showSelectCourseModal}
        setShowSelectCourseModal={setShowSelectCourseModal}
      />

      <HoleDetailModal
        showHoleDetailModal={showHoleDetailModal}
        setShowHoleDetailModal={setShowHoleDetailModal}
        selectedHoleDetail={selectedHoleDetail}
        courseHoleData={courseHoleData}
        holeScoreData={holeScoreData}
      />

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}

export default Score;
