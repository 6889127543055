import { Card } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";
import clsx from "clsx";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import { deleteRoundForCreator } from "../module/deleteRound";
import { updateStartRound } from "../module/startRound";
import Swal from "sweetalert2";

function RoundUpcoming() {
  const [listRound, setListRound] = useState([]);
  const [listRoundStart, setListRoundStart] = useState([]);

  const userData = useSelector((state) => state.user.data);
  const maxUserList = { desktop: 7, mobile: 5 };

  useEffect(() => {
    async function fetchData() {
      const roundQuery = query(collection(firestore, "round"));
      const roundSnapshot = await getDocs(roundQuery);

      let roundDataArray = [];
      roundSnapshot.forEach((doc) => {
        const roundData = doc.data();

        const isCreator = roundData.creator === userData.uid;
        const isUserInList = roundData.userList.some(
          (user) =>
            (user.player?.uid === userData.uid &&
              user.player?.isJoin === true) ||
            (user.caddie?.uid === userData.uid && user.caddie?.isJoin === true)
        );

        if (isCreator || isUserInList) {
          if (roundData.teeTime === "now") {
            roundDataArray.push({ ...roundData, roundId: doc.id });
          }
        }
      });
      setListRoundStart(roundDataArray);
    }
    if (userData) {
      fetchData();
    }
  }, [userData]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(firestore, "round")),
      async (roundSnapshot) => {
        try {
          let dataArray = [];
          let roundDataArray = [];

          roundSnapshot.forEach((doc) => {
            const roundData = doc.data();

            const isCreator = roundData.creator === userData?.uid;
            const isUserInList = roundData.userList.some(
              (user) =>
                (user.player?.uid === userData.uid &&
                  user.player?.isJoin === true) ||
                (user.caddie?.uid === userData.uid &&
                  user.caddie?.isJoin === true)
            );

            if (isCreator || isUserInList) {
              if (
                roundData.teeTime === "schedule" &&
                (roundData.status == undefined ||
                  roundData.status !== "deleted")
              ) {
                roundDataArray.push({ ...roundData, roundId: doc.id });
              }
            }
          });

          for (const round of roundDataArray) {
            let holesCourse = "";

            const courseHolesCollection = collection(firestore, "courseHoles");
            const q = doc(courseHolesCollection, round.holesCourse);
            const courseHolesSnapshot = await getDoc(q);
            if (courseHolesSnapshot.exists) {
              holesCourse = courseHolesSnapshot.data().courseHoleName;
            }

            const courseQuery = query(
              collection(firestore, "courses"),
              where("id", "==", round.course)
            );
            const { time, day, month } = epochToDateTime(round.scheduleTime);

            const fetchUserDetails = async (uid) => {
              const q = query(
                collection(firestore, "users"),
                where("uid", "==", uid)
              );
              const userSnapshot = await getDocs(q);
              let userDetails = {};
              userSnapshot.forEach((userDoc) => {
                userDetails = userDoc.data();
              });
              return userDetails;
            };

            let userList = round.userList;
            let userDataArray = [];

            for (let userObj of userList) {
              const { caddie, player } = userObj;

              if (player?.uid) {
                const playerData = await fetchUserDetails(player.uid);
                const combinedPlayerData = {
                  ...playerData,
                  isCreator: player.isCreator,
                  isJoin: player.isJoin,
                };
                userDataArray.push(combinedPlayerData);
              }

              if (caddie?.uid) {
                const caddieData = await fetchUserDetails(caddie.uid);
                const combinedCaddieData = {
                  ...caddieData,
                  isCreator: caddie.isCreator,
                  isJoin: caddie.isJoin,
                };
                userDataArray.push(combinedCaddieData);
              }
            }

            const courseSnapshot = await getDocs(courseQuery);
            courseSnapshot.forEach((doc2) => {
              const courseData = doc2.data();
              dataArray.push({
                ...round,
                courseInfo: courseData,
                time: time,
                day: day,
                month: month,
                userData: userDataArray,
                holesCourse: holesCourse,
              });
            });
          }

          setListRound(dataArray);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      }
    );

    return () => unsubscribe(); // Clean up the subscription on unmount
  }, [userData]);

  const handleDeleteRound = async (roundId) => {
    try {
      await deleteRoundForCreator(roundId);
    } catch (error) {
      console.error("Error deleted round:", error);
    }
  };

  const handleUpdateStartRound = async (roundId) => {
    try {
      if (listRoundStart.length > 0) {
        Swal.fire({
          title: "Round In Progress",
          text: "You still have a round in progress. Please finish that round first.",
          icon: "warning",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });
      } else {
        await updateStartRound(roundId);
      }
    } catch (error) {
      console.error("Error denying round:", error);
    }
  };

  const isSameDay = (timestamp) => {
    const scheduledDate = new Date(timestamp * 1000); // Convert to milliseconds
    const currentDate = new Date();

    return (
      scheduledDate.getFullYear() === currentDate.getFullYear() &&
      scheduledDate.getMonth() === currentDate.getMonth() &&
      scheduledDate.getDate() === currentDate.getDate()
    );
  };

  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl">
        <Card.Body className="main-player-stat">
          <div className="d-flex align-items-center mb-2">
            <select
              className="form-select"
              aria-label="Default select example"
              style={{
                height: "40px",
              }}
            >
              <option selected>Confirmed Round</option>
              <option value="friendRequest">Friend Request</option>
              <option value="friendActivities">Friend Activities</option>
              <option value="allRounds">All Rounds</option>
            </select>

            {/* Icon for filtering */}
            <div
              style={{
                cursor: "pointer",
                height: "40px",
                width: "40px",
              }}
              className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3 d-flex align-items-center justify-content-center"
            >
              <i className="feather-filter font-xss text-grey-500"></i>
            </div>
          </div>

          {listRound.length > 0 ? (
            listRound
              .filter((round) =>
                round.userData.some(
                  (user) => user.uid === userData.uid && user.isJoin === true
                )
              ) // Check if userData.uid matches and isJoin is false
              .map((round, index) => (
                <div key={index} className="create-round-req-main">
                  <section className="create-round-req-head">
                    <div className="pointer">
                      <h4 className="fw-600">{round.roundName}</h4>
                      <br />
                      <h6 className="fw-600 text-muted">
                        {`${round.holes} (${round.holesCourse} ), ${round.gameMode}(${round.scoring})`}
                      </h6>
                    </div>
                    <div className="d-flex flex-wrap-reverse justify-content-end align-items-end">
                      <ReactSVG
                        src="assets/images/dooNineIconV2/chat-SL.svg"
                        wrapper="svg"
                        style={{ height: 40, width: 40 }}
                        className={clsx("pointer", {
                          "d-none": round.groupChat !== "create",
                        })}
                      />
                      <Dropdown className="drodownFriend" drop="down">
                        <Dropdown.Toggle
                          as="span" // Use a span element for the wrapper
                          id="dropdown-basic"
                          style={{ cursor: "pointer", zIndex: 1000 }}
                        >
                          <ReactSVG
                            src="assets/images/dooNineIconV3/create-round/createRound-menu.svg"
                            wrapper="svg"
                            className="wizrd-more-btn"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomDropdown}>
                          <Dropdown.Item
                            onClick={() =>
                              handleUpdateStartRound(round.roundId)
                            }
                          >
                            Start Round
                          </Dropdown.Item>
                          <Dropdown.Item href="#/leader-board">
                            Leader Board
                          </Dropdown.Item>
                          <Dropdown.Item href="#/game-mode">
                            Game mode
                          </Dropdown.Item>
                          <Dropdown.Item href="#/competitor">
                            Competitor
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item href="#/player-caddie">
                            Player & Caddie
                          </Dropdown.Item>
                          <Dropdown.Item href="#/course">Course</Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            disabled={
                              !round.userData.some(
                                (user) =>
                                  user.uid === userData.uid &&
                                  user.isCreator === true
                              )
                            }
                            href={`/create-round?edit=${round.roundId}`}
                          >
                            Edit Round
                          </Dropdown.Item>

                          {/* Invitation Link */}
                          <Dropdown.Item
                            disabled={
                              !round.userData.some(
                                (user) =>
                                  user.uid === userData.uid &&
                                  user.isCreator === true
                              )
                            }
                          >
                            Invitation Link
                          </Dropdown.Item>

                          {round.userData.some(
                            (user) =>
                              user.uid === userData.uid &&
                              user.isCreator === true
                          ) ? (
                            <Dropdown.Item
                              onClick={() => handleDeleteRound(round.roundId)}
                            >
                              Delete Round
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item href="#/leave-round">
                              Leave Round
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </section>
                  <section
                    className="create-round-req-body p-2 mb-2 pointer"
                    //   onClick={() => handleChatandDateClick()}
                    // style={{
                    //   backgroundImage: round.coverImage
                    //     ? typeof round.coverImage === "string"
                    //       ? `url(${round.coverImage})` // ถ้าเป็น URL ให้ใช้โดยตรง
                    //       : `url(${URL.createObjectURL(round.coverImage)})` // ถ้าเป็นไฟล์ ให้ใช้ createObjectURL
                    //     : "none",
                    //   height: round.coverImage ? "" : "inherit",
                    // }}
                    style={{
                      backgroundImage: round.coverImage,
                      height: round.coverImage ? "" : "inherit",
                    }}
                  >
                    <div
                      className="req-course-data row"
                      style={{
                        backgroundColor: round.coverImage ? "" : "#fff",
                      }}
                    >
                      <div
                        className="col-sm-2 text-center d-sm-flex align-items-center pointer"
                        //   onClick={(e) => {
                        //     handleCourseClick();
                        //     e.stopPropagation();
                        //   }}
                      >
                        <img
                          src={round.courseInfo.profileImage}
                          alt="course-profile"
                          width={70}
                          height={70}
                          className="rounded-circle my-2"
                        />
                      </div>
                      <div
                        className="col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start pointer"
                        //   onClick={(e) => {
                        //     handleCourseClick();
                        //     e.stopPropagation();
                        //   }}
                      >
                        <div>
                          <span className="fw-600 fs-4">
                            {round.courseInfo.courseName}
                          </span>{" "}
                          <br />
                          <span className="fw-600 fs-6">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</span>
                        </div>
                      </div>
                      <div className="col-sm-5 d-flex justify-content-center justify-content-sm-end my-2 my-sm-0">
                        <div
                          className="h-100 w-50 d-flex flex-column justify-content-evenly rounded-3 py-2 pointer"
                          style={{ backgroundColor: "#E59E49" }}
                          // onClick={() => handleChatandDateClick()}
                        >
                          {round.teeTime === "schedule" ? (
                            <>
                              <h4 className="fw-600 text-white text-center">
                                {round.month}
                              </h4>
                              <h1 className="fw-700 text-white text-center">
                                {round.day}
                              </h1>
                              <div className="bg-white text-center">
                                <span
                                  className="fw-600 fs-4"
                                  style={{ color: "#E59E49" }}
                                >
                                  {round.time}
                                </span>
                              </div>
                            </>
                          ) : (
                            <div className="bg-white text-center px-2">
                              <span
                                className="fw-600 fs-4"
                                style={{ color: "#E59E49" }}
                              >
                                Start Now
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section>
                    {/* desktop display 7 limit */}
                    <div className="d-none d-sm-flex align-items-center justify-content-between p-3 pointer">
                      {/* Left side: User profile images */}
                      <div className="d-flex align-items-center">
                        {round.userData
                          .slice(0, maxUserList.desktop)
                          .map((user, idx) =>
                            user.isCreator ? (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={50}
                                    height={50}
                                    className="rounded-circle player-color-outline me-4"
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={50}
                                    height={50}
                                    className="rounded-circle caddie-color-outline me-4"
                                  />
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={50}
                                    height={50}
                                    className="rounded-circle player-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.desktop - idx,
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={50}
                                    height={50}
                                    className="rounded-circle caddie-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.desktop - idx,
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                        {round.userData.length > maxUserList.desktop ? (
                          <div
                            className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                            style={{
                              width: 50,
                              height: 50,
                              outline: "1px dashed grey",
                              opacity: 0.6,
                            }}
                          >
                            <h6 className="text-center">
                              +{round.userData.length - maxUserList.desktop}{" "}
                              more
                            </h6>
                          </div>
                        ) : null}
                      </div>

                      {/* Right side: Button */}
                      <div>
                        {isSameDay(round.scheduleTime) && (
                          <button
                            className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                            onClick={() =>
                              handleUpdateStartRound(round.roundId)
                            }
                          >
                            Start
                          </button>
                        )}
                      </div>
                    </div>

                    {/* mobile display 5 limit */}
                    <div className="d-sm-none d-flex align-items-center justify-content-between p-3 pointer">
                      {/* Left side: User profile images */}
                      <div className="d-flex align-items-center">
                        {round.userData
                          .slice(0, maxUserList.mobile)
                          .map((user, idx) =>
                            user.isCreator ? (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={40}
                                    height={40}
                                    className="rounded-circle player-color-outline me-4"
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={40}
                                    height={40}
                                    className="rounded-circle caddie-color-outline me-4"
                                  />
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={40}
                                    height={40}
                                    className="rounded-circle player-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.mobile - idx,
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={40}
                                    height={40}
                                    className="rounded-circle caddie-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.mobile - idx,
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                        {round.userData.length > maxUserList.mobile ? (
                          <div
                            className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                            style={{
                              width: 40,
                              height: 40,
                              outline: "1px dashed grey",
                            }}
                          >
                            <h6 className="text-center">
                              +{round.userData.length - maxUserList.mobile} more
                            </h6>
                          </div>
                        ) : null}
                      </div>

                      {/* Right side: Button */}
                      <div>
                        {isSameDay(round.scheduleTime) && (
                          <button
                            className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                            onClick={() =>
                              handleUpdateStartRound(round.roundId)
                            }
                          >
                            Start
                          </button>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ))
          ) : (
            <p></p>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
export default RoundUpcoming;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
