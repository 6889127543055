import { firestore } from "../../../../../components/firebase/FirebaseConfig";
import { where, query, getDocs, updateDoc, collection, serverTimestamp, doc } from "firebase/firestore";
import Swal from "sweetalert2";

export const confirmJoin = async (roundData, confirmCaddie, userData, yourCaddie, setLoading, navigate, userList) => {
    // console.log(userList);
    // console.log(copiedRounData);
    // console.log(roundData);

    try {
        setLoading(true);

        const foundPlayer = roundData.userList.find(item => item.player && item.player.uid === userData.uid);
        if (foundPlayer) {
            // invite player
            if (foundPlayer.caddie) {
                // already have caddie
                if (yourCaddie && yourCaddie.uid === foundPlayer.caddie.uid) {
                    // same caddie
                    foundPlayer.caddie = {
                        ...foundPlayer.caddie,
                        playerConfirm: confirmCaddie
                    }
                } else if (yourCaddie && yourCaddie.uid !== foundPlayer.caddie.uid) {
                    // diff caddie
                    foundPlayer.caddie = {
                        playerConfirm: confirmCaddie,
                        isCreator: false,
                        isJoin: false,
                        uid: yourCaddie.uid
                    }
                } else {
                    // not select or no caddie
                    foundPlayer.caddie = null
                }
            } else {
                // not have caddie
                if (yourCaddie) {
                    // select caddie
                    foundPlayer.caddie = {
                        playerConfirm: confirmCaddie,
                        isCreator: false,
                        isJoin: false,
                        uid: yourCaddie.uid
                    }
                } else {
                    // not select
                    foundPlayer.caddie = null
                }
            }
            foundPlayer.player.isJoin = true
        } else {
            // no invite player
            // console.log(userList);
            const foundNewPlayer = userList.find(item => item.player && item.player.uid === userData.uid);

            if (foundNewPlayer) {
                // found new player (you)
                const foundNewPlayerRow = roundData.userList.find(item => item.row === foundNewPlayer.row);
                if (foundNewPlayerRow) {
                    // found your selected row
                    if (yourCaddie) {
                        // found your caddie
                        foundNewPlayerRow.player = {
                            isCreator: false,
                            isJoin: true,
                            uid: foundNewPlayer.player.uid
                        }

                        foundNewPlayerRow.caddie = {
                            isCreator: yourCaddie.isCreator || false,
                            isJoin: yourCaddie.isJoin || false,
                            uid: yourCaddie.uid,
                            playerConfirm: confirmCaddie || false
                        }

                    } else {
                        // not found your caddie
                        foundNewPlayerRow.player = {
                            isCreator: false,
                            isJoin: true,
                            uid: foundNewPlayer.player.uid
                        }

                        foundNewPlayerRow.caddie = null
                    }
                }
            } else {
                // not found new player (you)
                setLoading(false);
                return;
            }

        }

        // console.log(roundData);

        const docRef = doc(firestore, 'round', roundData.roundId);
        await updateDoc(docRef, {
            userList: roundData.userList,
            lastUpdate: serverTimestamp()
        });

        const userUids = roundData.userList.reduce((acc, item) => {
            if (item.player && item.player.uid) {
                acc.push(item.player.uid);
            }
            if (item.caddie && item.caddie.uid) {
                acc.push(item.caddie.uid);
            }
            return acc;
        }, []);

        const roundActivityCollection = collection(firestore, 'roundActivity');
        const q = query(roundActivityCollection, where('roundId', '==', roundData.roundId));
        const roundActivitySnapshot = await getDocs(q);
        if (!roundActivitySnapshot.empty) {
            const activityRef = roundActivitySnapshot.docs[0].ref;
            updateDoc(activityRef, {
                userList: userUids,
                lastUpdate: serverTimestamp()
            })
        }

        setLoading(false);

        Swal.fire({
            title: 'Joined',
            icon: 'success'
        }).then(() => {
            navigate('/round-activity')
        })

    } catch (error) {
        setLoading(false);
        console.error('Fail to join', error);
    }
}