import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import {
  getDoc,
  query,
  where,
  getDocs,
  collection,
  doc,
  addDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";

function HeaderScoreBox({ totalScoreData, user, scoreData }) {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.data);

  const [playStatus, setPlayStatus] = useState("");

  const [roundId, setRoundId] = useState(null);
  const [isReview, setIsReview] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams(window.location.search);
      const roundId = params.get("round");
      setRoundId(roundId);
    }
    if (userData) {
      fetchData();
    }
  }, [userData]);

  const cellStyle = {
    padding: "5px 5px 5px 5px",
    textAlign: "center",
    borderBottom: "1px solid #ddd",
    fontSize: "14px",
  };

  const colorStyles = {
    grey: { backgroundColor: "#A6A6A6", borderRadius: "6px" },
    white: { backgroundColor: "#F5F5F5", borderRadius: "6px" },
  };

  const userTotalScore = totalScoreData.find(
    (score) =>
      score.playerId === user.player?.uid || score.caddieId === user.caddie?.uid
  )?.totalScore;

  const userTotalPutts = totalScoreData.find(
    (score) =>
      score.playerId === user.player?.uid || score.caddieId === user.caddie?.uid
  )?.totalPutts;

  const userTotalPenalties = totalScoreData.find(
    (score) =>
      score.playerId === user.player?.uid || score.caddieId === user.caddie?.uid
  )?.totalPenalties;

  // Check is Score Full Fill
  useEffect(() => {
    if (totalScoreData) {
      let userStatus = totalScoreData.find(
        (score) =>
          score.playerId === user.player?.uid ||
          score.caddieId === user.caddie?.uid
      )?.status;
      setPlayStatus(userStatus);
    }
  }, [totalScoreData]);

  // Check is Score Full Fill
  useEffect(() => {
    async function fetchData() {
      if (scoreData && totalScoreData) {
        const userData = scoreData.find(
          (item) =>
            item.userId === user.player?.uid ||
            item.caddieId === user.caddie?.uid
        );

        let hasValidScore = false;
        for (let i = 1; i <= 9; i++) {
          const holeKey = `hole${i}`;
          if (userData[holeKey].score == "-") {
            hasValidScore = true;
            break;
          }
        }

        if (hasValidScore) {
          // console.log("มี - ใน Score - เกมยังไม่จบ");
        } else {
          setPlayStatus("playing-can-finish");
        }

        if (userData.status == "finished") {
          setPlayStatus("finished");

          const docRef = doc(firestore, "round", roundId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const roundData = docSnap.data();
            const isReview =
              roundData.userList.find(
                (item) => item.player?.uid === userData.userId
              )?.isReview || false;

            if (!isReview) {
              setPlayStatus("finished-not-review");
            }
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
        }
      }
    }

    fetchData();
  }, [scoreData, totalScoreData]);

  const handleFinishRound = () => {
    navigate(`/finish-round?round=${roundId}`);
  };

  return (
    <>
      <div
        style={{
          ...cellStyle,
          ...(user.player?.uid === userData.uid
            ? colorStyles.white
            : colorStyles.grey),
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <img
            src={
              user.player !== null && user.player.data.profileImage
                ? user.player.data.profileImage
                : ""
            }
            alt="Player"
            style={{
              borderRadius: "50%",
              marginRight: "10px",
              width: "40px",
              height: "40px",
            }}
            className="player-color-outline"
          />
          <img
            src={
              user.caddie !== null && user.caddie.data.profileImage
                ? user.caddie.data.profileImage
                : ""
            }
            alt="Caddie"
            style={{
              borderRadius: "50%",
              width: "40px",
              height: "40px",
            }}
            className="caddie-color-outline"
          />
        </div>
        <div
          style={{
            backgroundColor:
              playStatus == "playing-can-finish" ? "#EE3D7F" : "#fff",
            color: playStatus == "playing-can-finish" ? "#fff" : "#000",
            padding: "5px 10px",
            borderRadius: "5px",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
          onClick={() => {
            if (playStatus === "playing-can-finish") {
              handleFinishRound();
            } else if (playStatus === "finished-not-review") {
              navigate(`/review-round?round=${roundId}`);
            }
          }}
        >
          {playStatus == "playing" ? (
            "Playing"
          ) : playStatus == "playing-can-finish" ? (
            "Finish Round"
          ) : playStatus == "finished" ? (
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <ReactSVG
                src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_lock.svg"
                style={{ width: "25px", marginRight: "5px" }}
              />
              Finished
            </span>
          ) : playStatus == "finished-not-review" ? (
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <ReactSVG
                src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_lock.svg"
                style={{ width: "25px", marginRight: "5px" }}
              />
              Finished & Review
            </span>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span style={{ color: "red" }}>{userTotalPenalties}</span>
          <span>
            <h2 className="fw-600">{userTotalScore}</h2>
          </span>
          <span>{userTotalPutts}</span>
        </div>
      </div>
    </>
  );
}

export default HeaderScoreBox;
