import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";

import Header from "../../../components/Header";
import Leftnav from "../../../components/Leftnav";
import Rightchat from "../../../components/Rightchat";
import Popupchat from "../../../components/Popupchat";
import Appfooter from "../../../components/Appfooter";

import {
  firestore,
  storage,
} from "../../../components/firebase/FirebaseConfig";
import {
  getDocs,
  doc,
  collection,
  addDoc,
  updateDoc,
  where,
  query,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { ReactSVG } from "react-svg";

import { Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import imageCompression from "browser-image-compression";
import { Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const CaddieReq = () => {
  const userData = useSelector((state) => state.user.data);

  const [courseList, setCourseList] = useState([]);
  const [eviList, setEviList] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(true);
  const [hiddenAddBtn, setHiddenAddBtn] = useState(false);

  const caddieNumRef = useRef(null);
  const selectedCourseRef = useRef(null);
  const caddiePhoneRef = useRef(null);
  const noteRef = useRef(null);

  const [caddieStatus, setCaddieStatus] = useState(null);

  const maxEvi = 3;

  useEffect(() => {
    window.scrollTo(0, 0);

    let unsubscribeFromCaddieStatus = () => {};

    async function loadData() {
      try {
        unsubscribeFromCaddieStatus = await getCaddieStatus();
        await getCourse();
      } catch (error) {
        console.error("Error loading data:", error);
      }
    }

    if (userData) {
      loadData();
    }
    return () => {
      if (unsubscribeFromCaddieStatus) {
        unsubscribeFromCaddieStatus();
      }
    };
  }, [userData]);

  async function getCourse() {
    try {
      const docRef = collection(firestore, "courses");
      const docSnap = await getDocs(docRef);
      const course = docSnap.docs
        .map((doc) => doc.data())
        .sort((a, b) => a.courseName.localeCompare(b.courseName));

      setCourseList(course);
      return course;
    } catch (error) {
      console.error("Fail to get course data", error);
    }
  }

  async function getCaddieStatus() {
    try {
      setStatusLoading(true);

      const q = query(
        collection(firestore, "caddieRequests"),
        where("caddieId", "==", userData.uid),
        where("status", "!=", "removed")
      );

      // Use onSnapshot to listen for real-time updates
      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          if (!querySnapshot.empty) {
            setCaddieStatus({});

            if (querySnapshot.size > 1) {
              console.warn("found document more than one");
            }
            const docData = querySnapshot.docs[0].data();
            setCaddieStatus(docData);
          } else {
            setCaddieStatus({});
          }
          setStatusLoading(false);
        },
        (error) => {
          setStatusLoading(false);
          setCaddieStatus({});
          console.error("Fail to get course data", error);
        }
      );
      return unsubscribe;
    } catch (error) {
      setStatusLoading(false);
      setCaddieStatus({});
      console.error("Fail to get course data", error);
    }
  }

  useEffect(() => {
    if (courseList.length === 0 || !userData || !caddieStatus) return;
    setEviList([]);
    const { caddieNumber, courseId, caddiePhone, note, evidence } =
      caddieStatus;

    caddieNumRef.current.value = caddieNumber || "";
    caddiePhoneRef.current.value = caddiePhone || "";
    noteRef.current.value = note || "";

    const foundCourse = courseList.find((course) => course.id === courseId);
    if (foundCourse) {
      selectedCourseRef.current.value = foundCourse.id;
    }

    if (evidence) {
      setEviList((prevEviList) => [
        ...prevEviList,
        ...evidence.map((image) => ({ preview: image })),
      ]);
    }
    if (
      Object.keys(caddieStatus).length !== 0 &&
      caddieStatus.status !== "removed"
    ) {
      caddieNumRef.current.disabled = true;
      caddiePhoneRef.current.disabled = true;
      noteRef.current.disabled = true;
      selectedCourseRef.current.disabled = true;
      setHiddenAddBtn(true);
    }
  }, [courseList, userData, caddieStatus]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const existingImagesCount = eviList.length;
    const remainingSlots = maxEvi - existingImagesCount;

    if (remainingSlots > 0) {
      const newFiles = files.slice(0, remainingSlots);
      const previewImages = [];

      newFiles.forEach((image) => {
        previewImages.push({
          preview: URL.createObjectURL(image),
          file: image,
        });
      });
      setEviList((prev) => [...prev, ...previewImages]);
    }
    e.target.value = null;
  };

  const handleShowReq = (e) => {
    e.preventDefault();
    setShowConfirmModal(true);
  };

  const handleConfirm = async () => {
    try {
      const q_check_caddieRequest = query(
        collection(firestore, "caddieRequests"),
        where("courseId", "==", selectedCourseRef?.current?.value),
        where("caddieNumber", "==", caddieNumRef.current.value)
      );
      const querySnapshot_check_caddieRequest = await getDocs(
        q_check_caddieRequest
      );

      const q_check_caddieAssignment = query(
        collection(firestore, "caddieAssignments"),
        where("courseId", "==", selectedCourseRef?.current?.value),
        where("caddieNumber", "==", caddieNumRef.current.value)
      );
      const querySnapshot_check_caddieAssignment = await getDocs(
        q_check_caddieAssignment
      );

      if (
        querySnapshot_check_caddieRequest.size > 0 ||
        querySnapshot_check_caddieAssignment.size > 0
      ) {
        Swal.fire({
          title: "Error!",
          text: "This caddie number is already in use.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#EE3C7F",
        });
        setLoading(false);
      } else {
        setLoading(true);
        const timestampInSecond = Math.round(Date.now() / 1000);
        const q = query(
          collection(firestore, "caddieRequests"),
          where("courseId", "==", selectedCourseRef?.current?.value),
          where("caddieId", "==", userData.uid)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size === 0) {
          const caddieReqRef = await addDoc(
            collection(firestore, "caddieRequests"),
            {
              caddieNumber: caddieNumRef.current.value || "",
              caddiePhone: caddiePhoneRef.current.value || "",
              note: noteRef.current.value || "",
              caddieId: userData.uid,
              courseId: selectedCourseRef.current.value || "",
              status: "pending",
              timestamp: timestampInSecond,
              requestTimestamp: timestampInSecond,
            }
          );
          if (eviList.length) {
            const uploadedEvidence = await handleUploadEvidence();
            await updateDoc(caddieReqRef, {
              evidence: uploadedEvidence.filter((item) => item !== null),
            });
          } else {
            await updateDoc(caddieReqRef, {
              evidence: [],
            });
          }
        } else {
          querySnapshot.forEach(async (doc_) => {
            const caddieReqRef = doc(firestore, "caddieRequests", doc_.id);
            await updateDoc(caddieReqRef, {
              caddieNumber: caddieNumRef.current.value || "",
              caddiePhone: caddiePhoneRef.current.value || "",
              note: noteRef.current.value || "",
              status: "pending",
              timestamp: timestampInSecond,
              requestTimestamp: timestampInSecond,
            });
            if (eviList.length) {
              const uploadedEvidence = await handleUploadEvidence();
              await updateDoc(caddieReqRef, {
                evidence: uploadedEvidence.filter((item) => item !== null),
              });
            } else {
              await updateDoc(caddieReqRef, {
                evidence: [],
              });
            }
          });
        }
        setLoading(false);
        setShowConfirmModal(false);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      setLoading(false);
      console.error("Fail to send request", error);
    }
  };

  const handleRemoveCourse = async () => {
    try {
      Swal.fire({
        title: "Are you sure?",
        html: `<h4>You won't be able to revert this!</h4>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EE3C7F",
        cancelButtonColor: "#F7F7F7",
        confirmButtonText: "<span style='color: white;'>OK</span>", // Change text color to white
        cancelButtonText: "<span style='color: #000;'>Cancel</span>",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const timestampInSecond = Math.round(Date.now() / 1000);

          const q = query(
            collection(firestore, "caddieRequests"),
            where("courseId", "==", selectedCourseRef?.current?.value),
            where("caddieId", "==", userData.uid)
          );

          const querySnapshot = await getDocs(q);

          if (querySnapshot.size !== 0) {
            querySnapshot.forEach(async (doc_) => {
              const caddieReqRef = doc(firestore, "caddieRequests", doc_.id);
              await updateDoc(caddieReqRef, {
                caddieNumber: caddieNumRef.current.value || "",
                caddiePhone: caddiePhoneRef.current.value || "",
                note: noteRef.current.value || "",
                status: "removed",
                timestamp: timestampInSecond,
                requestTimestamp: timestampInSecond,
              });
            });
          }

          const q2 = query(
            collection(firestore, "caddieAssignments"),
            where("courseId", "==", selectedCourseRef?.current?.value),
            where("caddieId", "==", userData.uid)
          );
          const querySnapshot2 = await getDocs(q2);
          if (querySnapshot2.size !== 0) {
            querySnapshot2.forEach(async (doc_) => {
              await deleteDoc(doc(firestore, "caddieAssignments", doc_.id));
            });
          }

          //Delete doc in caddieProfiles
          const q3 = query(
            collection(firestore, "caddieProfiles"),
            where("caddieId", "==", userData.uid)
          );
          const querySnapshot3 = await getDocs(q3);
          if (querySnapshot3.size !== 0) {
            querySnapshot3.forEach(async (doc3) => {
              await deleteDoc(doc(firestore, "caddieProfiles", doc3.id));
            });
          }

          caddieNumRef.current.disabled = false;
          caddiePhoneRef.current.disabled = false;
          noteRef.current.disabled = false;
          selectedCourseRef.current.disabled = false;
          selectedCourseRef.current.value = "";
          setHiddenAddBtn(false);
          setLoading(false);
          window.scrollTo(0, 0);
        }
      });
    } catch (error) {
      setLoading(false);
      console.error("Fail to send request", error);
    }
  };

  async function handleUploadEvidence() {
    const evidence = [];
    for (const [index, image] of eviList.entries()) {
      if (image.file) {
        try {
          const uploadResult = await uploadImage(image.file, index);
          evidence.push(uploadResult);
        } catch (error) {
          console.error(`Failed to upload ${image.file.name}:`, error);
          evidence.push(null);
        }
      } else {
        console.warn("No file found for image:", image);
        if (image.preview) {
          evidence.push(image.preview);
        } else {
          evidence.push(null);
        }
      }
    }
    return evidence;
  }

  async function uploadImage(file, name) {
    try {
      if (!file) return;

      const compressedFile = await imageCompression(file, {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      });

      const storageRef = ref(
        storage,
        `caddie_evidence/${userData.uid}/${name}`
      );
      await uploadBytes(storageRef, compressedFile);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  }

  const renderStatus = useMemo(() => {
    if (statusLoading) {
      return (
        <strong className="text-muted fs-4 px-4 py-2 rounded-pill bg-white theme-light-bg">
          Caddie Status – Loading
          <Spinner
            className="ms-1"
            variant="grey"
            animation="border"
            role="status"
            size="sm"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </strong>
      );
    }

    if (!caddieStatus) return;

    const officialColor = "#81D77A";
    const unoficialColor = "#4A74E5";
    const waitingColor = "#E59E49";

    const statusData = caddieStatus;

    if (statusData.status === "approved") {
      if (statusData.approvedRole === "official") {
        return (
          <strong
            className="fs-4 px-4 py-2 rounded-pill text-white"
            style={{ backgroundColor: officialColor }}
          >
            Caddie Status – Official
          </strong>
        );
      } else {
        return (
          <strong
            className="fs-4 px-4 py-2 rounded-pill text-white"
            style={{ backgroundColor: unoficialColor }}
          >
            Caddie Status – Confirm
          </strong>
        );
      }
    } else if (statusData.status === "pending") {
      return (
        <strong
          className="fs-4 px-4 py-2 rounded-pill text-white"
          style={{ backgroundColor: waitingColor }}
        >
          Caddie Status – Waiting
        </strong>
      );
    } else if (statusData.status === "rejected") {
      return (
        <>
          <strong className="text-muted fs-4 px-4 py-2 rounded-pill bg-white theme-light-bg">
            Caddie Status – No Status
          </strong>
          <br />
          <div className="mt-3" style={{ backgroundColor: waitingColor }}>
            <strong className="text-white">
              Your request has been denied. Please contact your course manager
              or doonine.com.
            </strong>
          </div>
        </>
      );
    } else {
      return (
        <strong className="text-muted fs-4 px-4 py-2 rounded-pill bg-white theme-light-bg">
          Caddie Status – No Status
        </strong>
      );
    }
  }, [caddieStatus, statusLoading]);

  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <h1 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">
                        My Course
                      </h1>
                      <section className="py-4 text-center rounded-3 bg-light theme-dark-bg mb-4">
                        {renderStatus}
                      </section>
                      <section>
                        <form
                          className="row g-3 needs-validation"
                          onSubmit={handleShowReq}
                        >
                          <label htmlFor="caddie-number">
                            <h4 className="fw-600 mb-0">Your Caddie Number</h4>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="caddie-number"
                            ref={caddieNumRef}
                          />

                          <label htmlFor="course-select">
                            <h4 className="fw-600 mt-3 mb-0">
                              Select your course
                            </h4>
                          </label>
                          <select
                            name="course-select"
                            id="course-select"
                            className="form-select form-select-lg"
                            ref={selectedCourseRef}
                            required
                          >
                            <option value="">No select</option>
                            {courseList.map((course, index) => (
                              <option key={index} value={course.id}>
                                {course.courseName}
                              </option>
                            ))}
                          </select>

                          <label htmlFor="caddie-phone">
                            <h4 className="fw-600 mt-3 mb-0">Phone Number</h4>
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            id="caddie-phone"
                            ref={caddiePhoneRef}
                          />

                          <label htmlFor="note">
                            <h4 className="fw-600 mt-3 mb-0">Note to admin</h4>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="note"
                            ref={noteRef}
                          />

                          <div htmlFor="evidence">
                            <h4 className="fw-600 mt-3">{`Document evidence (${eviList.length}/${maxEvi})`}</h4>
                          </div>
                          <div className="d-flex align-items-center flex-wrap gap-2 mt-3">
                            {eviList.map((image, index) => (
                              <div
                                key={index}
                                className="position-relative overflow-visible"
                                style={{
                                  minWidth: 100,
                                  minHeight: 100,
                                  maxWidth: 150,
                                  maxHeight: 300,
                                }}
                              >
                                <img
                                  key={index}
                                  src={image.preview}
                                  alt={index}
                                  className="w-100 h-100"
                                  style={{ objectFit: "contain" }}
                                />
                                <i
                                  className="bi bi-x-circle position-absolute text-black rounded-circle d-flex justify-content-center align-items-center pointer"
                                  style={{
                                    top: 0,
                                    right: 0,
                                    width: 30,
                                    height: 30,
                                    fontSize: 30,
                                    backgroundColor: "rgba(255,255,255,0.5)",
                                  }}
                                  onClick={() =>
                                    setEviList((prev) =>
                                      prev.filter((_, i) => i !== index)
                                    )
                                  }
                                ></i>
                              </div>
                            ))}
                            {eviList.length < 3 && !hiddenAddBtn && (
                              <ReactSVG
                                src="assets/images/dooNineIconV2/others/create_Post_Add_Photo.svg"
                                onClick={() =>
                                  document.getElementById("evidence").click()
                                }
                                className="pointer rounded-3"
                                style={{
                                  width: 100,
                                  height: 100,
                                  padding: 5,
                                  border: "2px dashed grey",
                                }}
                              />
                            )}
                          </div>
                          <input
                            type="file"
                            accept="image/*"
                            id="evidence"
                            className="d-none"
                            multiple
                            onChange={handleImageChange}
                          />
                          <h5 className="text-sm-end mt-1 text-decoration-underline">
                            *** พบปัญหาในการใช้งานกรุณาติดต่อ doonine.com ***
                          </h5>

                          <div className="text-end mt-4">
                            {hiddenAddBtn ? (
                              <button
                                type="button"
                                className="btn bg-current text-white fs-5 fw-bold px-5 me-2"
                                onClick={() => handleRemoveCourse()}
                              >
                                Remove course
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn bg-current text-white fs-5 fw-bold px-5 me-2"
                              >
                                Request
                              </button>
                            )}
                            <Link to={"/userPage"}>
                              <button className="btn bg-light theme-dark-bg fs-5 fw-bold">
                                Cancel
                              </button>
                            </Link>
                          </div>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popupchat />
      <Appfooter />

      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        centered
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>
            <h3 className="fw-bold">Please Confirm your request</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <h4>
              <strong>Your Caddie Number</strong>
            </h4>
            <h5 className="ms-2 m-0">- {caddieNumRef?.current?.value}</h5>
          </div>

          <div className="mb-4">
            <h4>
              <strong>Course</strong>
            </h4>
            <h5 className="ms-2 m-0">
              - {selectedCourseRef?.current?.selectedOptions[0]?.textContent}
            </h5>
          </div>

          <div className="mb-4">
            <h4>
              <strong>Phone Number</strong>
            </h4>
            <h5 className="ms-2 m-0">- {caddiePhoneRef?.current?.value}</h5>
          </div>

          <div className="mb-4">
            <h4>
              <strong>Note to admin</strong>
            </h4>
            <h5 className="ms-2 m-0">- {noteRef?.current?.value}</h5>
          </div>

          <div className="mb-4">
            <h4>
              <strong>Document evidence</strong>
            </h4>
            <div
              className="d-flex align-items-center gap-2 mt-3 right-scroll-bar"
              style={{ maxHeight: 100, overflow: "auto" }}
            >
              {eviList.length ? (
                <>
                  {eviList.map((image, index) => (
                    <div
                      key={index}
                      className="overflow-visible"
                      style={{
                        minWidth: 60,
                        minHeight: 60,
                        maxWidth: 100,
                        maxHeight: 200,
                      }}
                    >
                      <img
                        key={index}
                        src={image.preview}
                        alt={index}
                        className="w-100 h-100"
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <h5 className="ms-2 m-0">-</h5>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn bg-current text-white fs-5 fw-bold me-2 w150"
            onClick={() => handleConfirm()}
          >
            {!loading ? (
              "Confirm"
            ) : (
              <Spinner
                variant="light"
                animation="border"
                role="status"
                size="sm"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </button>
          <button
            className="btn bg-light theme-dark-bg fs-5 fw-bold"
            onClick={() => setShowConfirmModal(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CaddieReq;
