import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";

function ScoreBox({
  user,
  handleClickScoreBox,
  roundData,
  holeScoreData,
  totalScoreData,
}) {
  const userData = useSelector((state) => state.user.data);
  const [isClicked, setIsClicked] = useState(false);

  const cellStyle = {
    padding: "5px 5px 5px 5px",
    textAlign: "center",
    borderBottom: "1px solid #ddd",
    fontSize: "14px",
  };

  const colorStyles = {
    grey: { backgroundColor: "#A6A6A6", borderRadius: "6px" },
    white: { backgroundColor: "#F5F5F5", borderRadius: "6px" },
  };

  let showData = {};

  if (
    roundData?.gameMode == "Stroke Play" &&
    roundData?.scoring == "Gross" &&
    roundData?.handicap == "scratch"
  ) {
    showData = {
      score: user.score,
      putts: user.putts,
      fairwayHit: user.fairwayHit,
      bunkerHit: user.bunkerHit,
      penalties: user.penalties,
      drivingRange: user.drivingRange,
    };
  } else if (
    roundData?.gameMode == "Stroke Play" &&
    roundData?.scoring == "To Par" &&
    roundData?.handicap == "scratch"
  ) {
    const hole = holeScoreData.find(
      (hole) =>
        hole.holeNumber === user?.holeNumber ||
        hole.holeNumber === parseInt(user?.holeNumber)
    );

    const score = user.score === "-" ? "-" : user.score - hole.par;
    showData = {
      score: score,
      putts: user.putts,
      fairwayHit: user.fairwayHit,
      bunkerHit: user.bunkerHit,
      penalties: user.penalties,
      drivingRange: user.drivingRange,
    };
  }

  // Check is Score Full Fill
  useEffect(() => {
    if (totalScoreData) {
      const userData = totalScoreData.find(
        (item) =>
          item.playerId === user.userId || item.caddieId === user.caddieId
      );

      setIsClicked(userData.status === "finished" ? true : false);
    }
  }, [totalScoreData]);

  return (
    <>
      <div
        style={{
          ...cellStyle,
          ...(user?.userId === userData.uid
            ? { ...colorStyles.white, cursor: isClicked ? null : "pointer" }
            : colorStyles.grey),
        }}
        onClick={
          user?.userId === userData.uid
            ? isClicked
              ? null
              : () => handleClickScoreBox(user)
            : null
        }
      >
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#E0F7FA",
            borderRadius: "6px",
            display: "inline-block",
            position: "relative",
            padding: "2px 2px 2px 2px",
            width: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "5px",
              left: "15px",
              fontSize: "18px",
              fontWeight: "bold",
              color: "#AAA",
            }}
          >
            <h5 className="fw-600">...</h5>
          </div>
          <div
            style={{
              position: "absolute",
              top: "5px",
              right: "15px",
              fontSize: "18px",
              fontWeight: "bold",
              color: "#AAA",
            }}
          >
            <h5 className="fw-600">{showData.putts}</h5>
          </div>

          <h1
            style={{
              margin: "15px 0 0px 0",
              fontSize: "25px",
              fontWeight: "bold",
              color: "#333",
            }}
          >
            <h2 className="fw-600">{showData.score}</h2>
          </h1>

          <div
            style={{
              marginBottom: "0px",
              fontSize: "14px",
              color: "#888",
              textAlign: "right",
              paddingRight: "15px",
            }}
          >
            <h5 className="fw-600">{showData.drivingRange}</h5>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              fontSize: "15px",
              gap: "5px",
            }}
          >
            {/* Green icon for bunker hit */}

            <ReactSVG
              src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_g.svg"
              style={{
                width: "27px",
                height: "27px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                visibility:
                  showData.bunkerHit && showData.bunkerHit !== "-"
                    ? "visible"
                    : "hidden",
              }}
            />

            {/* Fairway hit indicator */}

            {showData.fairwayHit === "center" ? (
              <ReactSVG
                src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_up.svg"
                style={{
                  width: "27px",
                  height: "27px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  visibility:
                    showData.fairwayHit && showData.fairwayHit !== "-"
                      ? "visible"
                      : "hidden",
                }}
              />
            ) : showData.fairwayHit === "right" ? (
              <ReactSVG
                src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_right.svg"
                style={{
                  width: "27px",
                  height: "27px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  visibility:
                    showData.fairwayHit && showData.fairwayHit !== "-"
                      ? "visible"
                      : "hidden",
                }}
              />
            ) : showData.fairwayHit === "left" ? (
              <ReactSVG
                src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_left.svg"
                style={{
                  width: "27px",
                  height: "27px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  visibility:
                    showData.fairwayHit && showData.fairwayHit !== "-"
                      ? "visible"
                      : "hidden",
                }}
              />
            ) : (
              ""
            )}

            {/* Red icon for bunker */}

            <ReactSVG
              src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_circle_b.svg"
              style={{
                width: "27px",
                height: "27px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                visibility:
                  showData.bunkerHit && showData.bunkerHit !== "-"
                    ? "visible"
                    : "hidden",
              }}
            />

            {/* Red icon for penalties */}
            <div>
              <h3
                className="fw-600"
                style={{
                  visibility: showData.penalties ? "visible" : "hidden",
                  color: '#C00000'
                }}
              >
                {showData.penalties || "0"}
              </h3>
            </div>

            {/* Trophy icon */}
            <ReactSVG
              src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_cup.svg"
              style={{
                width: "27px",
                height: "27px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                visibility: "visible",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ScoreBox;
