import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { collection, query, where, getDocs, or } from "firebase/firestore";
import { firestore } from './firebase/FirebaseConfig';
import axios from 'axios';

const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';
const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN

const Rightchat = () => {

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

    const [isOpen, setIsOpen] = useState(false);
    const [dimensions, setDimensions] = useState({ width: 800, height: 182 });
    const [friendList, setFriendList] = useState([]);
    const navigate = useNavigate()

    const userData = useSelector((state) => state.user.data);

    const updateDimensions = () => {
        if (window.innerWidth < 500) {
            setDimensions({ width: 450, height: 102 });
        } else {
            const update_width = window.innerWidth - 100;
            const update_height = Math.round(update_width / 4.4);
            setDimensions({ width: update_width, height: update_height });
        }
    };

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const toggleOpen = () => setIsOpen(!isOpen);


    const menuClass = `${isOpen ? " d-block" : ""}`;

    useEffect(() => {
        async function fetchFriends() {
            let listId = [];
            const q = query(
                collection(firestore, "friends"),
                or(
                    where("friendId", "==", userData?.uid),
                    where("userId", "==", userData?.uid)
                )
            );

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {

                if (doc.data().status === 'active') {
                    listId.push(doc.data().friendId)
                    listId.push(doc.data().userId)
                }

            });
            listId = [...new Set(listId)];
            listId = listId.filter(item => item !== userData?.uid);
            let friendListArray = []
            for (let i = 0; i < listId.length; i++) {
                const q2 = query(collection(firestore, "users"), where("uid", "==", listId[i]));
                const querySnapshot2 = await getDocs(q2);
                querySnapshot2.forEach((doc2) => {
                    friendListArray.push({
                        imageUrl: doc2.data().profileImage || defaultProfile,
                        name: doc2.data().firstName + ' ' + doc2.data().lastName,
                        uid: doc2.data().uid,
                        dooNineId: doc2.data().dooNineId
                    });
                });
            }

            setFriendList(friendListArray);
        }
        if (userData) {
            fetchFriends()
        }
    }, [userData])

    const handleFriendClick = async (dooNineId) => {
        try {
            const response = await axios.get(`https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels?members_exactly_in=${userData.dooNineId}&show_member=false&members_exactly_in=${dooNineId}`, {
                headers: {
                    'Content-Type': 'application/json; charset=utf8',
                    'Api-Token': sendBird_api_token
                }
            });

            if (response.data.channels.length > 0) {
                isMobile ? navigate('/chat-room', { state: { channelUrl: response.data.channels[0].channel_url, previousPage: 'myfriend' } }) :
                    navigate('/chat', { state: { channelUrl: response.data.channels[0].channel_url, previousPage: 'myfriend' } })

            } else {
                const response2 = await axios.post('https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels', {
                    user_ids: [userData.dooNineId, dooNineId],
                }, {
                    headers: {
                        'Content-Type': 'application/json; charset=utf8',
                        'Api-Token': sendBird_api_token
                    }
                });

                const newChannelUrl = response2.data.channel_url;
                isMobile ? navigate('/chat-room', { state: { channelUrl: newChannelUrl, previousPage: 'myfriend' } }) :
                    navigate('/chat', { state: { channelUrl: newChannelUrl, previousPage: 'myfriend' } });
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <style>
                {`.friend-list-ul :hover {
  background-color: rgba(199, 199, 199, 0.185);
}
`}
            </style>

            <div id="main-content-wrap" className={`right-chat nav-wrap mt-2 right-scroll-bar ${dimensions.width > 1500 ? "active-sidebar" : " "}`}>
                <div className="middle-sidebar-right-content bg-white shadow-xss rounded-xxl right-scroll-bar" style={{

                    maxHeight: `85vh`,
                }}>

                    <div className="section full position-relative feed-body">
                        <h4 className="p-3 m-0 font-xsssss text-grey-500 text-uppercase fw-700 ls-3">CONTACTS</h4>
                        <hr className='text-muted m-0' />
                        {friendList.length > 0 ? (<>
                            <ul className="list-group list-group-flush mb-3 friend-list-ul">
                                {friendList.map((friend, index) => (
                                    <li key={index} className="list-group-item no-icon pt-2 pb-2 border-0 d-flex align-items-center pointer friend-item"
                                        onClick={() => handleFriendClick(friend.dooNineId)}
                                    >
                                        <figure className="avatar float-left mb-0 me-2 bg-transparent">
                                            <img src={friend.imageUrl} alt="avater" className="rounded-circle" style={{ width: '45px', height: '45px', objectFit: 'cover' }}
                                                onError={(e) => e.target.src = defaultProfile}
                                            />
                                        </figure>
                                        <h3 className="fw-700 mb-0 mt-0 bg-transparent">
                                            <span className="bg-transparent font-xssss text-grey-600 d-block text-dark model-popup-chat pointer">{friend.name}</span>
                                        </h3>
                                        <span className={`${friend.status} ms-auto btn-round-xss`}></span>
                                    </li>

                                ))}
                            </ul>
                        </>) : (<>
                            <div className='text-muted text-center fs-6 my-3'>You have no friend</div>
                        </>)}
                    </div>
                </div>

                {/* <div className={`modal-popup-chat ${menuClass}`}>
                <div className="modal-popup-wrap bg-white p-0 shadow-lg rounded-3">
                    <div className="modal-popup-header w-100 border-bottom">
                        <div className="card p-3 d-block border-0 d-block">
                            <figure className="avatar mb-0 float-left me-2">
                                <img src="assets/images/user-12.png" alt="avater" className="w35 me-1" />
                            </figure>
                            <h5 className="fw-700 text-primary font-xssss mt-1 mb-1">Hendrix Stamp</h5>
                            <h4 className="text-grey-500 font-xsssss mt-0 mb-0"><span className="d-inline-block bg-success btn-round-xss m-0"></span> Available</h4>
                            <div className="font-xssss position-absolute right-0 top-0 mt-3 me-4 pointer" onClick={toggleOpen}><i className="ti-close text-grey-900 mt-2 d-inline-block"></i></div>
                        </div>
                    </div>
                    <div className="modal-popup-body w-100 p-3 h-auto">
                        <div className="message"><div className="message-content font-xssss lh-24 fw-500">Hi, how can I help you?</div></div>
                        <div className="date-break font-xsssss lh-24 fw-500 text-grey-500 mt-2 mb-2">Mon 10:20am</div>
                        <div className="message self text-right mt-2"><div className="message-content font-xssss lh-24 fw-500">I want those files for you. I want you to send 1 PDF and 1 image file.</div></div>
                        <div className="snippet pt-3 ps-4 pb-2 pe-3 mt-2 bg-grey rounded-xl float-right" data-title=".dot-typing"><div className="stage"><div className="dot-typing"></div></div></div>
                        <div className="clearfix"></div>
                    </div>
                    <div className="modal-popup-footer w-100 border-top">
                        <div className="card p-3 d-block border-0 d-block">
                            <div className="form-group icon-right-input style1-input mb-0"><input type="text" placeholder="Start typing.." className="form-control rounded-xl bg-greylight border-0 font-xssss fw-500 ps-3" /><i className="feather-send text-grey-500 font-md"></i></div>
                        </div>
                    </div>
                </div>
            </div> */}
            </div>
        </>

    );
}

export default Rightchat;