import { Card } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  onSnapshot,
  doc,
  updateDoc
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";

import clsx from "clsx";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function RoundStart() {
  const [listRound, setListRound] = useState([]);
  const userData = useSelector((state) => state.user.data);
  const maxUserList = { desktop: 7, mobile: 5 };
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(firestore, "round")),
      async (roundSnapshot) => {
        try {
          let dataArray = [];
          let roundDataArray = [];

          roundSnapshot.forEach((doc) => {
            const roundData = doc.data();

            // Check if the round creator is the current user or if the user is in userList and isJoin is true
            const isCreator = roundData.creator === userData.uid;
            const isUserInList = roundData.userList.some(
              (user) =>
                (user.player?.uid === userData.uid &&
                  user.player?.isJoin === true) ||
                (user.caddie?.uid === userData.uid &&
                  user.caddie?.isJoin === true)
            );

            if (isCreator || isUserInList) {
              if (roundData.teeTime === "now") {
                roundDataArray.push({ ...roundData, roundId: doc.id });
              }
            }
          });

          for (const round of roundDataArray) {
            let holesCourse = "";

            const courseHolesCollection = collection(firestore, "courseHoles");
            const q = doc(courseHolesCollection, round.holesCourse);
            const courseHolesSnapshot = await getDoc(q);
            if (courseHolesSnapshot.exists) {
              holesCourse = courseHolesSnapshot.data().courseHoleName;
            }

            const courseQuery = query(
              collection(firestore, "courses"),
              where("id", "==", round.course)
            );
            const { time, day, month } = epochToDateTime(round.scheduleTime);

            const fetchUserDetails = async (uid) => {
              const q = query(
                collection(firestore, "users"),
                where("uid", "==", uid)
              );
              const userSnapshot = await getDocs(q);
              let userDetails = {};
              userSnapshot.forEach((userDoc) => {
                userDetails = userDoc.data();
              });
              return userDetails;
            };

            let userList = round.userList;
            let userDataArray = [];

            for (let userObj of userList) {
              const { caddie, player } = userObj;

              if (player?.uid) {
                const playerData = await fetchUserDetails(player.uid);
                const combinedPlayerData = {
                  ...playerData,
                  isCreator: player.isCreator,
                  isJoin: player.isJoin,
                };
                userDataArray.push(combinedPlayerData);
              }

              if (caddie?.uid) {
                const caddieData = await fetchUserDetails(caddie.uid);
                const combinedCaddieData = {
                  ...caddieData,
                  isCreator: caddie.isCreator,
                  isJoin: caddie.isJoin,
                };
                userDataArray.push(combinedCaddieData);
              }
            }

            const courseSnapshot = await getDocs(courseQuery);
            courseSnapshot.forEach((doc2) => {
              const courseData = doc2.data();
              dataArray.push({
                ...round,
                courseInfo: courseData,
                time: time,
                day: day,
                month: month,
                userData: userDataArray,
                holesCourse: holesCourse,
              });
            });
          }

          setListRound(dataArray);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      }
    );

    return () => unsubscribe(); // Clean up the subscription on unmount
  }, [userData]);

  console.log(listRound);

  const handleEndRound = async (roundId) => {
    Swal.fire({
      title: "End this round?",
      html: `
        <h5 class="fw-600">Are you sure you want to end this round?</h5>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const ref = doc(firestore, "round", roundId);
        await updateDoc(ref, {
          teeTime: "finish",
        });

        Swal.fire({
          title: "Round End!",
          html: `
            <h5 class="fw-600">Your round has been end.</h5>
          `,
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#ee3d7f",
        }).then(async (result) => {
          navigate("/round-activity");
        });
      }
    });
  };

  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl">
        <Card.Body className="main-player-stat">
          {listRound.map((round, index) => (
            <div key={index} className="create-round-req-main">
              <section className="create-round-req-head">
                <div className="pointer">
                  <h4 className="fw-600">{round.roundName}</h4>
                  <br />
                  <h6 className="fw-600 text-muted">
                    {`${round.holes} (${round.holesCourse}), ${round.gameMode}(${round.scoring})`}
                  </h6>
                </div>
                <div className="d-flex flex-wrap-reverse justify-content-end align-items-end">
                  <ReactSVG
                    src="assets/images/dooNineIconV2/chat-SL.svg"
                    wrapper="svg"
                    style={{ height: 40, width: 40 }}
                    className={clsx("pointer", {
                      "d-none": round.groupChat !== "create",
                    })}
                  />
                  <Dropdown className="drodownFriend" drop="down">
                    <Dropdown.Toggle
                      as="span" // Use a span element for the wrapper
                      id="dropdown-basic"
                      style={{ cursor: "pointer", zIndex: 1000 }}
                    >
                      <ReactSVG
                        src="assets/images/dooNineIconV3/create-round/createRound-menu.svg"
                        wrapper="svg"
                        className="wizrd-more-btn"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomDropdown}>
                      <Dropdown.Item href="#/resume-round">
                        Resume Round
                      </Dropdown.Item>
                      <Dropdown.Item href="#/leader-board">
                        Leader Board
                      </Dropdown.Item>
                      <Dropdown.Item href="#/game-mode">
                        Game mode
                      </Dropdown.Item>
                      <Dropdown.Item href="#/competitor">
                        Competitor
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#/player-caddie">
                        Player & Caddie
                      </Dropdown.Item>
                      <Dropdown.Item href="#/course">Course</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item disabled>Edit Round</Dropdown.Item>
                      <Dropdown.Item disabled>Invitation Link</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleEndRound(round.roundId)}
                      >
                        End Round
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </section>
              <section
                className="create-round-req-body p-2 mb-2 pointer"
                //   onClick={() => handleChatandDateClick()}
                style={{
                  backgroundImage: round.coverImage
                    ? typeof round.coverImage === "string"
                      ? `url(${round.coverImage})` // ถ้าเป็น URL ให้ใช้โดยตรง
                      : `url(${URL.createObjectURL(round.coverImage)})` // ถ้าเป็นไฟล์ ให้ใช้ createObjectURL
                    : "none",
                  height: round.coverImage ? "" : "inherit",
                }}
              >
                <div
                  className="req-course-data row"
                  style={{ backgroundColor: round.coverImage ? "" : "#fff" }}
                >
                  <div
                    className="col-sm-2 text-center d-sm-flex align-items-center pointer"
                    //   onClick={(e) => {
                    //     handleCourseClick();
                    //     e.stopPropagation();
                    //   }}
                  >
                    <img
                      src={round.courseInfo.profileImage}
                      alt="course-profile"
                      width={70}
                      height={70}
                      className="rounded-circle my-2"
                    />
                  </div>
                  <div
                    className="col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start pointer"
                    //   onClick={(e) => {
                    //     handleCourseClick();
                    //     e.stopPropagation();
                    //   }}
                  >
                    <div>
                      <span className="fw-600 fs-4">
                        {round.courseInfo.courseName}
                      </span>{" "}
                      <br />
                      <span className="fw-600 fs-6">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</span>
                    </div>
                  </div>
                  <div className="col-sm-5 d-flex justify-content-center justify-content-sm-end my-2 my-sm-0">
                    <div
                      className="h-100 w-50 d-flex flex-column justify-content-evenly rounded-3 py-2 pointer"
                      style={{ backgroundColor: "#81D77A" }}
                      // onClick={() => handleChatandDateClick()}
                    >
                      {round.teeTime === "now" ? (
                        <>
                          <h4 className="fw-600 text-white text-center">
                            {`OCT`}
                            {/* {round.month} */}
                          </h4>
                          <h1 className="fw-700 text-white text-center">
                            {`03`}
                            {/* {round.day} */}
                          </h1>
                          <div className="bg-white text-center">
                            <span
                              className="fw-600 fs-4"
                              style={{ color: "#81D77A" }}
                            >
                              {`11:30`}
                              {/* {round.time} */}
                            </span>
                          </div>
                        </>
                      ) : (
                        <div className="bg-white text-center px-2">
                          <span
                            className="fw-600 fs-4"
                            style={{ color: "#81D77A" }}
                          >
                            Start Now
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>

              <section>
                {/* desktop display 7 limit */}
                <div className="d-none d-sm-flex align-items-center justify-content-between p-3 pointer">
                  {/* Left side: User profile images */}
                  <div className="d-flex align-items-center">
                    {round.userData
                      .slice(0, maxUserList.desktop)
                      .map((user, idx) =>
                        user.isCreator ? (
                          <React.Fragment key={idx}>
                            {user.role === "player" ? (
                              <img
                                src={user.profileImage}
                                alt="player"
                                width={50}
                                height={50}
                                className="rounded-circle player-color-outline me-4"
                              />
                            ) : (
                              <img
                                src={user.profileImage}
                                alt="caddie"
                                width={50}
                                height={50}
                                className="rounded-circle caddie-color-outline me-4"
                              />
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment key={idx}>
                            {user.role === "player" ? (
                              <img
                                src={user.profileImage}
                                alt="player"
                                width={50}
                                height={50}
                                className="rounded-circle player-color-outline"
                                style={{
                                  marginLeft: "-10px",
                                  zIndex: maxUserList.desktop - idx,
                                }}
                              />
                            ) : (
                              <img
                                src={user.profileImage}
                                alt="caddie"
                                width={50}
                                height={50}
                                className="rounded-circle caddie-color-outline"
                                style={{
                                  marginLeft: "-10px",
                                  zIndex: maxUserList.desktop - idx,
                                }}
                              />
                            )}
                          </React.Fragment>
                        )
                      )}
                    {round.userData.length > maxUserList.desktop ? (
                      <div
                        className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                        style={{
                          width: 50,
                          height: 50,
                          outline: "1px dashed grey",
                          opacity: 0.6,
                        }}
                      >
                        <h6 className="text-center">
                          +{round.userData.length - maxUserList.desktop} more
                        </h6>
                      </div>
                    ) : null}
                  </div>

                  {/* Right side: Button */}
                  <div>
                    <Link to={`/score?round=${round.roundId}`}>
                      <div className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                        Resume
                      </div>
                    </Link>
                  </div>
                </div>

                {/* mobile display 5 limit */}
                <div className="d-sm-none d-flex align-items-center justify-content-between p-3 pointer">
                  {/* Left side: User profile images */}
                  <div className="d-flex align-items-center">
                    {round.userData
                      .slice(0, maxUserList.mobile)
                      .map((user, idx) =>
                        user.isCreator ? (
                          <React.Fragment key={idx}>
                            {user.role === "player" ? (
                              <img
                                src={user.profileImage}
                                alt="player"
                                width={40}
                                height={40}
                                className="rounded-circle player-color-outline me-4"
                              />
                            ) : (
                              <img
                                src={user.profileImage}
                                alt="caddie"
                                width={40}
                                height={40}
                                className="rounded-circle caddie-color-outline me-4"
                              />
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment key={idx}>
                            {user.role === "player" ? (
                              <img
                                src={user.profileImage}
                                alt="player"
                                width={40}
                                height={40}
                                className="rounded-circle player-color-outline"
                                style={{
                                  marginLeft: "-10px",
                                  zIndex: maxUserList.mobile - idx,
                                }}
                              />
                            ) : (
                              <img
                                src={user.profileImage}
                                alt="caddie"
                                width={40}
                                height={40}
                                className="rounded-circle caddie-color-outline"
                                style={{
                                  marginLeft: "-10px",
                                  zIndex: maxUserList.mobile - idx,
                                }}
                              />
                            )}
                          </React.Fragment>
                        )
                      )}
                    {round.userData.length > maxUserList.mobile ? (
                      <div
                        className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                        style={{
                          width: 40,
                          height: 40,
                          outline: "1px dashed grey",
                        }}
                      >
                        <h6 className="text-center">
                          +{round.userData.length - maxUserList.mobile} more
                        </h6>
                      </div>
                    ) : null}
                  </div>

                  {/* Right side: Button */}
                  <div>
                    <Link to={`/score?round=${round.roundId}`}>
                      <div className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                        Resume
                      </div>
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          ))}

          <div className="d-flex align-items-center">
            <i className="feather-calendar text-current fs-1 me-2"></i>
            <span className="fs-4">Create a round schedule</span>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
export default RoundStart;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
