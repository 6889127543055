import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useSelector } from "react-redux";
import {
  getDoc,
  query,
  where,
  getDocs,
  collection,
  doc,
  addDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { ReactSVG } from "react-svg";

function ReviewRound() {
  const navigate = useNavigate();

  const [roundId, setRoundId] = useState(null);
  const [caddieData, setCaddieData] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [roundData, setRoundData] = useState(null);

  const [isReview, setIsReview] = useState(false);

  const userData = useSelector((state) => state.user.data);

  const [serviceRating, setServiceRating] = useState(0);
  const [adviceRating, setAdviceRating] = useState(0);
  const [courseRating, setCourseRating] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams(window.location.search);
      const roundId = params.get("round");
      setRoundId(roundId);

      if (roundId == null) {
        Swal.fire({
          title: "Invalid Round",
          html: `
            <h5 class="fw-600">The round you selected is invalid.</h5>
            <h5 class="fw-600">Please check and try again.</h5>
          `,
          icon: "error",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        }).then(async (result) => {
          navigate("/round-activity");
        });
      } else {
        if (userData.role == "player") {
          const q = query(
            collection(firestore, "scoreCards"),
            where("roundId", "==", roundId),
            where("userId", "==", userData.uid)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc2) => {
            console.log(doc2.data());

            const q2 = query(
              collection(firestore, "users"),
              where("uid", "==", doc2.data().caddieId)
            );
            const querySnapshot2 = await getDocs(q2);
            querySnapshot2.forEach(async (doc3) => {
              setCaddieData(doc3.data());
            });

            const docRef = doc(firestore, "round", doc2.data().roundId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              setRoundData(docSnap.data());

              const docRef2 = doc(firestore, "courses", docSnap.data().course);
              const docSnap2 = await getDoc(docRef2);

              if (docSnap2.exists()) {
                setCourseData(docSnap2.data());
              } else {
                console.log("No such document!");
              }
            } else {
              console.log("No such document!");
            }
          });
        }
      }
    }
    if (userData) {
      fetchData();
    }
  }, [userData]);

  useEffect(() => {
    async function fetchData() {
      const docRef = doc(firestore, "round", roundId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const user = data.userList.find(
          (user) => user.player.uid === userData.uid
        );

        if (user) {
          console.log("Player found:", user);
          console.log("isReview:", user.isReview ? "true" : "false");

          if (user.isReview) {
            setIsReview(user.isReview);

            const q = query(
              collection(firestore, "reviewCaddies"),
              where("roundId", "==", roundId),
              where("playerId", "==", userData.uid)
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              setServiceRating(doc.data().serviceRating);
              setAdviceRating(doc.data().adviceRating);
            });

            const q2 = query(
              collection(firestore, "reviewCourses"),
              where("roundId", "==", roundId),
              where("playerId", "==", userData.uid)
            );
            const querySnapshot2 = await getDocs(q2);
            querySnapshot2.forEach((doc) => {
              setCourseRating(doc.data().courseRating);
            });
          }
        } else {
          console.log("No player with this UID found in userList.");
        }
      } else {
        console.log("No such document!");
      }
    }
    if (roundId && userData) {
      fetchData();
    }
  }, [roundId, userData]);

  //   console.log("------------------------------------");

  //   console.log("roundId : ", roundId);
  //   console.log("userData : ", userData);
  //   console.log("caddieData : ", caddieData);
  //   console.log("courseData : ", courseData);
  //   console.log("roundData : ", roundData);
  //   console.log("serviceRating : ", serviceRating);
  //   console.log("adviceRating : ", adviceRating);
  //   console.log("courseRating : ", courseRating);

  const handleSubmit = async () => {
    // Create Document in reviewCaddies Collection
    const docRef4 = await addDoc(collection(firestore, "reviewCaddies"), {
      caddieId: caddieData.uid,
      roundId: roundId,
      playerId: userData.uid,
      serviceRating: serviceRating,
      adviceRating: adviceRating,
      courseId: courseData.id,
    });
    console.log("Document written with ID: ", docRef4.id);

    // Create Document in reviewCourses Collection
    const docRef2 = await addDoc(collection(firestore, "reviewCourses"), {
      caddieId: caddieData.uid,
      roundId: roundId,
      playerId: userData.uid,
      courseRating: courseRating,
      courseId: courseData.id,
    });
    console.log("Document written with ID: ", docRef2.id);

    // Change isReview to true in scoreCards Document
    const docRef = doc(firestore, "round", roundId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const roundData = docSnap.data();
      const userList = roundData.userList;

      const updatedUserList = userList.map((user) => {
        if (user.player.uid === userData.uid) {
          return {
            ...user,
            player: {
              ...user.player,
            },
            isReview: true,
          };
        }
        return user;
      });

      await updateDoc(docRef, {
        userList: updatedUserList,
      });
    } else {
      console.log("No such document!");
    }

    const q = query(
      collection(firestore, "scoreCards"),
      where("roundId", "==", roundId),
      where("userId", "==", userData.uid)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc1) => {
      const docRef3 = doc(firestore, "scoreCards", doc1.id);
      await updateDoc(docRef3, {
        isReview: true,
      });
    });

    Swal.fire({
      title: "Review Success",
      html: `
        <h5 class="fw-600">Your review has been submitted.</h5>
      `,
      icon: "success",
      confirmButtonColor: "#ee3d7f",
      confirmButtonText: "OK",
    }).then(async (result) => {
      navigate("/round-activity");
    });
  };

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex justify-content-between align-items-center rounded-3">
                  <div className="d-flex align-items-center">
                    <Link
                      to={`/score?round=${roundId}`}
                      className="d-inline-block mt-2"
                    >
                      <i className="ti-arrow-left font-sm text-white"></i>
                    </Link>
                    <div>
                      <h2 className="text-white fw-600 ms-4 mb-0 mt-0">
                        Review
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="card-body w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <h5 className="mb-0">
                        What do you think about{" "}
                        <strong>
                          {caddieData?.firstName} {caddieData?.lastName}
                        </strong>{" "}
                        services?
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="card-body w-100 border-0">
                  <div
                    className="card-body"
                    style={{ backgroundColor: "#F5F5F5", borderRadius: "10px" }}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src={caddieData?.profileImage}
                        alt="profile"
                        className="rounded-circle caddie-color-outline me-4"
                        style={{
                          width: "70px",
                          height: "70px",
                          marginRight: "30px",
                        }}
                      />

                      <div className="row">
                        <h4 className="fw-600 mb-3">
                          {caddieData?.firstName} {caddieData?.lastName}
                        </h4>

                        <div>
                          <h5>Service Mind</h5>
                          <div className="rating">
                            {[...Array(5)].map((_, index) => (
                              <span
                                key={index}
                                style={{
                                  display: "inline-flex", // ใช้ inline-flex เพื่อให้ ReactSVG อยู่บรรทัดเดียวกัน
                                  alignItems: "center", // จัดการแนวตั้งให้อยู่กึ่งกลาง
                                  marginRight: 10,
                                  cursor: "pointer",
                                  color:
                                    index < serviceRating
                                      ? "#ffc107"
                                      : "#e4e5e9",
                                }}
                                onClick={() => {
                                  if (!isReview) {
                                    setServiceRating(index + 1);
                                  }
                                }}
                              >
                                {index < serviceRating ? (
                                  <ReactSVG
                                    src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_star_p.svg"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <ReactSVG
                                    src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_star_gr.svg"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </span>
                            ))}
                          </div>
                        </div>

                        <div className="mt-2                                                             ">
                          <h5>Advice and Guidance</h5>
                          <div className="rating">
                            {[...Array(5)].map((_, index) => (
                              <span
                                key={index}
                                style={{
                                  display: "inline-flex", // ใช้ inline-flex เพื่อให้ ReactSVG อยู่บรรทัดเดียวกัน
                                  alignItems: "center", // จัดการแนวตั้งให้อยู่กึ่งกลาง
                                  marginRight: 10,
                                  cursor: "pointer",
                                  fontSize: "30px",
                                  color:
                                    index < adviceRating
                                      ? "#ffc107"
                                      : "#e4e5e9",
                                }}
                                onClick={() => {
                                  if (!isReview) {
                                    setAdviceRating(index + 1);
                                  }
                                }}
                              >
                                {index < adviceRating ? (
                                  <ReactSVG
                                    src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_star_b.svg"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <ReactSVG
                                    src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_star_gr.svg"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body p-lg-3 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <h5 className="fw-700mb-3">
                        What do you think about{" "}
                        <strong> {courseData?.courseName}</strong> ?
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="card-body w-100 border-0">
                  <div
                    className="card-body"
                    style={{ backgroundColor: "#F5F5F5", borderRadius: "10px" }}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src={courseData?.profileImage}
                        alt="profile"
                        className="rounded-circle"
                        style={{
                          width: "70px",
                          height: "70px",
                          marginRight: "30px",
                        }}
                      />

                      <div className="row">
                        <h4 className="fw-600">{courseData?.courseName}</h4>

                        <div>
                          <div className="rating">
                            {[...Array(5)].map((_, index) => (
                              <span
                                key={index}
                                style={{
                                  marginRight: 10,
                                  display: "inline-flex", // ใช้ inline-flex เพื่อให้ ReactSVG อยู่บรรทัดเดียวกัน
                                  alignItems: "center", // จัดการแนวตั้งให้อยู่กึ่งกลาง
                                  cursor: "pointer",
                                  fontSize: "30px",
                                  color:
                                    index < courseRating
                                      ? "#ffc107"
                                      : "#e4e5e9",
                                }}
                                onClick={() => {
                                  if (!isReview) {
                                    setCourseRating(index + 1);
                                  }
                                }}
                              >
                                {index < courseRating ? (
                                  <ReactSVG
                                    src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_star_p.svg"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                ) : (
                                  <ReactSVG
                                    src="assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_star_gr.svg"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                )}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body p-lg-3 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12 d-flex align-items-center justify-content-end">
                      {isReview ? null : (
                        <>
                          {serviceRating == 0 &&
                          adviceRating == 0 &&
                          courseRating == 0 ? (
                            <div
                              className=" p-2 z-index-1 rounded-3 text-white fw-700 ls-3"
                              style={{
                                backgroundColor: "#EE3D7F",
                                cursor: "pointer",
                              }}
                              onClick={() => navigate("/round-activity")}
                            >
                              Later
                            </div>
                          ) : (
                            <div
                              className=" p-2 z-index-1 rounded-3 text-white fw-700 ls-3"
                              style={{
                                backgroundColor: "#EE3D7F",
                                cursor: "pointer",
                              }}
                              onClick={() => handleSubmit()}
                            >
                              OK
                            </div>
                          )}

                          <div
                            className="bg-greylight p-2 z-index-1 rounded-3 text-grey fw-700 ls-3"
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                            onClick={() => navigate(-1)}
                          >
                            Back
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}

export default ReviewRound;
