import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import {
  addDoc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  orderBy,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import { useSelector } from "react-redux";
import "./Caddie.css";

import ModalCaddieDetail from "./component/ModalCaddieDetail";
import ModalFilter from "./ModalFilter";
import { useNavigate } from "react-router-dom";

import { Highlight } from "react-instantsearch";
import axios from "axios";
import {
  favFriendCaddie,
  favFriend,
  unfavFriend,
  unfavFriendCaddie,
} from "../../components/friend/FavFriend";
import StackFilter from "./component/StackFilter";
import CaddieCard from "./component/CaddieCard";
import Label from "../createlabel/Label";
import CaddieSearch from "./component/CaddieSearch";
import LoadCircle from "../../components/LoadCircle";

const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
    Math.cos(lat2 * (Math.PI / 180)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
};

function Caddie() {
  const userData = useSelector((state) => state.user.data);

  const [caddieList, setCaddieList] = useState([]);
  const [courseData, setCourseData] = useState();
  const [show, setShow] = useState(false);
  const [filteredCaddieList, setFilteredCaddieList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [caddieData, setCaddieData] = useState({});
  const [favoriteFriendsList, setFavoriteFriendsList] = useState({});
  const [caddieProfile, setCaddieProfile] = useState({});
  const [userLabels, setUserLabels] = useState([]);
  const [listUserBlocked, setListUserBolcked] = useState([]);
  const [selectedUserLabel, setSelectedUserLabel] = useState(null);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [triggerFriendList, setTriggerFriendList] = useState(true);
  const [triggerGetLabel, setTriggerGetLabel] = useState(false);
  const [originalCaddieList, setOriginalCaddieList] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [listIdDescription, setListIdDescription] = useState([]);
  const [userLocation, setUserLocation] = useState(null);

  const [sortData, setSortData] = useState({
    isLabel: { state: false, selectedLabels: [] },
    isFavorites: { state: false },
    isStar: { state: false },
    isPro: { state: false },
    age: { state: false, min: 18, max: 40 },
    isBlocks: { state: false },
    // height: { state: true, min: 0, max: 300 },
    // weight: { state: true, min: 0, max: 300 },
  });
  const navigate = useNavigate();

  const [tempSortData, setTempSortData] = useState({
    isLabel: { state: true, selectedLabels: [] },
    isFavorites: { state: true },
    isStar: { state: true },
    isPro: { state: true },
    age: { state: true, min: 18, max: 40 },
    isBlocks: { state: true },
  });

  const [search, setSearch] = useState({
    province: {
      state: false,
      value: "",
    },
    text: {
      state: false,
      value: "",
    },
    nearMe: {
      state: false,
    },
  });

  const allStatesFalse = Object.keys(sortData).every(
    (key) => sortData[key].state === false
  );

  const trueStates = Object.keys(sortData).filter(
    (key) => sortData[key].state === true
  );

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    async function fetchData() {
      const q = query(
        collection(firestore, "labels"),
        where("userId", "==", userData.uid)
      );
      const querySnapshot = await getDocs(q);
      let dataArray = [];
      querySnapshot.forEach((doc2) => {
        const data = doc2.data();
        dataArray.push({ docId: doc2.id, ...data });
      });
      setUserLabels(dataArray);
    }
    if (userData) {
      fetchData();
    }
  }, [userData, triggerGetLabel]);

  useEffect(() => {
    async function fetchData() {
      const q = query(
        collection(firestore, "blockedUsers"),
        where("userId", "==", userData.uid)
      );
      const querySnapshot = await getDocs(q);
      let dataArray = [];
      querySnapshot.forEach((doc2) => {
        const data = doc2.data();
        dataArray.push(data.blockedUserId);
      });
      setListUserBolcked(dataArray);
    }
    if (userData) {
      fetchData();
    }
  }, [userData]);

  useEffect(() => {
    async function fetchData() {
      let caddieList = [];
      const q = query(
        collection(firestore, "users"),
        where("role", "==", "caddie"),
        orderBy("isStar", "desc")
      );

      const querySnapshot = await getDocs(q);
      const caddiePromises = querySnapshot.docs.map(async (docUser) => {
        if (docUser.data().firstName.includes("ทดสอบระบบ")) {
          // console.log("ทดสอบระบบ");
        } else {
          if (docUser.data().uid !== undefined) {
            const q_caddieAssignments = query(
              collection(firestore, "caddieAssignments"),
              where("caddieId", "==", docUser.data().uid)
            );

            const querySnapshot_caddieAssignments = await getDocs(
              q_caddieAssignments
            );

            const innerPromises = querySnapshot_caddieAssignments.docs.map(
              async (docCaddieAssignment) => {
                const docRef = doc(
                  firestore,
                  "courses",
                  docCaddieAssignment.data().courseId
                );
                const docCourse = await getDoc(docRef);

                if (docCourse.exists()) {
                  caddieList.push({
                    idDocUser: docUser.id,
                    ...docUser.data(),
                    courseId: docCourse.id,
                    courseName: docCourse.data().courseName,
                    location: docCourse.data().location,
                    imageUrl: docUser.data().profileImage || "user.png",
                    name:
                      docUser.data().firstName + " " + docUser.data().lastName,
                  });
                } else {
                  console.log("No such document!");
                  setLoading(false);
                }
              }
            );

            await Promise.all(innerPromises);
          }
        }
      });

      await Promise.all(caddiePromises);

      // console.log(caddieList);

      for (let index = 0; index < caddieList.length; index++) {
        const element = caddieList[index];

        const q3 = query(
          collection(firestore, "labeledUsers"),
          where("friendId", "==", element.uid),
          where("userId", "==", userData.uid)
        );
        const querySnapshot3 = await getDocs(q3);
        querySnapshot3.forEach((doc2) => {
          const data = doc2.data();
          let labels = [];
          if (data.labels) {
            for (let index = 0; index < data.labels.length; index++) {
              const element = data.labels[index];
              const item = userLabels.find((item) => item.docId === element);
              labels.push(item?.label);
            }
          }
          caddieList[index].labels = labels;
        });
      }
      setLoading(false);
      setCaddieList(caddieList);
      setOriginalCaddieList(caddieList);
    }

    if (userData) {
      fetchData();
    }
  }, [userData, userLabels]);

  const handleShow = async (docID) => {
    setFavoriteFriends(false);
    setShow(true);

    console.log(docID);

    const docRef = doc(firestore, "users", docID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let isFriend = false;

      if (docSnap.data().uid) {
        const q3 = query(
          collection(firestore, "friends"),
          where("friendId", "==", docSnap.data().uid),
          where("userId", "==", userData?.uid)
        );

        const q2 = query(
          collection(firestore, "friends"),
          where("friendId", "==", userData?.uid),
          where("userId", "==", docSnap.data().uid)
        );

        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach(async (doc2) => {
          if (doc2.data().status == "active") {
            isFriend = true;
          }
        });

        const querySnapshot3 = await getDocs(q3);
        querySnapshot3.forEach(async (doc3) => {
          if (doc3.data().status == "active") {
            isFriend = true;
          }
        });
      }

      const q_caddieAssignments = query(
        collection(firestore, "caddieAssignments"),
        where("caddieId", "==", docSnap.data().uid)
      );
      const querySnapshot_caddieAssignments = await getDocs(
        q_caddieAssignments
      );
      querySnapshot_caddieAssignments.forEach(async (docCaddieAssignment) => {
        const docRefCourse = doc(
          firestore,
          "courses",
          docCaddieAssignment.data().courseId
        );
        const docSnapCourse = await getDoc(docRefCourse);

        if (docSnapCourse.exists()) {
          setCourseData({
            ...docSnapCourse.data(),
            courseId: docSnapCourse.id,
          });
        } else {
          console.log("No such document!");
        }
      });

      setCaddieData({ isFriend: isFriend, ...docSnap.data() });

      const q_caddieProfiles = query(
        collection(firestore, "caddieProfiles"),
        where("caddieId", "==", docSnap.data().uid)
      );
      const querySnapshot_caddieProfiles = await getDocs(q_caddieProfiles);
      querySnapshot_caddieProfiles.forEach(async (docCaddieprofile) => {
        let listImage = [
          { imageUrl: docSnap.data()?.profileImage, isPrimary: true, order: 0 },
        ];
        const querySnapshot = await getDocs(
          collection(firestore, "caddieProfiles", docCaddieprofile.id, "image")
        );
        querySnapshot.forEach((doc) => {
          listImage.push(doc.data());
        });
        setCaddieProfile({ ...docCaddieprofile.data(), image: listImage });
      });
    }

    if (docSnap.data().uid === undefined) {
    } else {
      const q = query(
        collection(firestore, "favoriteFriends"),
        where("caddieId", "==", docSnap.data().uid),
        where("userId", "==", userData.uid)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        setFavoriteFriends(false);
      } else {
        querySnapshot.forEach((doc1) => {
          if (doc1.data().status == "active") {
            setFavoriteFriends(true);
          } else {
            setFavoriteFriends(false);
          }
        });
      }
    }
  };

  const handleSwitchChange = (checked, switchId) => {
    setTempSortData((prevState) => ({
      ...prevState,
      [switchId]: { ...prevState[switchId], state: checked },
    }));
  };

  const handleSubmit = () => {
    setSortData(tempSortData);
    handleCloseSortAndFilter();
  };

  const [showSortAndFilter, setShowSortAndFilter] = useState(false);
  const handleCloseSortAndFilter = () => {
    setTempSortData(sortData);
    setShowSortAndFilter(false);
  };
  const handleShowSortAndFilter = () => {
    setTempSortData(sortData);

    setShowSortAndFilter(true);
  };

  const handleInputChange = (e, field, type) => {
    const value = e && e.target ? e.target.value : e;

    setTempSortData((prevState) => ({
      ...prevState,
      [field]: { ...prevState[field], [type]: value },
    }));
  };

  const [favoriteFriends, setFavoriteFriends] = useState(false);

  const [checkIsRequestToCourse, setCheckIsRequestToCourse] = useState("");
  const [trigerDebug, setTrigerDebug] = useState(false);
  useEffect(() => {
    if (userData?.role === "caddie") {
      const q = query(
        collection(firestore, "caddieRequests"),
        where("caddieId", "==", userData.uid)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.size === 0) {
          setCheckIsRequestToCourse("");
        } else {
          querySnapshot.forEach((doc) => {
            const status = doc.data().status;
            if (status === "pending") {
              setCheckIsRequestToCourse("pending");
            } else if (status === "approved") {
              setCheckIsRequestToCourse("approved");
            } else if (status === "rejected") {
              setCheckIsRequestToCourse("rejected");
            }
          });
        }
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }
  }, [userData, trigerDebug]);

  const handleChat = async (dooNineId) => {
    console.log(dooNineId);

    try {
      const response = await axios.get(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels?members_exactly_in=${userData.dooNineId}&show_member=false&members_exactly_in=${dooNineId}`,
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": sendBird_api_token,
          },
        }
      );

      if (response.data.channels.length > 0) {
        isMobile
          ? navigate("/chat-room", {
            state: {
              channelUrl: response.data.channels[0].channel_url,
              previousPage: "caddie",
            },
          })
          : navigate("/chat", {
            state: {
              channelUrl: response.data.channels[0].channel_url,
              previousPage: "caddie",
            },
          });
      } else {
        const response2 = await axios.post(
          "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels",
          {
            user_ids: [userData.dooNineId, dooNineId],
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": sendBird_api_token,
            },
          }
        );
        isMobile
          ? navigate("/chat-room", {
            state: {
              channelUrl: response.data.channels[0].channel_url,
              previousPage: "caddie",
            },
          })
          : navigate("/chat", {
            state: {
              channelUrl: response.data.channels[0].channel_url,
              previousPage: "caddie",
            },
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fav = [];

    async function getFavUserCaddie() {
      const caddieQuery = query(
        collection(firestore, "favoriteFriends"),
        where("userId", "==", userData.uid),
        where("status", "==", "active")
      );

      const querySnapshot = await getDocs(caddieQuery);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          fav.push(doc.data().caddieId);
        });
      }
    }

    async function getFavUser() {
      const playerQuery = query(
        collection(firestore, "favoritePlayers"),
        where("userId", "==", userData.uid),
        where("status", "==", "active")
      );

      const querySnapshot = await getDocs(playerQuery);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          fav.push(doc.data().playerId);
        });
      }
    }

    if (userData) {
      getFavUserCaddie();
      getFavUser();

      setFavoriteFriendsList(fav);
    }
  }, [userData]);

  const handleFav = async (isCaddie, friendId, favType) => {
    if (userData && friendId) {
      if (favType === "fav") {
        if (isCaddie === "caddie") {
          setFavoriteFriendsList((prev) => [...prev, friendId]);
          const isFav = await favFriendCaddie(userData?.uid, friendId);
        } else {
          setFavoriteFriendsList((prev) => [...prev, friendId]);
          const isFav = await favFriend(userData?.uid, friendId);
        }
      } else {
        if (isCaddie === "caddie") {
          setFavoriteFriendsList((prev) =>
            prev.filter((id) => id !== friendId)
          );
          const isUnFav = await favFriendCaddie(userData?.uid, friendId);
        } else {
          setFavoriteFriendsList((prev) =>
            prev.filter((id) => id !== friendId)
          );
          const isUnFav = await favFriend(userData?.uid, friendId);
        }
      }
    }
  };

  useEffect(() => {
    let filteredCaddie = [...originalCaddieList];

    if (search.nearMe.state) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });

          filteredCaddie.sort((a, b) => {
            const distanceA = calculateDistance(
              latitude,
              longitude,
              a.location.latLong.latitude,
              a.location.latLong.longitude
            );
            const distanceB = calculateDistance(
              latitude,
              longitude,
              b.location.latLong.latitude,
              b.location.latLong.longitude
            );
            return distanceA - distanceB;
          });

          setCaddieList(filteredCaddie);
        },
        (error) => console.error("Error getting user location:", error),
        { enableHighAccuracy: true }
      );
    } else {
      setUserLocation(null);
      filteredCaddie.sort((a, b) => {
        const nameA = a.firstName || "";
        const nameB = b.firstName || "";
        return nameA.localeCompare(nameB);
      });

      setCaddieList(filteredCaddie);
    }
  }, [search.nearMe.state, originalCaddieList]);

  useEffect(() => {
    const filterCaddie = () => {
      let filteredCaddies = [...originalCaddieList];
      // Filter by province if the state is true
      if (search.province.state && search.province.value) {
        filteredCaddies = filteredCaddies.filter(
          (course) =>
            course.location.provinceEng.toLowerCase() ===
            search.province.value.toLowerCase()
        );
      }

      // Filter by text search if the state is true
      if (search.text.state && search.text.value) {
        // Assuming Algolia search API is used for text search
        let caddieIdList = [];

        const url =
          "https://RJJNPL7MRL.algolia.net/1/indexes/caddieProfiles/query";
        const payload = {
          query: search.text.value,
        };
        const headers = {
          "x-algolia-application-id": "RJJNPL7MRL",
          "x-algolia-api-key": "bb21db75be816cbedc8dd1b5838e4bba",
          "Content-Type": "application/json",
        };

        axios
          .post(url, payload, { headers })
          .then((response) => {
            const caddieIdList = response.data.hits.map((hit) => hit.caddieId);
            setListIdDescription(caddieIdList);
          })
          .catch((error) => {
            console.error("Error fetching courses:", error);
          });
      } else {
      }
    };
    filterCaddie();
  }, [
    search.province.state,
    search.province.value,
    search.text.state,
    search.text.value,
    originalCaddieList,
    // filter, // Include the filter state dependencies
    userLocation,
  ]);

  useEffect(() => {
    const sortedList = [...caddieList];

    // Sort the list based on the "Near Me" criteria
    if (search.nearMe.state && userLocation) {
      sortedList.sort((a, b) => {
        const distanceA = calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          a.location.latLong.latitude,
          a.location.latLong.longitude
        );
        const distanceB = calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          b.location.latLong.latitude,
          b.location.latLong.longitude
        );
        return distanceA - distanceB;
      });
    } else {
      sortedList.sort((a, b) => {
        const nameA = a.firstName || "";
        const nameB = b.firstName || "";
        return nameA.localeCompare(nameB);
      });
    }

    const provinceFilteredList =
      search.province.state && search.province.value
        ? sortedList.filter(
          (caddie) =>
            caddie.location.provinceEng.toLowerCase() ===
            search.province.value.toLowerCase()
        )
        : sortedList;

    const prioritizedList = provinceFilteredList.sort((a, b) => {
      const isStarA = a.isStar ? 1 : 0;
      const isProA = a.isPro ? 1 : 0;
      const isStarB = b.isStar ? 1 : 0;
      const isProB = b.isPro ? 1 : 0;

      // Sort by isStar first, then by isPro
      if (isStarA !== isStarB) return isStarB - isStarA;
      if (isProA !== isProB) return isProB - isProA;

      return 0;
    });

    // Apply additional filtering criteria
    const filteredList = prioritizedList.filter((caddie) => {
      const isStarMatch =
        !sortData.isStar.state || (sortData.isStar.state && caddie.isStar);
      const isProMatch =
        !sortData.isPro.state || (sortData.isPro.state && caddie.isPro);

      const hasValidBirthDate = caddie.birthDate.year;
      const isAgeMatch =
        !sortData.age.state ||
        (hasValidBirthDate &&
          2024 - caddie.birthDate.year >= sortData.age.min &&
          2024 - caddie.birthDate.year <= sortData.age.max);

      const isFavoriteMatch =
        !sortData.isFavorites.state ||
        Object.values(favoriteFriendsList).includes(caddie.uid);

      const selectedLabels =
        sortData.isLabel?.selectedLabels.map((option) => option.label) || [];

      const isLabelMatch =
        !sortData.isLabel?.state ||
        selectedLabels.length === 0 ||
        (caddie.labels &&
          selectedLabels.every((label) => caddie.labels.includes(label)));

      const isBlockMatch =
        !sortData.isBlocks.state ||
        Object.values(listUserBlocked).includes(caddie.uid);

      const isSearchMatch =
        !search.text.state ||
        (listIdDescription.length === 0
          ? caddie.firstName
            .toLowerCase()
            .includes(search.text.value.toLowerCase()) ||
          caddie.lastName
            .toLowerCase()
            .includes(search.text.value.toLowerCase())
          : Object.values(listIdDescription).includes(caddie.uid));

      return (
        isStarMatch &&
        isProMatch &&
        isAgeMatch &&
        isFavoriteMatch &&
        isLabelMatch &&
        isBlockMatch &&
        isSearchMatch
      );
    });

    setFilteredCaddieList(filteredList);
  }, [
    caddieList,
    sortData,
    favoriteFriendsList,
    search,
    userLocation,
    listIdDescription,
  ]);

  // console.log(filteredCaddieList);
  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12 pageCaddie">
                <div className="card shadow-xss w-100 d-block d-flex border-0 mb-1">
                  <div className="d-flex w-100 flex-column flex-md-row p-3">
                    <h2 className="d-flex fw-700 mb-2 mb-md-0 mt-0 font-md text-grey-900 align-items-center">
                      Caddies
                    </h2>

                    <div className="d-flex align-items-center ms-md-auto mt-2 mt-md-0 w-md-auto">
                      <div className="search-form-2 flex-grow-1">
                        <CaddieSearch
                          search={search}
                          setSearch={setSearch}
                          openSearch={openSearch}
                          setOpenSearch={setOpenSearch}
                        ></CaddieSearch>
                      </div>

                      <div
                        onClick={() => handleShowSortAndFilter()}
                        style={{ cursor: "pointer" }}
                        className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3"
                      >
                        <i className="feather-filter font-xss text-grey-500"></i>
                      </div>
                    </div>
                  </div>

                  <StackFilter
                    trueStates={trueStates}
                    sortData={sortData}
                    setSortData={setSortData}
                    setTempSortData={setTempSortData}
                    search={search}
                    setSearch={setSearch}
                    originalCaddieList={originalCaddieList}
                  ></StackFilter>

                  {!loading ?
                    <div className="row gx-1 ps-1 pe-1">
                      {filteredCaddieList.map((value, index) => (
                        <>
                          <CaddieCard
                            value={value}
                            index={index}
                            handleShow={handleShow}
                            handleChat={handleChat}
                            favoriteFriendsList={favoriteFriendsList}
                            handleFav={handleFav}
                            setSelectedUserLabel={setSelectedUserLabel}
                            setShowLabelModal={setShowLabelModal}
                          ></CaddieCard>
                        </>
                      ))}
                    </div>
                    :
                    <div className="d-flex justify-content-center p-5">
                      <LoadCircle size="lg" variant="dark" />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Caddie detail */}
      <ModalCaddieDetail
        show={show}
        setShow={setShow}
        caddieData={caddieData}
        setCaddieData={setCaddieData}
        setCourseData={setCourseData}
        setFavoriteFriends={setFavoriteFriends}
        favoriteFriends={favoriteFriends}
        courseData={courseData}
        caddieProfile={caddieProfile}
        setCaddieProfile={setCaddieProfile}
      ></ModalCaddieDetail>

      <Label
        triggerFriendList={triggerFriendList}
        setTriggerFriendList={setTriggerFriendList}
        showLabelModal={showLabelModal}
        setShowLabelModal={setShowLabelModal}
        setSelectedUserLabel={setSelectedUserLabel}
        selectedUserLabel={selectedUserLabel}
        userLabels={userLabels}
        triggerGetLabel={triggerGetLabel}
        setTriggerGetLabel={setTriggerGetLabel}
      />

      {/* Modal Filter */}
      <ModalFilter
        showSortAndFilter={showSortAndFilter}
        setShowSortAndFilter={setShowSortAndFilter}
        handleCloseSortAndFilter={handleCloseSortAndFilter}
        tempSortData={tempSortData}
        handleSubmit={handleSubmit}
        handleSwitchChange={handleSwitchChange}
        handleInputChange={handleInputChange}
        userLabels={userLabels}
      // handleInputLabelChange={handleInputLabelChange}
      />

      <Popupchat />
      <Appfooter />
    </>
  );
}

export default Caddie;

export const Hit = ({ hit }) => {
  return (
    <article>
      <div className="hit-description">
        <Highlight attribute="description" hit={hit} />
      </div>
    </article>
  );
};
