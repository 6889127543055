import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { firestore } from "../firebase/FirebaseConfig";

export async function addFriendnotifications(senderID, receiverID, timeStampInSeconds) {
    try {

        if (!senderID || !receiverID) {
            console.error("Invalid userID or friendID!!");
            console.error("userID :", senderID);
            console.error("friendID :", receiverID);
            return;
        }

        if (!timeStampInSeconds) {
            timeStampInSeconds = Math.floor(Date.now() / 1000);
        }
        const docRef = await addDoc(collection(firestore, "notifications"), {
            notificationId: '',
            fromUserId: senderID,
            toUserId: receiverID,
            isRead: false,
            timestamp: timeStampInSeconds,
            type: 'add'
        });
        await updateDoc(doc(firestore, "notifications", docRef.id), {
            notificationId: docRef.id
        });
    } catch (error) {
        console.error('Fail to send notification', error);
    }
}

export async function acceptFriendnotifications(senderID, receiverID, timeStampInSeconds) {
    try {

        if (!senderID || !receiverID) {
            console.error("Invalid userID or friendID!!");
            console.error("userID :", senderID);
            console.error("friendID :", receiverID);
            return;
        }

        if (!timeStampInSeconds) {
            timeStampInSeconds = Math.floor(Date.now() / 1000);
        }
        const docRef = await addDoc(collection(firestore, "notifications"), {
            notificationId: '',
            fromUserId: senderID,
            toUserId: receiverID,
            isRead: false,
            timestamp: timeStampInSeconds,
            type: 'accept'
        });
        await updateDoc(doc(firestore, "notifications", docRef.id), {
            notificationId: docRef.id
        });
    } catch (error) {
        console.error('Fail to send notification', error);
    }
}