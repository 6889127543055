import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

function RoundsCount({ activeSectionRound, setActiveSectionRound }) {
  const [listRoundUpcoming, setListRoundUpcoming] = useState([]);
  const [listRoundFinished, setListRoundFinsihed] = useState([]);

  const userData = useSelector((state) => state.user.data);

  // Handlers for clicking each section
  const handleUpcomingClick = () => {
    setActiveSectionRound("upcoming");
  };

  const handleFinishedClick = () => {
    setActiveSectionRound("finished");
  };

  useEffect(() => {
    async function fetchData() {
      const roundQuery = query(collection(firestore, "round"));
      const roundSnapshot = await getDocs(roundQuery);

      let dataRondUpcoming = [];
      let dataRondFinished = [];
      roundSnapshot.forEach((doc) => {
        const roundData = doc.data();

        // Check if the round creator is the current user or if the user is in userList and isJoin is true
        const isCreator = roundData.creator === userData.uid;
        const isUserInList = roundData.userList.some(
          (user) =>
            (user.player?.uid === userData.uid &&
              user.player?.isJoin === true) ||
            (user.caddie?.uid === userData.uid && user.caddie?.isJoin === true)
        );

        if (isCreator || isUserInList) {
          if (roundData.teeTime === "schedule"&& (roundData.status == undefined || roundData.status !== "deleted")) {
            dataRondUpcoming.push({ ...roundData });
          }

          if (roundData.teeTime === "finish"&& (roundData.status == undefined || roundData.status !== "deleted")) {
            dataRondFinished.push({ ...roundData });
          }

          setListRoundUpcoming(dataRondUpcoming);
          setListRoundFinsihed(dataRondFinished);
        }
      });
    }
    if (userData) {
      fetchData();
    }
  }, [userData]);

  return (
    <>
      {/* Card for selecting sections */}
      <Card className="border-0 shadow-xss rounded-xxl mb-1 mt-2">
        <Card.Body className="main-player-stat">
          <section className="gap-2 d-flex flex-md-nowrap flex-wrap mb-2">
            {/* First Section: Upcoming Round */}
            <div
              className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3"
              style={{
                backgroundColor: "#F2CFA4",
                border:
                  activeSectionRound === "upcoming"
                    ? "3px solid #E59E49"
                    : "none",
                cursor: "pointer",
              }}
              onClick={handleUpcomingClick}
            >
              <strong
                className="px-3 rounded-3"
                style={{ backgroundColor: "#E59E49" }}
              >
                {listRoundUpcoming.length}
              </strong>
              <h4 className="mb-3 ms-3 fw-600" style={{ color: "#E59E49" }}>
                {`Upcoming Round`}
              </h4>
            </div>

            {/* Second Section: Finished Round */}
            <div
              className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3"
              style={{
                backgroundColor: "#909294",
                border:
                  activeSectionRound === "finished"
                    ? "3px solid #212529"
                    : "none",
                cursor: "pointer",
              }}
              onClick={handleFinishedClick}
            >
              <strong
                className="px-3 rounded-3"
                style={{ backgroundColor: "#212529" }}
              >
                {listRoundFinished.length}
              </strong>
              <h4 className="mb-3 ms-3 fw-600" style={{ color: "#212529" }}>
                {`Finished Round`}
              </h4>
            </div>
          </section>
        </Card.Body>
      </Card>
    </>
  );
}

export default RoundsCount;
