import { Card } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  limit,
  orderBy,
  deleteDoc,
  onSnapshot
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { denyRound } from '../module/denyRound';
function RoundRequest() {
  const userData = useSelector((state) => state.user.data);
  const [listRoundRequest, setListRoundRequest] = useState([]);
  const [listRoundStart, setListRoundStart] = useState([]);

  const maxUserList = { desktop: 7, mobile: 5 };
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const roundQuery = query(collection(firestore, "round"));
      const roundSnapshot = await getDocs(roundQuery);

      let roundDataArray = [];
      roundSnapshot.forEach((doc) => {
        const roundData = doc.data();

        const isCreator = roundData.creator === userData.uid;
        const isUserInList = roundData.userList.some(
          (user) =>
            (user.player?.uid === userData.uid &&
              user.player?.isJoin === true) ||
            (user.caddie?.uid === userData.uid && user.caddie?.isJoin === true)
        );

        if (isCreator || isUserInList) {
          if (roundData.teeTime === "now") {
            roundDataArray.push({ ...roundData, roundId: doc.id });
          }
        }
      });
      setListRoundStart(roundDataArray);
    }
    if (userData) {
      fetchData();
    }
  }, [userData]);

  useEffect(() => {
    if (!userData?.uid) return;
  
    const fetchUserDetails = async (uid, userCache) => {
      if (userCache[uid]) return userCache[uid]; // Return from cache if exists
  
      const userQuery = query(collection(firestore, "users"), where("uid", "==", uid));
      const userSnapshot = await getDocs(userQuery);
      let userDetails = {};
      userSnapshot.forEach((userDoc) => {
        userDetails = userDoc.data();
      });
      userCache[uid] = userDetails; // Cache the result
      return userDetails;
    };
  
    const roundActivityQuery = query(
      collection(firestore, "roundActivity"),
      where("userList", "array-contains", userData.uid),
      where("creator", "!=", userData.uid),
      orderBy("created", "desc")
    );
  
    const unsubscribe = onSnapshot(roundActivityQuery, async (roundActivitySnapshot) => {
      if (roundActivitySnapshot.empty) return setListRoundRequest([]);
  
      const rounds = roundActivitySnapshot.docs.map((doc) => doc.data());
      const dataArray = [];
      const userCache = {}; // Cache for user data
  
      for (const roundActivity of rounds) {
        const { creator } = roundActivity;
        
        const roundQuery = query(
          collection(firestore, "round"),
          where("creator", "==", creator),
          orderBy("created", "desc"),
          limit(1)
        );
  
        const roundSnapshot = await getDocs(roundQuery);
        if (roundSnapshot.empty) continue;
  
        const roundDoc = roundSnapshot.docs[0];
        const round = roundDoc.data();
  
        let isJoinTrue = false;
        const userChecks = round.userList.map(({ player, caddie }) => {
          if (player?.uid === userData.uid && player.isJoin) isJoinTrue = true;
          if (caddie?.uid === userData.uid && caddie.isJoin) isJoinTrue = true;
        });
  
        await Promise.all(userChecks);
  
        if (!isJoinTrue && round.scheduleTime) {
          const courseHolesRef = doc(firestore, "courseHoles", round.holesCourse);
          const [courseHolesSnapshot, courseSnapshot] = await Promise.all([
            getDoc(courseHolesRef),
            getDocs(query(collection(firestore, "courses"), where("id", "==", round.course)))
          ]);
  
          const holesCourse = courseHolesSnapshot.exists() ? courseHolesSnapshot.data().courseHoleName : "";
  
          const { time, day, month } = epochToDateTime(round.scheduleTime);
  
          const userFetches = round.userList.map(async ({ player, caddie }) => {
            const userDataArray = [];
            if (player?.uid) {
              const playerData = await fetchUserDetails(player.uid, userCache);
              userDataArray.push({ ...playerData, isCreator: player.isCreator, isJoin: player.isJoin });
            }
            if (caddie?.uid) {
              const caddieData = await fetchUserDetails(caddie.uid, userCache);
              userDataArray.push({ ...caddieData, isCreator: caddie.isCreator, isJoin: caddie.isJoin });
            }
            return userDataArray;
          });
  
          const userDataArray = await Promise.all(userFetches);
  
          courseSnapshot.forEach((courseDoc) => {
            const courseData = courseDoc.data();
            dataArray.push({
              ...round,
              courseInfo: courseData,
              time,
              day,
              month,
              userData: userDataArray.flat(), // Flatten array
              holesCourse,
            });
          });
        }
      }
  
      setListRoundRequest(dataArray);
    });
  
    return () => unsubscribe();
  }, [userData]);
  

  const handleJoinClick = (roundId) => {
    if (listRoundStart.length > 0) {
      Swal.fire({
        title: "Round In Progress",
        text: "You still have a round in progress. Please finish that round first.",
        icon: "warning",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      });
    } else {
      navigate(`/join-round?id=${roundId}`);
    }
  };

  const handleDeny = async (roundId, userId) => {
    try {
      await denyRound(roundId, userId);
      console.log("Round denied successfully");
    } catch (error) {
      console.error("Error denying round:", error);
    }
  };
console.log(listRoundRequest)
  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl w-100">
        <Card.Body className="main-player-stat p-1">
          <div className="card-body d-flex align-items-center p-2">
            <h4 className="fw-700 mb-0 font-xssss text-grey-900">
              Round Request
            </h4>
            <Link to={""} className="fw-600 ms-auto font-xssss text-primary">
              See all
            </Link>
          </div>

          {listRoundRequest.length > 0 ? (
            listRoundRequest
              .filter((round) =>
                round.userData.some(
                  (user) => user.uid === userData.uid && user.isJoin === false
                )
              )
              .sort((a, b) => {
                // เปรียบเทียบวันที่เพื่อให้ได้ creator ล่าสุด
                return new Date(b.createdAt) - new Date(a.createdAt);
              })
              .slice(0, 1) // เลือกแค่รอบที่สร้างล่าสุดอันเดียว
              .map((round, index) => (
                <div key={index} className="create-round-req-main mb-3">
                  <section className="create-round-req-head">
                    <div className="pointer">
                      <h4 className="fw-600">{round.roundName}</h4>
                    </div>
                  </section>

                  <section
                    className="create-round-req-body p-1 mb-2 pointer d-flex align-items-center me-2"
                    style={{
                      backgroundImage: round.coverImage
                        ? typeof round.coverImage === "string"
                          ? `url(${round.coverImage})`
                          : `url(${URL.createObjectURL(round.coverImage)})`
                        : "none",
                      height: round.coverImage ? "" : "inherit",
                    }}
                  >
                    <div
                      className="req-course-data d-flex align-items-center w-100"
                      style={{
                        backgroundColor: round.coverImage ? "" : "#fff",
                      }}
                    >
                      <div className="col-sm-2 col-xs-1 text-center d-flex align-items-center pointer me-3">
                        <img
                          src={round.courseInfo.profileImage}
                          alt="course-profile"
                          width={50}
                          height={50}
                          className="rounded-circle my-2"
                        />
                      </div>
                      <div className="col-sm-6 col-xs-6 d-flex align-items-center justify-content-start pointer">
                        <div>
                          <h6 className="fw-600 fs-5 mb-2">
                            {round.courseInfo.courseName}
                          </h6>
                          <h6 className="fw-600 fs-7">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</h6>
                        </div>
                      </div>
                      <div className="col-sm-4 col-xs-4 d-flex my-2 my-sm-0">
                        <div
                          className="h-100 w-75 d-flex flex-column justify-content-evenly rounded-3 py-2 pointer"
                          style={{ backgroundColor: "#E59E49" }}
                        >
                          {round.teeTime === "schedule" ? (
                            <>
                              <h4 className="fw-600 text-white text-center">
                                {round.month}
                              </h4>
                              <h1 className="fw-700 text-white text-center">
                                {round.day}
                              </h1>
                              <div className="bg-white text-center">
                                <span
                                  className="fw-600 fs-4"
                                  style={{ color: "#E59E49" }}
                                >
                                  {round.time}
                                </span>
                              </div>
                            </>
                          ) : (
                            <div className="bg-white text-center px-2">
                              <span
                                className="fw-600 fs-4"
                                style={{ color: "#E59E49" }}
                              >
                                Start Now
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section>
                    {/* Desktop display */}
                    <div className="d-none d-sm-flex flex-column justify-content-between p-3 pointer">
                      <div className="d-flex align-items-center mb-3 ms-2">
                        {round.userData
                          .slice(0, maxUserList.desktop)
                          .map((user, idx) =>
                            user.isCreator ? (
                              <React.Fragment key={idx}>
                                <img
                                  src={user.profileImage}
                                  alt={user.role}
                                  width={40}
                                  height={40}
                                  className={`rounded-circle ${user.role}-color-outline me-4`}
                                />
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={idx}>
                                <img
                                  src={user.profileImage}
                                  alt={user.role}
                                  width={40}
                                  height={40}
                                  className={`rounded-circle ${user.role}-color-outline`}
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.desktop - idx,
                                  }}
                                />
                              </React.Fragment>
                            )
                          )}
                        {round.userData.length > maxUserList.desktop && (
                          <div
                            className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                            style={{
                              width: 40,
                              height: 40,
                              outline: "1px dashed grey",
                              opacity: 0.6,
                            }}
                          >
                            <h6 className="text-center">
                              +{round.userData.length - maxUserList.desktop}{" "}
                              more
                            </h6>
                          </div>
                        )}
                      </div>

                      {/* Request By section */}
                      <div className="d-flex align-items-center mb-3">
                        {round.userData.map(
                          (user, idx) =>
                            user.isCreator === true && (
                              <div key={idx}>
                                <h6
                                  className="fw-600"
                                  style={{ fontSize: "11px" }}
                                >
                                  Request By {user.firstName} {user.lastName}
                                </h6>
                              </div>
                            )
                        )}
                      </div>

                      {/* Join/Deny buttons */}
                      <div className="d-flex justify-content-end">
                        {/* <Link to={`/join-round?id=${round.roundId}`}> */}
                        <button
                          className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                          onClick={() => handleJoinClick(round.roundId)}
                        >
                          Join
                        </button>
                        {/* </Link> */}
                        <button
                          className="p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                          style={{ background: "#f5f5f5" }}
                          onClick={() => handleDeny (round.roundId, userData.uid)}
                        >
                          Deny
                        </button>
                      </div>
                    </div>

                    {/* Mobile display */}
                    <div className="d-sm-none d-flex flex-column justify-content-between p-3 pointer">
                      <div className="d-flex align-items-center mb-3 ms-2">
                        {round.userData
                          .slice(0, maxUserList.mobile)
                          .map((user, idx) =>
                            user.isCreator ? (
                              <React.Fragment key={idx}>
                                <img
                                  src={user.profileImage}
                                  alt={user.role}
                                  width={40}
                                  height={40}
                                  className={`rounded-circle ${user.role}-color-outline me-4`}
                                />
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={idx}>
                                <img
                                  src={user.profileImage}
                                  alt={user.role}
                                  width={40}
                                  height={40}
                                  className={`rounded-circle ${user.role}-color-outline`}
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.mobile - idx,
                                  }}
                                />
                              </React.Fragment>
                            )
                          )}
                        {round.userData.length > maxUserList.mobile && (
                          <div
                            className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                            style={{
                              width: 40,
                              height: 40,
                              outline: "1px dashed grey",
                            }}
                          >
                            <h6 className="text-center">
                              +{round.userData.length - maxUserList.mobile} more
                            </h6>
                          </div>
                        )}
                      </div>

                      {/* Request By section */}
                      <div className="d-flex align-items-center mb-3">
                        {round.userData.map(
                          (user, idx) =>
                            user.isCreator === true && (
                              <div key={idx}>
                                <h6 className="fw-600 fs-7">
                                  Request By {user.firstName} {user.lastName}
                                </h6>
                              </div>
                            )
                        )}
                      </div>

                      {/* Join/Deny buttons */}
                      <div className="d-flex justify-content-end">
                        {/* <button className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                        Join
                      </button> */}
                        <Link to={`/join-round?id=${round.roundId}`}>
                          <div className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                            Join
                          </div>
                        </Link>
                        <button
                          className="p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                          style={{ background: "#f5f5f5" }}
                        >
                          Deny
                        </button>
                      </div>
                    </div>
                  </section>
                </div>
              ))
          ) : (
            <p>No rounds request available</p>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
export default RoundRequest;
