import { firestore } from "../firebase/FirebaseConfig"
import { collection, addDoc, where, query, getDocs, serverTimestamp } from "firebase/firestore"

export const blockFriend = async (userId, blockId) => {
    try {
        const q1 = query(collection(firestore, "blockedUsers"), where("blockedUserId", "==", blockId), where("userId", "==", userId));
        const q2 = query(collection(firestore, "blockedUsers"), where("blockedUserId", "==", userId), where("userId", "==", blockId));

        const querySnapshot1 = await getDocs(q1);
        const querySnapshot2 = await getDocs(q2);

        if (querySnapshot1.empty && querySnapshot2.empty) {
            await addDoc(collection(firestore, "blockedUsers"), {
                blockedUserId: blockId,
                userId: userId,
                timestamp: serverTimestamp()
            });
            return true;
        } else {
            console.warn('Alredy block this user');
            return false;
        }
    } catch (error) {
        console.error('Error blocking user', error);
        return false;
    }
}