import React, { Fragment, useState, useEffect, useRef } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "@sendbird/uikit-react/dist/index.css";
import "./styles.css";
import { ReactSVG } from "react-svg";
import { GroupChannelList } from "@sendbird/uikit-react/GroupChannelList";
import GroupChannel from "@sendbird/uikit-react/GroupChannel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';

import GroupChannelListHeader from "./component/GroupChannelListHeader";
import GroupChannelListPreview from "./component/GroupChannelListPreview";
import ChannelHeader from "./component/ChannelHeader";
import Swal from "sweetalert2";

const sendMessage = "public/assets/images/message/Chat-Send-Message.svg";
const friend = "assets/images/message/Chat-Friends.svg";
const addFriend = "assets/images/message/Char-Add-Friend.svg";
const menu = "assets/images/message/Chat-Menu.svg";


function Chat() {
  const userData = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  const location = useLocation();
  const [channelSelected, setChannelSelected] = useState(
    location.state?.channelUrl || ""
  );
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [members, setMembers] = useState([]);
  const [isFriend, setIsFriend] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenChannelPre, setDropdownOpenChannelPre] = useState(false);

  const [dataProfile, setDataProfile] = useState();


  const [showSettings, setShowSettings] = useState(false)
  const [currentChannelUrl, setCurrentChannelUrl] = useState('')


  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    async function fetchData() {
      if (!channelSelected) return;
      try {
        const response = await axios.get(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelSelected}/members`,
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );
        console.log(response);
        let member = response.data.members.filter(
          (user) => user.user_id !== userData?.dooNineId
        );
        setMembers(member);

        const userQuery = query(
          collection(firestore, "users"),
          where("dooNineId", "==", member[0].user_id)
        );
        const userQuerySnapshot = await getDocs(userQuery);

        if (!userQuerySnapshot.empty) {
          const userDoc = userQuerySnapshot.docs[0]; // Assuming there's only one document

          setDataProfile(userDoc.data());

          // Queries for checking if the user is a friend
          const friendQuery1 = query(
            collection(firestore, "friends"),
            where("friendId", "==", userDoc.data().uid),
            where("userId", "==", userData.uid),
            where("status", "==", "active")
          );

          const friendQuery2 = query(
            collection(firestore, "friends"),
            where("friendId", "==", userData.uid),
            where("userId", "==", userDoc.data().uid),
            where("status", "==", "active")
          );

          // Use Promise.all to run both queries concurrently
          const [friendSnapshot1, friendSnapshot2] = await Promise.all([
            getDocs(friendQuery1),
            getDocs(friendQuery2),
          ]);

          const isFriend = !friendSnapshot1.empty || !friendSnapshot2.empty;

          setIsFriend(isFriend);
          console.log(isFriend ? "isFriend" : "notFriend");
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (userData) {
      fetchData();
    }
  }, [channelSelected, userData]);

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      console.log("ChannelPreviewGoToProfile");
      navigate("/user", { state: { uid: uid } });
    }
  };

  const CustomGroupChannelInput = (props) => {
    return (
      <GroupChannel
        {...props}
        renderFileUploadIcon={() => (
          <ReactSVG
            src={sendMessage}
            style={{ width: "24px", height: "24px" }}
          />
        )}
        onSendFileMessage={() => {
          console.log("Send message icon clicked");
        }}
      />
    );
  };

  const handleChannelSelect = (channel) => {
    if (!channel) {
      navigate("/");
      return;
    }

    const channelUrl = channel.url;

    if (isMobile) {
      navigate("/chat-room", {
        state: {
          channelUrl,
          previousPage: "chat",
        },
      });
    } else {
      setChannelSelected(channelUrl);
    }
  };

  const handleLeaveChannel = async (channelUrl, userId) => {
    // console.log(channelUrl);
    // console.log(userId);

    // Example channelUrl: 'sendbird_group_channel_424931360_3c8ddf9e0821743ddff84638a95bb73320e930ea'
    // Example userId: 'lazyladybug543'

    try {
      await axios.delete(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelUrl}`,

        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
          },
        }
      );

      Swal.fire({
        title: 'Leave Channel Success ',
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      }).then(async (result) => {
        setChannelSelected(location.state?.channelUrl | '');

      });

    } catch (error) {
      console.error("Error leaving channel:", error);
    }
  };

  const dropdownRef = useRef(null);

  const handleDropdownToggle = (dropdownId) => {
    setDropdownOpenChannelPre((prev) => (prev === dropdownId ? null : dropdownId));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpenChannelPre(null);
    }
  };

  const handleTouchOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpenChannelPre(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleTouchOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleTouchOutside);
    };
  }, []);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />
      <SendbirdProvider
        appId={'B72515CE-E0DA-417F-AF02-4BF3D07B3D50'}
        userId={userData?.dooNineId}
        accessToken={userData?.sendBirdAccessToken}
      >
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div
                  className={`col-xl-5 ${isMobile ? "App-chat-mobile" : "App-chat-desktop"
                    }`}
                  style={{ padding: "0" }}
                >
                  <GroupChannelList
                    style={{ overflow: 'auto' }}
                    selectedChannelUrl={channelSelected}
                    onChannelSelect={handleChannelSelect}
                    renderHeader={() => <GroupChannelListHeader />}
                    disableAutoSelect
                    renderChannelPreview={({ channel }) => (
                      <GroupChannelListPreview
                        channel={channel}
                        dropdownOpenChannelPre={dropdownOpenChannelPre}
                        setDropdownOpenChannelPre={setDropdownOpenChannelPre}
                        handleDropdownToggle={handleDropdownToggle}
                        dropdownRef={dropdownRef}
                        handleProfileClick={handleProfileClick}
                        handleLeaveChannel={handleLeaveChannel}
                        selectedChannelUrl={channelSelected}
                      />
                    )}
                  />
                </div>

                <div className={`sendbird-conversation-image-click col-xl-7 ${isMobile ? "" : "App-chat-desktop"}`}>
                  {isMobile ? (
                    ""
                  ) : (
                    <>


                      <GroupChannel
                        channelUrl={channelSelected}
                        isReactionEnabled={true}
                        renderInput={(props) => (
                          <CustomGroupChannelInput {...props} />
                        )}
                        renderChannelPreviewAvatar={() => null}
                        renderChannelHeader={() => (
                          <ChannelHeader
                            channel={channelSelected}
                            members={members}
                            isFriend={isFriend}
                            friend={friend}
                            addFriend={addFriend}
                            menu={menu}
                            toggleDropdown={toggleDropdown}
                            dropdownOpen={dropdownOpen}
                            handleProfileClick={handleProfileClick}
                            dataProfile={dataProfile}
                            handleLeaveChannel={handleLeaveChannel}
                          >
                          </ChannelHeader>
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </SendbirdProvider>
      <Popupchat />
      <Appfooter />
    </Fragment >
  );
}

export default Chat;


