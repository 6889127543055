import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import Picker from "react-mobile-picker";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";

function AddScoreModal({
  showScoreModal,
  setShowScoreModal,
  selectedHole,
  courseHoleData,
  holeScoreData,
}) {
  const userData = useSelector((state) => state.user.data);
  const [holeData, setHoleData] = useState(null);

  const handleCloseScoreModal = () => {
    setShowScoreModal(false);
  };

  const [pickerValue, setPickerValue] = useState({
    score: "-",
    putts: "-",
    fairwayHit: "-",
    bunkerHit: "-",
    penalties: "-",
  });

  const [drivingRange, setDrivingRange] = useState("");

  const selections = {
    score: [
      "-",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
    ],
    putts: ["-", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    fairwayHit: ["-", "↑", "↖", "↗"],
    bunkerHit: ["-", "YES", "NO"],
    penalties: ["-", "0", "1", "2"],
  };

  useEffect(() => {
    const bunkerHit =
      selectedHole?.bunkerHit === true
        ? "YES"
        : selectedHole?.bunkerHit === false
        ? "NO"
        : "-";

    const fairwayHit =
      selectedHole?.fairwayHit == "center"
        ? "↑"
        : selectedHole?.fairwayHit == "left"
        ? "↖"
        : selectedHole?.fairwayHit == "right"
        ? "↗"
        : "-";

    setPickerValue({
      score: selectedHole?.score || "-",
      putts: selectedHole?.putts || "-",
      fairwayHit: fairwayHit,
      bunkerHit: bunkerHit,
      penalties: selectedHole?.penalties || "-",
    });
    setDrivingRange(selectedHole?.drivingRange || "");
  }, [selectedHole]);

  const handleSubmit = async () => {
    const holeField = `hole${selectedHole.holeNumber}`;

    const updateData = {};

    updateData[`${holeField}.bunkerHit`] =
      pickerValue.bunkerHit === "NO"
        ? false
        : pickerValue.bunkerHit === "YES"
        ? true
        : pickerValue.bunkerHit;

    updateData[`${holeField}.fairwayHit`] =
      pickerValue.fairwayHit === "↑"
        ? "center"
        : pickerValue.fairwayHit === "↖"
        ? "left"
        : pickerValue.fairwayHit === "↗"
        ? "right"
        : pickerValue.fairwayHit;

    updateData[`${holeField}.drivingRange`] = drivingRange;
    // updateData[`${holeField}.fairwayHit`] = pickerValue.fairwayHit;
    updateData[`${holeField}.penalties`] = pickerValue.penalties;
    updateData[`${holeField}.putts`] = pickerValue.putts;
    updateData[`${holeField}.score`] = pickerValue.score;

    const ref = doc(firestore, "scoreCards", selectedHole.docId);
    await updateDoc(ref, updateData);
    handleCloseScoreModal();
  };

  const handleClear = () => {
    setPickerValue({
      score: "-",
      putts: "-",
      fairwayHit: "-",
      bunkerHit: "-",
      penalties: "0",
    });
    setDrivingRange("");
  };

  useEffect(() => {
    const hole = holeScoreData.find(
      (hole) =>
        hole.holeNumber === selectedHole?.holeNumber ||
        hole.holeNumber === parseInt(selectedHole?.holeNumber)
    );

    setHoleData(hole);
  }, [selectedHole, holeScoreData]);

  return (
    <>
      <Modal show={showScoreModal} onHide={handleCloseScoreModal} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ display: "flex", alignItems: "center" }}>
            <h2 className="fw-600 mb-0 mt-0">
              {courseHoleData?.courseHoleName}
            </h2>

            <div
              style={{ display: "flex", alignItems: "center", marginLeft: 15 }}
            >
              <h2 className="fw-600 mb-0 mt-0" style={{ color: "#EE3D7F" }}>
                {holeData?.holeNumber}
              </h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginLeft: 5,
                }}
              >
                <h4>{holeData?.par}</h4>
                <h4 className="text-muted">{holeData?.handicapIndex}</h4>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="d-flex justify-content-between"
            style={{ gap: "10px" }} // เพิ่มช่องว่างระหว่าง pickers
          >
            {Object.keys(selections).map((name, index) => (
              <div key={index} style={{ flex: "1" }}>
                <h4 className="m-0 fw-bold" style={{ textAlign: "center" }}>
                  {name}
                </h4>
                <Picker
                  value={pickerValue}
                  wheelMode="normal"
                  height={160}
                  onChange={setPickerValue}
                >
                  <Picker.Column key={name} name={name}>
                    {selections[name].map((option) => (
                      <Picker.Item key={option} value={option}>
                        {option}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                </Picker>
              </div>
            ))}
          </div>

          <div className="form-group d-flex align-items-center">
            <h5 className="mb-2 me-3">Driving Range (yd)</h5>
            <input
              type="text"
              className="form-control w-auto"
              id="drivingRange"
              value={drivingRange}
              onChange={(e) => setDrivingRange(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn bg-greylight" onClick={() => handleClear()}>
            <h4 className="fw-600 m-0 text-grey-700 p-1">Clear</h4>
          </button>
          {/* <button className="btn bg-current post-btn">
            <h4 className="fw-600 m-0 text-white p-1">Next</h4>
          </button> */}
          <button
            className="btn bg-current post-btn"
            onClick={() => handleSubmit()}
          >
            <h4 className="fw-600 m-0 text-white p-1">OK</h4>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddScoreModal;
