import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useSelector } from "react-redux";
import {
  getDoc,
  query,
  where,
  getDocs,
  collection,
  doc,
  addDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { ReactSVG } from "react-svg";

function FinishRound() {
  const navigate = useNavigate();

  const [roundId, setRoundId] = useState(null);
  const [roundData, setRoundData] = useState(null);

  const userData = useSelector((state) => state.user.data);

  useEffect(() => {
    async function fetchData() {
      const params = new URLSearchParams(window.location.search);
      const roundId = params.get("round");
      setRoundId(roundId);

      if (roundId == null) {
        Swal.fire({
          title: "Invalid Round",
          html: `
            <h5 class="fw-600">The round you selected is invalid.</h5>
            <h5 class="fw-600">Please check and try again.</h5>
          `,
          icon: "error",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        }).then(async (result) => {
          navigate("/round-activity");
        });
      } else {
        const docRef = doc(firestore, "round", roundId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setRoundData(docSnap.data());
        } else {
          Swal.fire({
            title: "Invalid Round",
            html: `
              <h5 class="fw-600">The round you selected is invalid.</h5>
              <h5 class="fw-600">Please check and try again.</h5>
            `,
            icon: "error",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(async (result) => {
            navigate("/round-activity");
          });
        }
      }
    }
    if (userData) {
      fetchData();
    }
  }, [userData]);

  const handleFinishRound = () => {
    Swal.fire({
      title: "Are you sure?",
      text: " You won't be able to revert this!",
      icon: "warning",
      confirmButtonText: "Yes, Finish Round!",
      confirmButtonColor: "#EE3C7F",
      showCancelButton: true,
      // cancelButtonColor: "#fff",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (userData.role == "player") {
          const q = query(
            collection(firestore, "scoreCards"),
            where("roundId", "==", roundId),
            where("userId", "==", userData.uid)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc2) => {
            const ref = doc(firestore, "scoreCards", doc2.id);
            await updateDoc(ref, {
              status: "finished",
            });
          });
          Swal.fire({
            title: "Round Finished!",
            text: "Your round has been finished.",
            icon: "success",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(async (result) => {
            navigate(`/review-round?round=${roundId}`);
          });
        }
      }
    });
  };

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex justify-content-between align-items-center rounded-3">
                  <div className="d-flex align-items-center">
                    <Link
                      to={`/score?round=${roundId}`}
                      className="d-inline-block mt-2"
                    >
                      <i className="ti-arrow-left font-sm text-white"></i>
                    </Link>
                    <div>
                      <h2 className="text-white fw-600 ms-4 mb-0 mt-0">
                        Finish Round
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="card-body p-lg-3 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12 d-flex align-items-center">
                      <div
                        className=" p-2 z-index-1 rounded-3 text-white fw-700 ls-3"
                        style={{
                          backgroundColor: "#EE3D7F",
                          cursor: "pointer",
                        }}
                        onClick={() => handleFinishRound()}
                      >
                        OK
                      </div>

                      <div
                        className="bg-greylight p-2 z-index-1 rounded-3 text-grey fw-700 ls-3"
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                      >
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}

export default FinishRound;
