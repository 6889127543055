import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./FavoriteFriend";
import { ReactSVG } from "react-svg";
import { addFriend } from "../../components/friend/AddFriend";
import { or } from "firebase/firestore";
import {
  favFriend,
  favFriendCaddie,
  unfavFriend,
  unfavFriendCaddie,
} from "../../components/friend/FavFriend";
function ModalCaddieDetail(props) {
  const userData = useSelector((state) => state.user.data);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const navigate = useNavigate();

  const [friendState, setFriendState] = useState("");
  
  useEffect(() => {
    async function fetchData() {
      const caddieUid = props.caddieData.uid || "";
      let isFriend = false;
      const q3 = query(
        collection(firestore, "friends"),
        where("friendId", "==", caddieUid),
        where("userId", "==", userData?.uid)
      );

      const q2 = query(
        collection(firestore, "friends"),
        where("friendId", "==", userData?.uid),
        where("userId", "==", caddieUid)
      );

      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach(async (doc2) => {
        if (doc2.data().status == "active") {
          isFriend = true;
        }
      });

      const querySnapshot3 = await getDocs(q3);
      querySnapshot3.forEach(async (doc3) => {
        if (doc3.data().status == "active") {
          isFriend = true;
        }
      });

      if (isFriend) {
        // console.log("friend");
        setFriendState("friend");
      } else {
        // console.log("not-friend");

        // User add to caddie
        const q4 = query(
          collection(firestore, "friendRequests"),
          where("fromUserId", "==", userData?.uid),
          where("toUserId", "==", caddieUid)
        );

        // Caddie add to user (me)
        const q5 = query(
          collection(firestore, "friendRequests"),
          where("fromUserId", "==", caddieUid),
          where("toUserId", "==", userData?.uid)
        );

        let statePending = false;
        let stateRequesting = false;
        // User add to caddie
        const querySnapshot4 = await getDocs(q4);
        querySnapshot4.forEach(async (doc4) => {
          if (doc4.data().status == "pending") {
            setFriendState("not-friend-pending");
            statePending = true;
          }
        });

        // Caddie add to user (me)
        const querySnapshot5 = await getDocs(q5);
        querySnapshot5.forEach(async (doc5) => {
          if (doc5.data().status == "pending") {
            setFriendState("not-friend-requesting");
            stateRequesting = true;
          }
        });

        if (statePending == false && stateRequesting == false) {
          setFriendState("not-friend");
        }
      }
    }
    if (userData) {
      fetchData();
    }
  }, [userData, props.caddieData]);

  const handleClose = () => {
    props.setCaddieData({});
    props.setCourseData({});
    props.setCourseData({});
    props.setCaddieProfile({});
    props.setShow(false);
  };

  const handleFavorite = async (uid) => {
    await favFriendCaddie(userData.uid, uid);
    props.setFavoriteFriends(false);
  };

  const handleChat = async (dooNineId) => {
    const response = await axios.get(
      `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels?members_exactly_in=${userData.dooNineId}&show_member=false&members_exactly_in=${dooNineId}`,
      {
        headers: {
          "Content-Type": "application/json; charset=utf8",
          "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
        },
      }
    );

    if (response.data.channels.length > 0) {
      isMobile
        ? navigate("/chat-room", {
            state: {
              channelUrl: response.data.channels[0].channel_url,
              previousPage: "caddie",
            },
          })
        : navigate("/chat", {
            state: {
              channelUrl: response.data.channels[0].channel_url,
              previousPage: "caddie",
            },
          });
    } else {
      const response2 = await axios.post(
        "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels",
        {
          user_ids: [userData.dooNineId, dooNineId],
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
          },
        }
      );
      isMobile
        ? navigate("/chat-room", {
            state: {
              channelUrl: response.data.channels[0].channel_url,
              previousPage: "caddie",
            },
          })
        : navigate("/chat", {
            state: {
              channelUrl: response.data.channels[0].channel_url,
              previousPage: "caddie",
            },
          });
    }
  };

  const handleProfileClick = (uid) => {
    if (!uid) return;
    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  // console.log("props.caddieData", props.caddieData);
  // console.log("props.caddieProfile", props.caddieProfile);
  // console.log("props.courseData", props.courseData);
  // console.log(friendState);

  return (
    <>
      {/* Modal Caddie detail */}
      <Modal show={props.show} onHide={handleClose} scrollable>
        <Modal.Body>
          <div className="card-image w-100 mb-3 position-relative">
            {/* Icon Star */}
            {props.caddieData.isStar ? (
              <h4
                className="fw-600 position-absolute z-index-1 ls-3 font-xssss text-white"
                style={{
                  top: "10px",
                  right: "10px",
                }}
              >
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-CaddieStar.svg"
                  style={{ width: "40px", height: "40px" }}
                />
              </h4>
            ) : null}

            {/* Icon Pro */}
            {props.caddieData.isPro ? (
              <h4
                className="fw-600 position-absolute z-index-1 ls-3 font-xssss text-white"
                style={{
                  top: props.caddieData.isStar ? "55px" : "10px",
                  right: "10px",
                }}
              >
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-ProCaddie.svg"
                  style={{ width: "40px", height: "40px" }}
                />
              </h4>
            ) : null}

            {/* Statue online & Name */}
            <h4
              className="fw-700 position-absolute z-index-1 ls-3 font-xss text-white"
              style={{
                bottom: "30px",
                left: "15px",
              }}
              onClick={() => handleProfileClick(props.caddieData.uid)}
            >
              <span
                style={{
                  height: "10px",
                  width: "10px",
                  backgroundColor: "#10d876",
                  borderRadius: "50%",
                  display: "inline-block",
                  marginRight: "9px",
                  boxShadow: "0 0 0 3px white",
                }}
              ></span>
              {props.caddieData.firstName} {props.caddieData.lastName}
            </h4>

            {/* Chat Icon */}
            <h4
              onClick={() => handleChat(props.caddieData.dooNineId)}
              className="fw-600 position-absolute z-index-1 ls-3 font-xssss text-white"
              style={{
                bottom: "20px",
                right: "10px",
                cursor: "pointer",
              }}
            >
              <ReactSVG
                src="assets/icon/CaddieFriendFav-Chat.svg"
                style={{ width: "55px", height: "55px" }}
              />
            </h4>

            {/* Chat Favorite */}
            <h4
              onClick={() => handleFavorite(props.caddieData.uid)}
              className="fw-600 position-absolute z-index-1 ls-3 font-xssss text-white"
              style={{
                bottom: "20px",
                right: "70px",
                cursor: "pointer",
              }}
            >
              {props.favoriteFriends ? (
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-Fav.svg"
                  style={{ width: "55px", height: "55px" }}
                />
              ) : (
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-UnFav.svg"
                  style={{ width: "55px", height: "55px" }}
                />
              )}
            </h4>

            <Carousel>
              {props.caddieProfile?.image?.map((img, index) => (
                <Carousel.Item>
                  <img
                    src={img.imageUrl}
                    alt="hotel"
                    className="w-100"
                    style={{
                      height: "300px",
                      objectFit: "cover",
                      borderRadius: "15px",
                    }}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div className="card-body pt-0">
            <h4 className="fw-700 font-xs mt-0 lh-28 mb-1">Intro</h4>

            <h6 className="font-xss text-grey-700 fw-400 mt-0 mb-3">
              {props.caddieProfile.description}
            </h6>

            <div className="clearfix"></div>

            <div className="row">
              <div className="col-12">
                <div
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    height: "80px",
                    backgroundColor: "#f1f1f1",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={props.courseData?.profileImage}
                    style={{
                      width: "55px",
                      height: "55px",
                      marginLeft: "15px",
                      borderRadius: "50%",
                    }}
                  />
                  <div style={{ marginLeft: "15px" }}>
                    <h5 style={{ margin: 0 }} className="fw-700 font-xsss">
                      {props.courseData?.courseName}
                    </h5>
                    <h5
                      style={{ margin: 0 }}
                      className="fw-600 text-grey-700 font-xssss"
                    >
                      {props.courseData?.location?.district},{" "}
                      {props.courseData?.location?.province}
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            {/* <span className="font-lg fw-700 mt-0 pe-3 ls-2 lh-32 d-inline-block text-success float-left">
              <span className="font-xsssss">$</span> value.price{" "}
              <span className="font-xsssss text-grey-500">/ mo</span>{" "}
            </span>
            <Link to="/course-detail" className="position-relative d-block">
              <i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-right"></i>
            </Link> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {friendState == "friend" ? (
            <button
              className="btn text-white font-xsss"
              style={{ backgroundColor: "#EE3D7F" }}
            >
              <span
                style={{
                  display: "inline-block",
                  marginRight: "5px",
                }}
              >
                {" "}
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-Friends.svg"
                  style={{ width: "25px", height: "25px" }}
                />
              </span>
              Friend
            </button>
          ) : friendState == "not-friend-pending" ||
            friendState == "not-friend-requesting" ? (
            <button
              className="btn text-white font-xsss"
              style={{ backgroundColor: "#EE3D7F" }}
            >
              <span
                style={{
                  display: "inline-block",
                  marginRight: "5px",
                }}
              >
                {" "}
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-CancelRequest.svg"
                  style={{ width: "25px", height: "25px" }}
                />
              </span>
              Pending
            </button>
          ) : (
            <button
              className="btn text-white font-xsss"
              style={{ backgroundColor: "#EE3D7F" }}
              onClick={() => addFriend(props.caddieData.uid, userData.uid)}
            >
              <span
                style={{
                  display: "inline-block",
                  marginRight: "5px",
                }}
              >
                {" "}
                <ReactSVG
                  src="assets/icon/CaddieFriendFav-AddFriend.svg"
                  style={{ width: "25px", height: "25px" }}
                />
              </span>
              Add Friend
            </button>
          )}

          <button
            className="btn font-xsss"
            style={{
              backgroundColor: "#F5F5F5",
              color: "#212529",
            }}
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCaddieDetail;
