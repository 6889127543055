import { firestore } from "../../../../../components/firebase/FirebaseConfig";
import { where, query, getDocs, updateDoc, collection, serverTimestamp, doc } from "firebase/firestore";
import Swal from "sweetalert2";

export const confirmJoinCaddie = async (roundData, userData, yourPlayer, setLoading, navigate, userList) => {
    try {
        setLoading(true);

        const foundCaddie = roundData.userList.find(item => item.caddie && item.caddie.uid === userData.uid);

        if (foundCaddie) {
            // invied caddie
            if (yourPlayer) {
                // found your player
                foundCaddie.caddie.isJoin = true
                foundCaddie.player = {
                    isCreator: yourPlayer.isCreator || false,
                    isJoind: yourPlayer.isJoin || false,
                    uid: yourPlayer.uid
                }
            } else {
                // not found your player
                foundCaddie.caddie.isJoin = true
                foundCaddie.player = null
            }

        } else {
            // no invite caddie
            const foundNewCaddie = userList.find(item => item.caddie && item.caddie.uid === userData.uid);
            if (foundNewCaddie) {
                // found new caddie (you)
                const foundNewCaddieRow = roundData.userList.find(item => item.row === foundNewCaddie.row);
                if (foundNewCaddieRow) {
                    // found your selected row
                    if (yourPlayer) {
                        foundNewCaddieRow.caddie = {
                            uid: userData.uid,
                            isCreator: false,
                            isJoin: true,
                            playerConfirm: false
                        }
                        foundNewCaddieRow.player = {
                            uid: yourPlayer.uid,
                            isCreator: yourPlayer.isCreator || false,
                            isJoin: yourPlayer.isJoin || false,
                        }
                    } else {
                        // not found your player
                        foundNewCaddieRow.caddie = {
                            uid: userData.uid,
                            isCreator: false,
                            isJoin: true,
                            playerConfirm: false
                        }
                        foundNewCaddieRow.player = null
                    }
                }
            } else {
                // not found new caddie (you)
                setLoading(false);
                return;
            }

        }

        console.log(roundData);

        // return 

        const docRef = doc(firestore, 'round', roundData.roundId);
        await updateDoc(docRef, {
            userList: roundData.userList,
            lastUpdate: serverTimestamp()
        });

        const userUids = roundData.userList.reduce((acc, item) => {
            if (item.player && item.player.uid) {
                acc.push(item.player.uid);
            }
            if (item.caddie && item.caddie.uid) {
                acc.push(item.caddie.uid);
            }
            return acc;
        }, []);

        const roundActivityCollection = collection(firestore, 'roundActivity');
        const q = query(roundActivityCollection, where('roundId', '==', roundData.roundId));
        const roundActivitySnapshot = await getDocs(q);
        if (!roundActivitySnapshot.empty) {
            const activityRef = roundActivitySnapshot.docs[0].ref;
            updateDoc(activityRef, {
                userList: userUids,
                lastUpdate: serverTimestamp()
            })
        }

        setLoading(false);

        Swal.fire({
            title: 'Joined',
            icon: 'success'
        }).then(() => {
            navigate('/round-activity')
        })

    } catch (error) {
        setLoading(false);
        console.error('Fail to join', error);
    }
}