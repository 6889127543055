import { Card } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";
import clsx from "clsx";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";

function RoundFinish() {
  const [listRound, setListRound] = useState([]);
  const userData = useSelector((state) => state.user.data);
  const maxUserList = { desktop: 7, mobile: 5 };

  useEffect(() => {
    async function fetchData() {
      try {
        let dataArray = [];
        const roundQuery = query(
          collection(firestore, "round"),
          where("creator", "==", userData.uid)
        );
        const roundSnapshot = await getDocs(roundQuery);

        let roundDataArray = [];
        roundSnapshot.forEach((doc) => {
          const roundData = doc.data();
          if (roundData.teeTime === "finish") {
            roundDataArray.push({ ...roundData, roundId: doc.id });
          }
        });

        for (const round of roundDataArray) {
          let holesCourse = "";

          const courseHolesCollection = collection(firestore, "courseHoles");
          const q = doc(courseHolesCollection, round.holesCourse);
          const courseHolesSnapshot = await getDoc(q);
          if (courseHolesSnapshot.exists) {
            holesCourse = courseHolesSnapshot.data().courseHoleName;
          }

          const courseQuery = query(
            collection(firestore, "courses"),
            where("id", "==", round.course)
          );
          const { time, day, month } = epochToDateTime(round.scheduleTime);

          const fetchUserDetails = async (uid) => {
            const q = query(
              collection(firestore, "users"),
              where("uid", "==", uid)
            );
            const userSnapshot = await getDocs(q);
            let userDetails = {};
            userSnapshot.forEach((userDoc) => {
              userDetails = userDoc.data();
            });
            return userDetails;
          };

          let userList = round.userList;
          let userDataArray = [];

          for (let userObj of userList) {
            const { caddie, player } = userObj;

            if (player?.uid) {
              const playerData = await fetchUserDetails(player.uid);
              const combinedPlayerData = {
                ...playerData,
                isCreator: player.isCreator,
              }; // รวม playerData และ player.isCreator
              userDataArray.push(combinedPlayerData);
            }

            if (caddie?.uid) {
              const caddieData = await fetchUserDetails(caddie.uid);
              const combinedCaddieData = {
                ...caddieData,
                isCreator: caddie.isCreator,
              }; // รวม playerData และ player.isCreator
              userDataArray.push(combinedCaddieData);
            }
          }

          const courseSnapshot = await getDocs(courseQuery);
          courseSnapshot.forEach((doc2) => {
            const courseData = doc2.data();
            dataArray.push({
              ...round,
              courseInfo: courseData,
              time: time,
              day: day,
              month: month,
              userData: userDataArray,
              holesCourse: holesCourse,
            });
          });
        }

        setListRound(dataArray);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    if (userData) {
      fetchData();
    }
  }, [userData]);

  console.log(listRound);

  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl">
        <Card.Body className="main-player-stat">
          <div className="d-flex align-items-center mb-2">
            <select
              className="form-select"
              aria-label="Default select example"
              style={{
                height: "40px",
              }}
            >
              <option selected>All Rounds</option>
              <option value="1">18 Holes Finished</option>
              <option value="2">9 Holes Finished</option>
            </select>

            {/* Icon for filtering */}
            <div
              style={{
                cursor: "pointer",
                height: "40px",
                width: "40px",
              }}
              className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3 d-flex align-items-center justify-content-center"
            >
              <i className="feather-filter font-xss text-grey-500"></i>
            </div>
          </div>

          {listRound.length > 0 ? (
            listRound.map((round, index) => (
              <div key={index} className="create-round-req-main">
                <section className="create-round-req-head">
                  <div className="pointer">
                    <h4 className="fw-600">{round.roundName}</h4>
                    <br />
                    <h6 className="fw-600 text-muted">
                      {`${round.holes} (${round.holesCourse} ), ${round.gameMode}(${round.scoring})`}
                    </h6>
                  </div>
                  <div className="d-flex flex-wrap-reverse justify-content-end align-items-end">
                    <ReactSVG
                      src="assets/images/dooNineIconV2/chat-SL.svg"
                      wrapper="svg"
                      style={{ height: 40, width: 40 }}
                      className={clsx("pointer", {
                        "d-none": round.groupChat !== "create",
                      })}
                    />
                    <ReactSVG
                      src="assets/images/dooNineIconV3/create-round/createRound-menu.svg"
                      wrapper="svg"
                      className="wizrd-more-btn"
                    />
                  </div>
                </section>
                <section
                  className="create-round-req-body p-2 mb-2 pointer"
                  //   onClick={() => handleChatandDateClick()}
                  style={{
                    backgroundImage: round.coverImage
                      ? typeof round.coverImage === "string"
                        ? `url(${round.coverImage})`
                        : `url(${URL.createObjectURL(round.coverImage)})`
                      : "none",
                    height: round.coverImage ? "" : "inherit",
                  }}
                >
                  <div
                    className="req-course-data row"
                    style={{ backgroundColor: round.coverImage ? "" : "#fff" }}
                  >
                    <div
                      className="col-sm-2 text-center d-sm-flex align-items-center pointer"
                      //   onClick={(e) => {
                      //     handleCourseClick();
                      //     e.stopPropagation();
                      //   }}
                    >
                      <img
                        src={round.courseInfo.profileImage}
                        alt="course-profile"
                        width={70}
                        height={70}
                        className="rounded-circle my-2"
                      />
                    </div>
                    <div
                      className="col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start pointer"
                      //   onClick={(e) => {
                      //     handleCourseClick();
                      //     e.stopPropagation();
                      //   }}
                    >
                      <div>
                        <span className="fw-600 fs-4">
                          {round.courseInfo.courseName}
                        </span>{" "}
                        <br />
                        <span className="fw-600 fs-6">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</span>
                      </div>
                    </div>
                    <div className="col-sm-5 d-flex justify-content-center justify-content-sm-end my-2 my-sm-0">
                      <div
                        className="h-100 w-50 d-flex flex-column justify-content-evenly rounded-3 py-2 pointer"
                        style={{ backgroundColor: "#212529" }}
                        // onClick={() => handleChatandDateClick()}
                      >
                        {round.teeTime === "finish" ? (
                          <>
                            <h4 className="fw-600 text-white text-center">
                              {round.month}
                            </h4>
                            <h1 className="fw-700 text-white text-center">
                              {round.day}
                            </h1>
                            <div className="bg-white text-center">
                              <span
                                className="fw-600 fs-4"
                                style={{ color: "#212529" }}
                              >
                                {round.time}
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="bg-white text-center px-2">
                            <span
                              className="fw-600 fs-4"
                              style={{ color: "#212529" }}
                            >
                              Start Now
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  {/* desktop display 7 limit */}
                  <div className="d-none d-sm-flex align-items-center justify-content-between p-3 pointer">
                    {/* Left side: User profile images */}
                    <div className="d-flex align-items-center">
                      {round.userData
                        .slice(0, maxUserList.desktop)
                        .map((user, idx) =>
                          user.isCreator ? (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={50}
                                  height={50}
                                  className="rounded-circle player-color-outline me-4"
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={50}
                                  height={50}
                                  className="rounded-circle caddie-color-outline me-4"
                                />
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={50}
                                  height={50}
                                  className="rounded-circle player-color-outline"
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.desktop - idx,
                                  }}
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={50}
                                  height={50}
                                  className="rounded-circle caddie-color-outline"
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.desktop - idx,
                                  }}
                                />
                              )}
                            </React.Fragment>
                          )
                        )}
                      {round.userData.length > maxUserList.desktop ? (
                        <div
                          className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                          style={{
                            width: 50,
                            height: 50,
                            outline: "1px dashed grey",
                            opacity: 0.6,
                          }}
                        >
                          <h6 className="text-center">
                            +{round.userData.length - maxUserList.desktop} more
                          </h6>
                        </div>
                      ) : null}
                    </div>                    
                  </div>

                  {/* mobile display 5 limit */}
                  <div className="d-sm-none d-flex align-items-center justify-content-between p-3 pointer">
                    {/* Left side: User profile images */}
                    <div className="d-flex align-items-center">
                      {round.userData
                        .slice(0, maxUserList.mobile)
                        .map((user, idx) =>
                          user.isCreator ? (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={40}
                                  height={40}
                                  className="rounded-circle player-color-outline me-4"
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={40}
                                  height={40}
                                  className="rounded-circle caddie-color-outline me-4"
                                />
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={40}
                                  height={40}
                                  className="rounded-circle player-color-outline"
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.mobile - idx,
                                  }}
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={40}
                                  height={40}
                                  className="rounded-circle caddie-color-outline"
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.mobile - idx,
                                  }}
                                />
                              )}
                            </React.Fragment>
                          )
                        )}
                      {round.userData.length > maxUserList.mobile ? (
                        <div
                          className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                          style={{
                            width: 40,
                            height: 40,
                            outline: "1px dashed grey",
                          }}
                        >
                          <h6 className="text-center">
                            +{round.userData.length - maxUserList.mobile} more
                          </h6>
                        </div>
                      ) : null}
                    </div>                  
                  </div>
                </section>
              </div>
            ))
          ) : (
            <p></p>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
export default RoundFinish;
