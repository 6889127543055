import React, { useEffect, useState } from "react";

const GroupChannelListHeader = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          background: "rgb(255 255 255)",
          borderStartEndRadius: "10px",
          borderStartStartRadius: "10px",
        }}
      >
        <div style={{ flexGrow: 1 }} className="ms-2">
          <p
            style={{
              fontWeight: "bold",
              marginBottom: 0,
              fontSize: "20px",
              fontFamily: "var(--sendbird-font-family-default)",
            }}
          >
            Chat
          </p>
        </div>
        <div
          style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}
        >
          {/* <input
            type="text"
            placeholder="Search here."
            style={{
              padding: "5px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              background: "rgb(239 239 239)",
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          /> */}
        </div>
      </div>
    </>
  );
};

export default GroupChannelListHeader;
