import React, { useEffect, useState } from "react";

import Header from "../../../components/Header";
import Leftnav from "../../../components/Leftnav";
import Rightchat from "../../../components/Rightchat";
import Appfooter from "../../../components/Appfooter";
import Popupchat from "../../../components/Popupchat";

import CreateRound from "../component/CreateRoundCard";
import RoundsCount from "./RoundsCount";
import RoundFinished from "./finishedround/RoundFinished";
import RoundUpcoming from "./upcominground/RoundUpcoming";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import {
  collection,
  query,
  onSnapshot,
  where,
  getDocs,
  or,
  and,
  orderBy,
  limit,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import RoundStart from "../roundactivity/startround/RoundStart";
import RoundRequest from "../roundactivity/requestround/RoundRequest";

function RoundActivity() {
  const [activeSectionRound, setActiveSectionRound] = useState("upcoming");
  const [listRound, setListRound] = useState([]);
  const userData = useSelector((state) => state.user.data);

  useEffect(() => {
    if (userData) {
      const unsubscribe = onSnapshot(query(collection(firestore, "round")), (roundSnapshot) => {
        let roundDataArray = [];
        
        roundSnapshot.forEach((doc) => {
          const roundData = doc.data();
  
          const isCreator = roundData.creator === userData.uid;
          const isUserInList = roundData.userList.some(
            (user) =>
              (user.player?.uid === userData.uid && user.player?.isJoin === true) ||
              (user.caddie?.uid === userData.uid && user.caddie?.isJoin === true)
          );
  
          if (isCreator || isUserInList) {
            if (roundData.teeTime === "now") {
              roundDataArray.push({ ...roundData, roundId: doc.id });
            }
          }
        });
  
        setListRound(roundDataArray);
      });
  
      return () => unsubscribe();
    }
  }, [userData]);
  
  console.log(listRound)
  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />

      {/* <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row feed-body mb-2">
              <div className="col-xl-8 col-xxl-9 col-lg-8 mb-2">
                {listRound.length > 0 ? (
                  <RoundStart />
                ) : (
                  <CreateRound />
                )}
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0" style={{ maxHeight: 600, overflow: 'auto' }}>
                <RoundRequest />
                <RoundRequest />

              </div>
            </div>

            <div className="row feed-body">
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                <RoundsCount
                  activeSectionRound={activeSectionRound}
                  setActiveSectionRound={setActiveSectionRound}
                />
                {activeSectionRound === "upcoming" && <RoundUpcoming />}
                {activeSectionRound === "finished" && <RoundFinished />}
              </div>


            </div>
          </div>
        </div>
      </div> */}

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row feed-body mb-2">
              <div className="col-xl-8 col-xxl-9 col-lg-8 px-md-3 feed-home">
                {listRound.length > 0 ? (
                  <RoundStart />
                ) : (
                  <CreateRound />
                )}
                <div
                  className="d-lg-none d-block col-xl-4 col-xxl-3 col-lg-4 ps-lg-0"
                >
                  <RoundRequest />

                </div>
                <RoundsCount
                  activeSectionRound={activeSectionRound}
                  setActiveSectionRound={setActiveSectionRound}
                />
                {activeSectionRound === "upcoming" && <RoundUpcoming />}
                {activeSectionRound === "finished" && <RoundFinished />}
              </div>
              <div
                className="d-none d-lg-block col-xl-4 col-xxl-3 col-lg-4 ps-lg-0 right-scroll-bar"
              >
                <RoundRequest />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left d-flex">
          <div className="row feed-body mb-2">

            <div className="col-xl-8 col-xxl-9 col-lg-8 mb-2 me-2">
              {listRound.length > 0 ? <RoundStart /> : <CreateRound />}

              <RoundsCount
                activeSectionRound={activeSectionRound}
                setActiveSectionRound={setActiveSectionRound}
              />
              {activeSectionRound === "upcoming" && <RoundUpcoming />}
              {activeSectionRound === "finished" && <RoundFinished />}
            </div>

            <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
              <RoundRequest />
            </div>
          </div>
          </div>
        </div>
      </div> */}
      <Popupchat />
      <Appfooter />
    </>
  );
}

export default RoundActivity;
