
import React, { useMemo, useState, useEffect } from 'react'
import Chart from "react-apexcharts";


export const DistributionChart = ({ userId = null }) => {

    const [series, setSeries] = useState([
        {
            name: 'Point',
            data: [30, 40, 45, 50, 49, 80]
        }
    ])

    const renderChart = useMemo(() => {
        if (!userId) return

        const options = {
            chart: {
                id: 'responsive-bar',
            },
            xaxis: {
                categories: ['Eagle', 'Birdie', 'Par', 'Bogey', 'Bouble', 'Triple'],
            },
            yaxis: {
                max: 100,
                show: false,
                labels: {
                    formatter: (value) => `$${value}`, // Add unit to y-axis labels
                },
            },
            title: {
                text: 'Score Distribution',
                align: 'center',
                style: {
                    fontSize: '16px',
                    color: '#333'
                }
            },
            colors: ['#FEB01980', '#FF456080', '#00E39680', '#775DD080', '#008FFB80', '#3A3A3A80'],
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    horizontal: false,
                    distributed: true,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val + "%";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                y: {
                    formatter: (value) => `${value}%`
                }
            },
            legend: {
                show: false
            }
        }

        return (
            <div className="chart-container" style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="100%"
                    height="300"
                />
            </div>
        )
    }, [series, userId])

    return (
        <>
            {renderChart}
        </>
    );
}

export const ScoreByParChart = ({ userId = null }) => {
    const renderChart = useMemo(() => {
        if (!userId) return

        const options = {
            chart: {
                id: 'responsive-bar',
            },
            xaxis: {
                categories: ['Par2', 'Par3', 'Par4'],
            },
            yaxis: {
                max: 6,
            },
            title: {
                text: 'Score By Par',
                align: 'center',
                style: {
                    fontSize: '16px',
                    color: '#333'
                }
            },
            colors: ['#008FFB80'],
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    horizontal: false,
                    distributed: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
        }

        const series = [
            {
                name: 'Point',
                data: [3.2, 4, 5.5]
            }
        ]

        return (
            <div className="chart-container" style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="100%"
                    height="300"
                />
            </div>
        )
    }, [userId])

    return (
        <>
            {renderChart}
        </>
    );
}

// caddie
export const DistributionChartCaddie = ({ userId = "test" }) => {
    const [series, setSeries] = useState([
        {
            name: 'Point',
            data: [30, 40, 45, 50, 49, 80]
        }
    ])

    const renderChart = useMemo(() => {
        if (!userId) return

        const options = {
            chart: {
                id: 'responsive-bar',
            },
            xaxis: {
                categories: ['Eagle', 'Birdie', 'Par', 'Bogey', 'Bouble', 'Triple'],
            },
            yaxis: {
                max: 100,
                show: false,
                labels: {
                    formatter: (value) => `$${value}`, // Add unit to y-axis labels
                },
            },
            title: {
                text: 'Score Distribution',
                align: 'center',
                style: {
                    fontSize: '16px',
                    color: '#333'
                }
            },
            colors: ['#FEB01980', '#FF456080', '#00E39680', '#775DD080', '#008FFB80', '#3A3A3A80'],
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    horizontal: false,
                    distributed: true,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val + "%";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                y: {
                    formatter: (value) => `${value}%`
                }
            },
            legend: {
                show: false
            }
        }

        return (
            <div className="chart-container" style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="100%"
                    height="300"
                />
            </div>
        )
    }, [series, userId])

    return (
        <>
            {renderChart}
        </>
    );
}

export const MonthlyAverageCaddie = ({ userId = "test" }) => {
    const renderChart = useMemo(() => {
        if (!userId) return

        const options = {
            chart: {
                id: 'responsive-bar',
            },
            xaxis: {
                categories: ['Par2', 'Par3', 'Par4'],
            },
            yaxis: {
                max: 6,
            },
            title: {
                text: 'Score By Par',
                align: 'center',
                style: {
                    fontSize: '16px',
                    color: '#333'
                }
            },
            colors: ['#008FFB80'],
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    horizontal: false,
                    distributed: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
        }

        const series = [
            {
                name: 'Point',
                data: [3.2, 4, 5.5]
            }
        ]

        return (
            <div className="chart-container" style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="100%"
                    height="300"
                />
            </div>
        )
    }, [userId])

    return (
        <>
            {renderChart}
        </>
    );
}