/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState, useEffect, useRef } from 'react'
import { firestore } from '../../../../../components/firebase/FirebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';

import { Accordion, ListGroup, useAccordionButton, ToggleButton } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useWizard } from '../wizardContext';
import clsx from 'clsx';
import moment from 'moment';
import { scoringDef } from '../module/holeDef';
import FilterCourse, { handleSearch } from '../module/searchCourse';
import CourseDetail from '../module/CourseDetail';

const StepOneByPlayer = () => {
    const userData = useSelector((state) => state.user.data);

    const { stepData, updateStepData } = useWizard();
    const data = stepData.stepOne;

    const courseListRef = useRef([])
    const courseDetailRef = useRef(null)
    const [courseLoading, setCourseLoading] = useState(true);
    const [holesLoading, setHolesLoading] = useState(true);
    const [selectedCourse, setSelectedCourse] = useState(data.course);
    const [selectCourseisOpen, setSelectCourseIsOpen] = useState(false);

    const [nineHolesData, setNineHolesData] = useState([]);
    const [eighteenHolesData, seteighteenHolesData] = useState([]);
    const [holeButton, setHoleButton] = useState([]);
    const [holesValue, setHolesValue] = useState(data.holes || "");
    const [holesCourse, setHolesCourse] = useState([]);
    const [selectedHoleCourse, setSelectedHoleCourse] = useState(data.holesCourse || "");

    const [handicapSelect, setHandicapSelect] = useState(data.handicap);
    const [scoringSelect, setScoringSelect] = useState(data.scoring);
    const [scoringType, setScoringType] = useState(data.scoringType);

    const [gameModeVal, setGameModeVal] = useState(data.gameMode);
    const gameModeButton = [
        {
            name: 'Stroke Play',
            value: 'str',
        },
        {
            name: 'Stableford',
            value: 'stb'
        },
        {
            name: 'Match Play',
            value: 'mp'
        }
    ]

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCourse, setFilteredCourse] = useState(courseListRef.current);

    const [filterModal, setFilterModal] = useState(false);
    const [courseDetailModal, setCourseDetailModal] = useState(false);

    const handleOpenFilterModal = () => {
        setFilterModal(true)
    }
    const handleCloseFilterModal = () => {
        setFilterModal(false)
    }

    const handleOpenCourseDetailModal = (e, course) => {
        e.stopPropagation();
        courseDetailRef.current = course;
        setCourseDetailModal(true);
    }

    const handleCloseCourseDetailModal = () => {
        setCourseDetailModal(false)
    }

    const toggleAccordion = useAccordionButton("0", () => setSelectCourseIsOpen(!selectCourseisOpen));

    useEffect(() => {
        async function getCourse() {
            try {
                const querySnapshot = await getDocs(collection(firestore, "courses"));
                let arrayData = [];
                querySnapshot.forEach((doc) => {
                    arrayData.push({
                        courseId: doc.id,
                        ...doc.data(),
                    });
                });

                arrayData.sort((a, b) => {
                    const nameA = a.courseName || "";
                    const nameB = b.courseName || "";
                    return nameA.localeCompare(nameB);
                });
                setFilteredCourse(arrayData);
                courseListRef.current = arrayData
                setCourseLoading(false);
            } catch (error) {
                setCourseLoading(false);
                console.error('fail to load course', error);
            }
        }
        getCourse()
    }, [])

    useEffect(() => {
        if (data.course) {
            getHole(data.course.courseId);
            updateStepData('stepOne', {
                roundName: data.roundName === "" ? `${userData.firstName}'s Round-${moment().format('YYMMDD')}` : data.roundName
            });
        }
    }, [data.course])



    const handleSelectCourse = async (course) => {
        setSelectedCourse(course);
        setSelectCourseIsOpen(false)

        const latLong = course.location.latLong;
        const lat = latLong._lat;
        const long = latLong._long;

        course.location.latLong = {};
        course.location.latLong.lat = lat;
        course.location.latLong.long = long;

        updateStepData('stepOne', {
            course: course,
            holes: "",
            holesCourse: "",
            roundName: `${userData.firstName}'s Round-${moment().format('YYMMDD')}`
        });
        updateStepData('stepTwo', {
            row: []
        });

        await getHole(course.courseId);
    }

    const getHole = async (courseId) => {

        setNineHolesData([]);
        seteighteenHolesData([]);
        setHoleButton([]);
        setHolesValue("");
        try {

            const holesCollection = collection(firestore, "courseHoles");
            const q = query(holesCollection, where('courseId', '==', courseId));
            const querySnapshot = await getDocs(q);

            const nineHolesArray = [];
            const eighteenHolesArray = [];
            const buttonArray = [];

            querySnapshot.forEach((doc) => {

                if (doc.data().numberOfHole === "9holes") {
                    nineHolesArray.push({ ...doc.data(), id: doc.id })
                } else if (doc.data().numberOfHole === "18holes") {
                    eighteenHolesArray.push({ ...doc.data(), id: doc.id })
                } else {
                    console.warn('Not found hole', doc.numberOfHole);
                }
            });

            if (nineHolesArray.length) {
                buttonArray.push({ name: '9 Holes', value: "1" })
            }
            if (eighteenHolesArray.length) {
                buttonArray.push({ name: '18 Holes', value: "2" })
            }
            setHoleButton(buttonArray);
            setNineHolesData(nineHolesArray);
            seteighteenHolesData(eighteenHolesArray);

            setHolesValue(data.holes);
            if (data.holes === "1") {
                setHolesCourse(nineHolesArray);
            } else if (holesValue === "2") {
                setHolesCourse(eighteenHolesArray);
            } else {
                setHolesCourse([]);
                // console.error('Hole value not define :', data.holes);
            }

            setHolesLoading(false);

        } catch (error) {
            setHolesLoading(false)
            console.error('fail to load course', error);
        }
    }

    const handleHolesChange = (e) => {
        const eholeValue = e.target.value;

        setHolesValue(eholeValue);
        setSelectedHoleCourse("")


        updateStepData('stepOne', { holes: e.target.value });

        if (eholeValue === "1") {
            setHolesCourse(nineHolesData);
            // setSelectedHoleCourse(nineHolesData[0].courseHoleName);
            updateStepData('stepOne', { holesCourse: "" })
        } else if (eholeValue === "2") {
            setHolesCourse(eighteenHolesData);
            // setSelectedHoleCourse(eighteenHolesData[0].courseHoleName);
            updateStepData('stepOne', { holesCourse: "" })
        } else {
            setHolesCourse([]);
            console.error('Hole value not define :', eholeValue);
        }

    }

    const handleHoleCourseChange = (e, id) => {
        let holesCourseId = ""
        if (id) {
            holesCourseId = id
        } else {
            holesCourseId = e.target.value
        }
        setSelectedHoleCourse(holesCourseId)
        updateStepData('stepOne', { holesCourse: holesCourseId })
    }

    const handleGameModeChange = (e) => {
        const mode = e.target.value;
        setGameModeVal(mode)
        const scoringList = scoringDef(mode, handicapSelect);

        updateStepData('stepOne', {
            gameMode: mode,
            scoring: scoringList[0],
            scoringType: scoringList
        });
        setScoringType(scoringList);
        setScoringSelect(scoringList[0])
    }

    const handleChangeHandicap = (e) => {
        const handicap = e.target.value;
        setHandicapSelect(handicap);
        const scoringList = scoringDef(gameModeVal, handicap);
        updateStepData('stepOne', {
            handicap: handicap,
            scoring: scoringList[0],
            scoringType: scoringList
        });

        setScoringType(scoringList);
        setScoringSelect(scoringList[0])
    }

    const handleScoringChange = (e) => {
        const scoring = e.target.value;
        setScoringSelect(scoring);
        updateStepData('stepOne', { scoring: scoring });
    }

    return (
        <>
            <Accordion activeKey={selectCourseisOpen ? "0" : "1"} flush>
                <h5 className='col-sm-4 mb-1 fw-600'>Select Golf Course</h5>

                <div className='p-3 w-100 text-center rounded-3 pointer bg-light theme-dark-bg'
                    onClick={toggleAccordion}>
                    {selectedCourse ?
                        <div className='d-flex justify-content-center align-items-center'>
                            <img src={selectedCourse.profileImage} alt="img" width={60} height={60} className='me-2 rounded-circle' />
                            <div>
                                <h4 className='fw-600'>{selectedCourse.courseName}</h4>
                                <h6 className='fw-600 text-black-50'>
                                    {selectedCourse.location.subDistrictEng}, {selectedCourse.location.provinceEng}
                                </h6>
                            </div>
                        </div>
                        :
                        <h4 className='fw-600 text-muted'>Select Course</h4>
                    }
                </div>

                <Accordion.Collapse eventKey='0' className='p-0'>
                    <>
                        <section className='d-sm-flex justify-content-between align-items-center mb-3 mt-2'>
                            <h3 className='col-sm-4 fw-600'>Golf Course</h3>
                            <div className='d-flex align-items-center gap-2 col-sm-6'>
                                <div className="search-form-2 flex-grow-1">
                                    <i className="ti-search font-xss"></i>
                                    <input
                                        type="text"
                                        className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
                                        placeholder="Search here."
                                        value={searchTerm}
                                        onChange={(e) => handleSearch(e, setSearchTerm, setFilteredCourse, courseListRef)}
                                    />
                                </div>
                                <button className='btn btn-light theme-dark-bg' onClick={() => handleOpenFilterModal()}><i className='fs-2 feather-filter text-muted'></i></button>
                            </div>
                        </section>
                        <hr />
                        {!courseLoading ?
                            <section className='right-scroll-bar' style={{ maxHeight: 350, overflow: 'auto', }}>
                                <ListGroup variant='flush' >
                                    {filteredCourse.map((course, index) => (
                                        <ListGroup.Item key={index} className='position-relative course-list-items' onClick={() => handleSelectCourse(course)}>
                                            <figure className="d-flex align-items-center me-2">
                                                <img
                                                    src={course?.profileImage}
                                                    alt="profile_img"
                                                    width={50}
                                                    height={50}
                                                    className='rounded-circle'
                                                />
                                                <div className='ms-2 text-truncate'>
                                                    <h5 className='fw-600 text-wrap'>
                                                        {course.courseName}
                                                    </h5>
                                                    <h6 className='fw-600 text-black-50'>{`${course?.location?.subDistrictEng}, ${course?.location?.provinceEng}`}</h6>
                                                </div>
                                            </figure>
                                            <h2 className='feather-info position-absolute pointer' style={{ top: 5, right: 5 }}
                                                onClick={(e) => handleOpenCourseDetailModal(e, course)}
                                            ></h2>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </section>
                            :
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only"></span>
                            </div>
                        }
                    </>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey='1' className='p-0'>
                    <>
                        <h5 className='mt-4 mb-1 fw-600'>Round Name</h5>
                        <input type="text" name="round-name" id="round-name"
                            className='form-control'
                            value={data.roundName}
                            onChange={(e) => updateStepData('stepOne', { roundName: e.target.value })}
                        />

                        <h5 className='mt-4 mb-1 fw-600'>Playing Holes</h5>
                        {!holesLoading && holeButton.length ?
                            <>
                                <div className='d-flex align-items-center gap-2 mb-2' >
                                    {holeButton.map((radio, idx) => (
                                        <div key={idx} className='radio-container col-sm-3'>
                                            <ToggleButton
                                                id={`hole-${idx}`}
                                                type="radio"
                                                name="hole"
                                                className={clsx("inactive w-100 theme-dark-bg", {
                                                    "active": holesValue === radio.value,
                                                })}
                                                variant='light'
                                                value={radio.value}
                                                checked={holesValue === radio.value}
                                                onChange={(e) => handleHolesChange(e)}
                                            >
                                                <h6 className='fw-600'>{radio.name}</h6>
                                            </ToggleButton>
                                            <i className={clsx("bi bi-check-circle-fill text-current checked-icon",
                                                { "d-none": holesValue !== radio.value })}></i>
                                        </div>
                                    ))}
                                </div>
                                <div className={clsx('mb-2', { 'd-none': !holesCourse.length })}>
                                    <select name="holeCourseSelect" id="holeCourseSelect" className='form-select' value={selectedHoleCourse} onChange={handleHoleCourseChange}>
                                        <option value=''>No Select</option>
                                        {holesCourse.map((hole, index) => (
                                            <option key={index} value={hole.id}>{hole.courseHoleName}</option>
                                        ))}
                                    </select>
                                </div>
                            </> :
                            <h5>No data</h5>
                        }
                        <h5 className='mt-4 mb-1 fw-600'>Game Mode</h5>
                        <div className='d-flex align-items-center gap-2 mb-2 flex-wrap' >
                            {gameModeButton.map((radio, idx) => (
                                <div key={idx} className='radio-container col-sm-3'>
                                    <ToggleButton
                                        id={`mode-${idx}`}
                                        type="radio"
                                        className={clsx("inactive w-100 theme-dark-bg", {
                                            "active": gameModeVal === radio.value,
                                        })}
                                        variant='light'
                                        name="mode"
                                        value={radio.value}
                                        checked={gameModeVal === radio.value}
                                        onChange={(e) => handleGameModeChange(e)}
                                    >
                                        <h6 className='fw-600'>{radio.name}</h6>
                                    </ToggleButton>
                                    <i className={clsx("bi bi-check-circle-fill text-current checked-icon",
                                        { "d-none": gameModeVal !== radio.value })}></i>
                                </div>
                            ))}
                        </div>

                        <div className='d-sm-flex align-items-center gap-2 mb-2'>
                            <label htmlFor="handicap" className='w-50'><h5 className='fw-600'>Handicap</h5></label>
                            <select name="handicap" id="handicap" className='form-select form-select-sm' value={handicapSelect} onChange={handleChangeHandicap}>
                                <option value="scratch">Scratch {`(No Handicap Applied)`}</option>
                                <option value="whs">WHS {`(World Handicap System)`}</option>
                                <option value="s36">S36 {`(System 36)`}</option>
                            </select>
                        </div>

                        <div className='d-sm-flex align-items-center gap-2 mb-2'>
                            <label htmlFor="scoring" className='w-50'><h5 className='fw-600'>Scoring</h5></label>
                            <select name="scoring" id="scoring" className='form-select form-select-sm' value={scoringSelect} onChange={handleScoringChange}>
                                {scoringType.map((type, index) => (
                                    <option key={index} value={type}>{type}</option>
                                ))}
                            </select>
                        </div>
                    </>
                </Accordion.Collapse>
            </Accordion >

            <FilterCourse
                isOpen={filterModal}
                handleClose={handleCloseFilterModal}
            />
            <CourseDetail
                isOpen={courseDetailModal}
                handleClose={handleCloseCourseDetailModal}
                course={courseDetailRef.current}
                handleSelectCourse={(course) => handleSelectCourse(course)}
                handleHoleCourseChange={(id, holeVal) => { handleHoleCourseChange(null, id); setHolesValue(holeVal);}}
            />

        </>
    )
}

export default StepOneByPlayer