import React, { useState, useRef, useEffect } from 'react'
import { Dropdown, Modal, Form } from 'react-bootstrap'
import { firestore, storage } from './firebase/FirebaseConfig';
import { doc, deleteDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { ReactSVG } from 'react-svg';
import SelectedPrivBtn from '../pages/home/postview/utilsfunction/SelectedPriv';
import imageCompression from 'browser-image-compression';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject, listAll } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

const sliderStyle = {
    swipeToSlide: true,
    arrows: false,
    dots: false,
    infinite: false,
    centerMode: false,
    variableWidth: true,
    autoplay: false,
    mobileFirst: true
}

function PostDropdown({ post, handleDeletedPost, handleEditedPost }) {
    const userData = useSelector((state) => state.user.data);
    const [editPostModal, setEditPostModal] = useState(false);
    const postTextRef = useRef(null);
    const isImageEdit = useRef(false);
    const [showImageList, setShowImageList] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [selectedPrivacy, setSelectedPrivacy] = useState(post.audience || 'public');

    const handlePostDelete = async (postId) => {
        try {
            const storageRef = ref(storage, `post_image/${postId}/`);
            const docRef = doc(firestore, 'posts', postId);

            const listResult = await listAll(storageRef);

            const deletePromises = listResult.items.map((itemRef) => deleteObject(itemRef));

            await Promise.all(deletePromises);

            await deleteDoc(docRef);

            handleDeletedPost(postId);
        } catch (error) {
            console.error('Error deleting directory or document: ', error);
        }
    };

    const handleEditPost = async (postId) => {
        if (postId) {
            setEditPostModal(true);
        }
    }

    useEffect(() => {
        if (editPostModal && postTextRef.current) {
            postTextRef.current.value = post.content || '';
            setSelectedPrivacy(post.audience);
            if (post.mediaType === 'image') {
                const imageList = [];
                post.media.forEach(image => {
                    imageList.push({ url: image })
                });
                setShowImageList(imageList)
            }
        }
    }, [editPostModal, post]);

    const handleSelectPriv = (selectedPriv) => {
        setSelectedPrivacy(selectedPriv);
    }

    const handleDelteImage = (image) => {
        isImageEdit.current = true;
        setShowImageList(prevImageList => {
            const updatedImageList = prevImageList.filter((items) => items.url !== image);
            return updatedImageList;
        });
    };

    function handleImageSelect(e) {
        isImageEdit.current = true;
        const selectedImage = e.target.files;
        const imageList = []
        for (const image of selectedImage) {
            const imageUrl = URL.createObjectURL(image);
            imageList.push({
                file: image,
                url: imageUrl
            });
        }
        setShowImageList((prevImageList) => [...prevImageList, ...imageList]);
    }

    const handleSubmitPost = async () => {
        if (!userData.role || userData.role === "" || userData.role === "no-select") {
            navigate('/accountinformation');
            return;
        }
        try {
            setLoading(true);
            let updateImage = { ...post };
            const storageRef = ref(storage, `post_image/${post.postId}/`);
            const postDocRef = doc(firestore, "posts", post.postId);

            if (isImageEdit.current) {
                const existingUrls = showImageList.map(imageObj => imageObj.url).filter(Boolean);

                const listResult = await listAll(storageRef);
                const deletePromises = listResult.items.map(async (itemRef) => {
                    const downloadURL = await getDownloadURL(itemRef);
                    if (!existingUrls.includes(downloadURL)) {
                        return deleteObject(itemRef);
                    }
                });

                await Promise.all(deletePromises);
            }

            const newUrls = [];
            for (let i = 0; i < showImageList.length; i++) {
                const imageObj = showImageList[i];
                if (imageObj.hasOwnProperty('file')) {
                    const url = await handleUploadImage(post.postId, imageObj.file);
                    newUrls.push(url);
                    showImageList.splice(i, 1); // Remove the object from the list
                    i--; // Adjust the index after removal
                }
            }

            // Add remaining existing URLs
            for (const imageObj of showImageList) {
                if (imageObj.url) {
                    newUrls.push(imageObj.url);
                }
            }

            updateImage.media = newUrls;
            updateImage.lastUpdate = serverTimestamp();
            updateImage.content = postTextRef.current.value || "";
            updateImage.audience = selectedPrivacy;
            updateImage.mediaType = newUrls.length > 0 ? "image" : "text"
            updateImage.role = userData.role

            delete updateImage.userData;
            delete updateImage.id;

            await updateDoc(postDocRef, updateImage);

            setLoading(false);
            setEditPostModal(false);
            handleEditedPost()
        } catch (error) {
            console.error("fail to update post", error);
            setLoading(false);
        }
    }

    const handleUploadImage = async (post_id, file) => {
        try {
            // Compress the image
            const compressedFile = await imageCompression(file, {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            });

            const storageRef = ref(storage, `post_image/${post_id}/${file.name}.jpg`);
            const uploadTask = uploadBytesResumable(storageRef, compressedFile);

            const downloadURL = await new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    null, // Skip progress tracking
                    (error) => {
                        console.error('Upload error:', error);
                        reject(error);
                    },
                    async () => {
                        try {
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            resolve(downloadURL);
                        } catch (error) {
                            console.error('Error getting download URL:', error);
                            reject(error);
                        }
                    }
                );
            });

            return downloadURL;
        } catch (error) {
            console.error('Error during image upload process:', error);
            throw error; // Re-throw error to be handled by the caller if necessary
        }
    };


    return (
        <>
            <style>{`.post-menu :hover {
  background-color: rgba(170, 170, 170, 0.1);
}
.post-menu a :hover {
  background-color: transparent;
}
`}</style>
            <Dropdown>
                <Dropdown.Toggle id="post-dropdown" className='p-0 bg-transparent border border-0'>
                    <h6><i className="ti-more-alt btn-round-md bg-greylight theme-dark-bg font-xss"></i></h6>
                </Dropdown.Toggle>

                <Dropdown.Menu className='p-0 theme-dark-bg post-menu' style={{ borderRadius: '15px', overflow: 'hidden' }} >
                    <Dropdown.Item disabled={post.userId !== userData.uid} onClick={() => handleEditPost(post.postId)}
                        className='py-2 d-flex align-items-center'>
                        {post.userId !== userData.uid ?
                            <div className='m-0 fw-500' style={{ lineHeight: 1.2 }}>
                                <i className='me-2 bi bi-pencil-fill'></i>
                                Edit post
                            </div>
                            :
                            <h4 className='m-0 fw-500'>
                                <i className='me-2 bi bi-pencil-fill'></i>
                                Edit post
                            </h4>
                        }
                    </Dropdown.Item>

                    <Dropdown.Item disabled={post.userId !== userData.uid}
                        className='py-2 d-flex align-items-center'>
                        {post.userId !== userData.uid ?
                            <div className='fs-4 m-0 fw-500' style={{ lineHeight: 1.2 }}>
                                <i className='me-2 bi bi-bell-slash-fill'></i>
                                Turn off notification
                            </div>
                            :
                            <h4 className='m-0 fw-500'>
                                <i className='me-2 bi bi-bell-slash-fill'></i>
                                Turn off notification
                            </h4>
                        }
                    </Dropdown.Item>

                    <Dropdown.Divider />

                    <Dropdown.Item disabled={post.userId !== userData.uid} onClick={() => handlePostDelete(post.postId)}
                        className='py-2 d-flex align-items-center'>
                        {post.userId !== userData.uid ?
                            <div className='fs-4 m-0 fw-500' style={{ lineHeight: 1.2 }}>
                                <i className='me-2 bi bi-trash-fill'></i>
                                Delete post</div>
                            :
                            <h4 className='m-0 fw-500'>
                                <i className='me-2 bi bi-trash-fill'></i>
                                Delete post
                            </h4>
                        }
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            {/* edit Modal */}
            <Modal show={editPostModal} onHide={() => setEditPostModal(false)} centered size='lg' animation={false}>
                <Modal.Header>
                    <Modal.Title className='mx-auto'><h1 className='fw-700 m-0'>Edit Post</h1></Modal.Title>
                    <button className='btn btn-close m-0' onClick={() => setEditPostModal(false)}></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-start'>
                        <figure className="avatar mt-2 me-2"><img src={userData?.profileImage} alt="icon" className="shadow-sm"
                            style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                objectFit: 'cover'
                            }}
                        /></figure>
                        <Form.Control as="textarea" rows={3} className='post-text-input' id='post-text-input' placeholder="What's on your mind ?" ref={postTextRef} />
                    </div>

                    {showImageList.length > 0 && (
                        <Slider {...sliderStyle} className='selected-image-container'>
                            {showImageList.map((image, index) => (
                                <div key={index} className='each-image-container'>
                                    <img src={image.url} aria-hidden alt={`image-${index}`} className='shadow-sm' />
                                    <i
                                        className='bi bi-x-circle' onClick={() => handleDelteImage(image.url)}
                                    ></i>
                                </div>
                            ))}
                            <div className='add-image-input-container d-flex justify-content-center align-items-center' onClick={() => document.getElementById('add-image-input').click()}>
                                <ReactSVG src="assets/images/dooNineIconV2/others/create_Post_Add_Photo.svg" className='create-post-add-photo-btn' />
                                <input type="file" name="add-image-input" id="add-image-input" multiple accept='image/*' className='d-none' onChange={handleImageSelect} />
                            </div>
                        </Slider>
                    )}
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between w-100 flex-nowrap'>
                    {showImageList.length < 1 && (
                        <div className='add-photo-btn-container'>
                            <ReactSVG src="assets/images/dooNineIconV2/others/create_Post_Add_Photo.svg" className='create-post-add-photo-btn ms-4 w40' onClick={() => document.getElementById('image-input').click()} />
                            <input type="file" name="image-input" id="image-input" multiple accept='image/*' className='d-none' onChange={handleImageSelect} />
                        </div>
                    )}
                    <div></div>
                    <div className='create-post-action-btn d-flex justify-content-between gap-2'>
                        <SelectedPrivBtn handleSelectPriv={handleSelectPriv} isPreSelected={selectedPrivacy} />
                        <button className='btn bg-current post-btn w-50' disabled={loading ? true : false}>
                            {!loading ? (
                                <h4 className='fw-600 m-0 text-white p-2' onClick={() => handleSubmitPost()}>Post</h4>
                            ) : (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default PostDropdown