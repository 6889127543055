import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
  getDoc,
  query,
  where,
  getDocs,
  collection,
  doc,
  addDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";

function ImageProfileBox({ data }) {
  const cellStyle = {
    padding: "5px 5px 5px 5px",
    textAlign: "center",
    borderBottom: "2px solid #ddd",
    borderTop: "2px solid #ddd",
    borderRight: "2px solid #ddd",
    borderLeft: "2px solid #ddd",
  };

  const colorStyles = {
    grey: { backgroundColor: "#F5F5F5", borderRadius: "6px" },
    white: { backgroundColor: "#F5F5F5", borderRadius: "6px" },
  };

  const [player, setPlayer] = useState(null);
  const [caddie, setCaddie] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (data) {
        if (data.playerId) {
          const q = query(
            collection(firestore, "users"),
            where("uid", "==", data.playerId)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            setPlayer(doc.data());
          });
        }
        if (data.caddieId) {
          const q = query(
            collection(firestore, "users"),
            where("uid", "==", data.caddieId)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            setCaddie(doc.data());
          });
        }
      }
    }

    fetchData();
  }, [data]);

  return (
    <>
      <div
        style={{
          ...cellStyle,
          ...colorStyles.grey,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <img
            src={player?.profileImage}
            alt="Player"
            style={{
              borderRadius: "50%",
              border: "4px solid #4A74E5",
              marginRight: "10px",
              width: "65px",
              height: "65px",
            }}
          />
          <img
            src={caddie?.profileImage}
            alt="Caddie"
            style={{
              borderRadius: "50%",
              border: "4px solid #EE3D7F",
              width: "65px",
              height: "65px",
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ImageProfileBox;
